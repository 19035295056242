import { rest } from 'msw';

/*
Cookie content
{
  "email": "test@grid.gg",
  "email_verified": true,
  "preferred_username": "Fisher Rob",
  "applicationId": "31287956-1eab-3a8e-957a-c5fbfa5e2cf5",
  "roles": [
    "admin",
    "developer",
    "grid_developers",
    "open_platform_developer",
    "organisation_owner",
    "series_state_api_consumer",
    "trader",
    "user"
  ],
  "sid": "90c46fc8-7a38-407e-baaf-ee09cc6c4203",
  "groups": [
    "0e2a9121-3a8f-40cb-b745-d5f66c83c4ba"
  ]
}
 */
const AuthorizationCookieToken =
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InRlc3RAZ3JpZC5nZyIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiJGaXNoZXIgUm9iIiwiYXBwbGljYXRpb25JZCI6IjMxMjg3OTU2LTFlYWItM2E4ZS05NTdhLWM1ZmJmYTVlMmNmNSIsInJvbGVzIjpbImFkbWluIiwiZGV2ZWxvcGVyIiwiZ3JpZF9kZXZlbG9wZXJzIiwib3Blbl9wbGF0Zm9ybV9kZXZlbG9wZXIiLCJvcmdhbmlzYXRpb25fb3duZXIiLCJzZXJpZXNfc3RhdGVfYXBpX2NvbnN1bWVyIiwidHJhZGVyIiwidXNlciJdLCJzaWQiOiI5MGM0NmZjOC03YTM4LTQwN2UtYmFhZi1lZTA5Y2M2YzQyMDMiLCJncm91cHMiOlsiMGUyYTkxMjEtM2E4Zi00MGNiLWI3NDUtZDVmNjZjODNjNGJhIl19';

const postLogin = rest.post('/auth/login', (_req, res, ctx) =>
    res(
        ctx.cookie('Authorization', AuthorizationCookieToken, { path: '/', sameSite: 'strict' }),
        ctx.json({ theme: 'grid' }),
    ),
);

const postLogout = rest.post('/auth/logout', (_req, res, ctx) =>
    res(
        ctx.cookie('Authorization', AuthorizationCookieToken, { path: '/', sameSite: 'strict' }),
        ctx.json({}),
    ),
);

const getVerify = rest.get('/auth/verify', (req, res, ctx) => res(ctx.json({})));

export default [postLogin, postLogout, getVerify];
