import { graphql } from 'msw';
import circuitHubFixture from '@features/site-circuit-hub/utils/mock-data';

export const strapi = graphql.link('http://localhost:3002/content/graphql');

export default strapi.query('GetTournamentHubByDomain', (req, res, ctx) =>
    res(
        ctx.data({
            tournamentHub: {
                ...circuitHubFixture,
                site: {
                    ...circuitHubFixture.site,
                    domain: req.body?.variables.domain,
                },
            },
        }),
    ),
);
