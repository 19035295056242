import set from 'lodash/set';
import { graphql } from 'msw';

import deleteErrorMock from '@features/dashboard/pages/central-data/pages/team/fixtures/deleteError.json';

import seriesListMockPageOne from '@features/dashboard/pages/central-data/pages/series/fixtures/data.json';
import seriesListMockPageTwo from '@features/dashboard/pages/central-data/pages/series/fixtures/secondPage.json';
import seriesMock from '@features/dashboard/pages/central-data/pages/series-details/fixtures/data.json';
import getSeriesByIdForEditSeries from '@features/dashboard/modals/EditSeries/fixtures/getSeriesByIdForEditSeries.json';

import tournamentsMockFiltered from '@features/dashboard/pages/central-data/pages/tournaments/fixtures/filtered.json';
import tournamentInternalMock from '@features/dashboard/pages/central-data/pages/tournaments/fixtures/tournament.json';
import tournamentsMockPageOne from '@features/dashboard/pages/central-data/pages/tournaments/fixtures/data.json';
import tournamentsMockPageTwo from '@features/dashboard/pages/central-data/pages/tournaments/fixtures/secondPage.json';
import tournamentMock from '@features/dashboard/pages/central-data/pages/tournament/fixtures/data.json';

import titleMock from '@features/dashboard/pages/central-data/pages/title/fixtures/data.json';
import titlesMock from '@features/dashboard/pages/central-data/pages/titles/fixtures/data.json';

import teamMock from '@features/dashboard/pages/central-data/pages/team/fixtures/data.json';
import teamsMockPageOne from '@features/dashboard/pages/central-data/pages/teams/fixtures/firstPage.json';
import teamsMockPageTwo from '@features/dashboard/pages/central-data/pages/teams/fixtures/secondPage.json';
import filteredTeamMock from '@features/dashboard/pages/central-data/pages/teams/fixtures/filteredSecond.json';
import teamPlayers from '@features/dashboard/pages/central-data/pages/team/fixtures/teamPlayers.json';

import playerMock from '@features/dashboard/pages/central-data/pages/players/components/EditPlayerModal/fixtures/data.json';
import playersMock from '@features/dashboard/pages/central-data/pages/players/fixtures/data.json';
import playersPageTwo from '@features/dashboard/pages/central-data/pages/team/fixtures/playersPageTwo.json';
import playersFilteredMock from '@features/dashboard/pages/central-data/pages/players/fixtures/filtered.json';
import updatePlayerErrorMock from '@features/dashboard/pages/central-data/pages/players/fixtures/updatePlayerError.json';
import { MIN_SEARCH_LENGTH } from '@components/Forms/SearchBar';

const internalEndpoint = graphql.link('http://localhost:3003/central-data/v2/graphql');

const handlers = [
    internalEndpoint.query('GetInternalData', (_, res, ctx) =>
        res(
            ctx.data({
                series: {
                    externalLinks: seriesMock.data.series.externalLinks,
                    machineDataSource: seriesMock.data.series.machineDataSource,
                },
            }),
        ),
    ),
    internalEndpoint.query('GetSeriesInRange', (req, res, ctx) => {
        if (!req.variables.after) {
            return res(ctx.data(seriesListMockPageOne.data));
        }
        return res(ctx.data(seriesListMockPageTwo.data));
    }),
    internalEndpoint.query('GetSeriesByIdForEditSeries', (req, res, ctx) => {
        const { id, ...rest } = getSeriesByIdForEditSeries.data.series;

        return res(
            ctx.data({
                series: {
                    id: req.variables.id,
                    ...rest,
                },
            }),
        );
    }),

    internalEndpoint.query('GetTitles', (_, res, ctx) => res(ctx.data(titlesMock.data))),
    internalEndpoint.query('GetFilterTitles', (_, res, ctx) => res(ctx.data(titlesMock.data))),

    internalEndpoint.query('GetTournaments', (req, res, ctx) => {
        if (req?.variables?.name?.contains?.length >= MIN_SEARCH_LENGTH) {
            return res(ctx.data(tournamentsMockFiltered.data));
        }
        return res(ctx.data(tournamentsMockPageOne.data));
    }),

    internalEndpoint.query('GetTournamentByIdInternal', (req, res, ctx) => {
        if (req?.variables?.tournamentId === '10') {
            return res(ctx.data({ tournament: tournamentInternalMock }));
        }
        return res(ctx.data({ tournament: null }));
    }),
    internalEndpoint.query('GetTournamentsFilter', (_, res, ctx) =>
        res(ctx.data(tournamentsMockPageOne.data)),
    ),
    internalEndpoint.query('GetLinksForTournament', (_, res, ctx) =>
        res(
            ctx.data({
                tournament: {
                    externalLinks: tournamentMock.externalLinks,
                },
            }),
        ),
    ),
    internalEndpoint.query('GetAutoCompleteTournaments', (req, res, ctx) => {
        if (!req.variables.after) {
            return res(ctx.data(tournamentsMockPageOne.data));
        }
        return res(ctx.data(tournamentsMockPageTwo.data));
    }),

    internalEndpoint.query('GetTeams', (req, res, ctx) => {
        if (req?.variables?.name?.contains?.length >= MIN_SEARCH_LENGTH) {
            return res(ctx.data(teamsMockPageOne.data));
        }
        if (!req.variables.after) {
            return res(ctx.data(teamsMockPageOne.data));
        }

        return res(ctx.errors([{ message: 'No data' }]));
    }),

    internalEndpoint.query('GetTeamByIdInternal', (req, res, ctx) => {
        if (req?.variables?.teamId === '10') {
            return res(ctx.data({ team: teamMock }));
        }
        return res(ctx.data({ player: null }));
    }),

    internalEndpoint.query('GetTeamsFilter', (_, res, ctx) => res(ctx.data(teamsMockPageOne.data))),
    internalEndpoint.query('GetFilterTeams', (req, res, ctx) => {
        if (!req.variables.after) {
            return res(ctx.data(teamsMockPageOne.data));
        }
        return res(ctx.data(teamsMockPageTwo.data));
    }),
    internalEndpoint.query('GetLinksForTeam', (req, res, ctx) =>
        res(ctx.data({ team: { externalLinks: teamMock.data.team.externalLinks } })),
    ),
    internalEndpoint.query('GetAutoCompleteTeams', (req, res, ctx) => {
        if (req.variables.titleId === '1') {
            return res(ctx.data(filteredTeamMock.data));
        }
        if (!req.variables.after) {
            return res(ctx.data(teamsMockPageOne.data));
        }
        return res(ctx.data(teamsMockPageTwo.data));
    }),

    internalEndpoint.query('GetSelectTitles', (_, res, ctx) => res(ctx.data(titlesMock.data))),
    internalEndpoint.query('GetTitleById', (_, res, ctx) => res(ctx.data(titleMock.data))),
    internalEndpoint.query('GetTitlesManagementData', (_, res, ctx) =>
        res(ctx.data(titlesMock.data)),
    ),

    internalEndpoint.query('GetPlayers', (req, res, ctx) => {
        if (req?.variables?.name?.contains?.length >= MIN_SEARCH_LENGTH) {
            return res(ctx.data(playersFilteredMock.data));
        }
        if (req?.variables?.teamId?.id === '2') {
            const mockedResponseWithNoPlayers = set(playersMock, 'data.players.totalCount', 0);
            return res(ctx.data(mockedResponseWithNoPlayers));
        }
        if (req.variables.after) {
            return res(ctx.data(playersPageTwo.data));
        }
        if (req.variables.teamId.id) {
            return res(ctx.data(teamPlayers.data));
        }
        return res(ctx.data(playersMock.data));
    }),

    internalEndpoint.query('GetPlayerById', (req, res, ctx) => {
        if (req?.variables?.playerId === '10') {
            return res(ctx.data({ player: playerMock }));
        }
        return res(ctx.data({ player: null }));
    }),
    internalEndpoint.query('GetPlayer', (_, res, ctx) => res(ctx.data(playerMock.data))),

    // Mutations
    internalEndpoint.mutation('UpdateTeam', (_, res, ctx) =>
        res(ctx.errors(updatePlayerErrorMock.error)),
    ),
    internalEndpoint.mutation('DeleteTeamById', (req, res, ctx) => {
        const { teamId } = req.variables;
        if (teamId === '1') {
            return res(ctx.errors(deleteErrorMock.error));
        }
        return res(ctx.data({}));
    }),
    internalEndpoint.mutation('CreatePlayer', (_, res, ctx) =>
        res(
            ctx.data({
                createPlayer: {
                    createdPlayer: {
                        id: '3448',
                    },
                },
            }),
        ),
    ),
    internalEndpoint.mutation('UpdatePlayer', (req, res, ctx) => {
        if (req.variables.player.id === '4') {
            return res(ctx.errors(updatePlayerErrorMock.error));
        }
        return res(ctx.data({}));
    }),
    internalEndpoint.mutation('UpdateSeries', (req, res, ctx) => {
        if (req.variables.updateSeriesInput.id === '4') {
            return res(ctx.errors(updatePlayerErrorMock.error));
        }
        return res(
            ctx.data({
                updateSeries: {
                    updatedSeries: {
                        id: req.variables.updateSeriesInput.id,
                        __typename: 'Series',
                    },
                    __typename: 'UpdateSeriesPayload',
                },
            }),
        );
    }),
    internalEndpoint.mutation('UpdateTournament', (_, res, ctx) =>
        res(ctx.errors(updatePlayerErrorMock.error)),
    ),
    internalEndpoint.mutation('UpdateTitle', (_, res, ctx) =>
        res(ctx.errors(updatePlayerErrorMock.error)),
    ),
];

export default handlers;
