import { css } from '@emotion/react';
import { color as themeColor } from '@components/styled/tools';

import { Theme } from '@config/themes';

export interface CustomScrollbarProps {
    thumbColor: string;
    trackColor?: string;
}

const customScrollbar = ({ thumbColor, trackColor }: CustomScrollbarProps) => (props: {
    theme: Theme;
}) => css`
    scrollbar-color: ${thumbColor ? themeColor(thumbColor)(props) : themeColor('base.80')(props)}
        ${trackColor ? themeColor(trackColor)(props) : themeColor('base.90')(props)};
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background: ${themeColor(thumbColor)(props)};
        border-radius: 5px;
    }
`;

export default customScrollbar;
