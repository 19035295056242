import { useContext } from 'react';

import { login, logout } from '@features/authentication/utilities/authentication';

import AuthContext from '@features/authentication/context/authentication';

import { AuthenticationStatus, AuthError } from '../types/authentication';

interface useAuthenticationReturnValue {
    status?: AuthenticationStatus;
    errors?: AuthError[];
    login: ReturnType<typeof login>;
    logout: ReturnType<typeof logout>;
}

const useAuthentication = (): useAuthenticationReturnValue => {
    const { status, errors, setAuthState } = useContext(AuthContext);

    return {
        status,
        errors,
        login: login(setAuthState),
        logout: logout(setAuthState),
    };
};

export default useAuthentication;
