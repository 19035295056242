import styled from '@emotion/styled';

import { ifProp } from 'styled-tools';

import {
    compose,
    space,
    SpaceProps,
    color,
    ColorProps,
    layout,
    LayoutProps,
    typography,
    TypographyProps,
    flexbox,
    FlexboxProps,
    position,
    PositionProps,
    border,
    BordersProps,
    maxHeight,
    MaxHeightProps,
    minWidth,
    MinWidthProps,
    minHeight,
    MinHeightProps,
    overflow,
    OverflowProps,
    flexDirection,
    FlexDirectionProps,
    textAlign,
} from '@components/styled/system';
import customScrollbar from '@utilities/customScrollbar';

const boxStyles = compose(
    space,
    color,
    layout,
    typography,
    flexbox,
    position,
    border,
    maxHeight,
    minWidth,
    minHeight,
    overflow,
    flexDirection,
);

type BoxStyleProps = SpaceProps &
    ColorProps &
    LayoutProps &
    TypographyProps &
    FlexboxProps &
    PositionProps &
    BordersProps &
    MaxHeightProps &
    MinWidthProps &
    MinHeightProps &
    OverflowProps &
    FlexDirectionProps;

export interface IBox extends BoxStyleProps {
    background?: string;
    push?: boolean;
}

export default styled.div<IBox>`
    ${boxStyles};
    box-sizing: border-box;
    ${textAlign}
    ${ifProp('push', 'margin-left: auto;')};
    ${ifProp(
        'onClick',
        `
            :hover {
                cursor: pointer;
            }
    `,
    )};
    ${customScrollbar({ thumbColor: 'base.80'})};
`;
