import { rest } from 'msw';
import orgData from '../../../../features/dashboard/pages/my-organisation/pages/organisation-management-console/fixtures/organisation-info/data.json';
import orgUsers from '../../../../features/dashboard/pages/my-organisation/pages/organisation-management-console/fixtures/users-list/data.json';
import organisations from '../../../../features/dashboard/pages/account-management/pages/organisation/fixtures/organisations/data.json';
import whitelistedIPs from '../../../../features/dashboard/pages/account-management/pages/organisation/fixtures/organisations/whitelistedIPs.json';

const getOrganisations = rest.get('/api/organisations', (req, res, ctx) =>
    res(ctx.json(organisations)),
);

const getOrganisation = rest.get('/api/organisations/:id', (req, res, ctx) => {
    if (req.params?.id === 'mine') {
        return res(ctx.json(orgData));
    }
    return res(ctx.json(orgData));
});

const getOrganisationUsers = rest.get('/api/organisations/:id/users', (req, res, ctx) =>
    res(ctx.json(orgUsers)),
);
const getOrganisationWhitelistedIps = rest.get(
    '/api/organisations/:id/ip-ranges',
    (req, res, ctx) => res(ctx.json(whitelistedIPs)),
);

export default [
    getOrganisation,
    getOrganisations,
    getOrganisationUsers,
    getOrganisationWhitelistedIps,
];
