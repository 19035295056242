import { rest } from 'msw';

const response = `
|         player_id |   total_kills |   total_deaths |   total_rounds_played |   different_maps |   headshots_percentage |
|------------------:|--------------:|---------------:|----------------------:|-----------------:|-----------------------:|
| 76561198012174501 |            52 |             76 |                    96 |                3 |               0.634615 |
| 76561198014498951 |            20 |             28 |                    51 |                1 |               0.25     |
| 76561198035445770 |            51 |             67 |                    87 |                3 |               0.470588 |
| 76561198035657319 |            92 |             86 |                   138 |                4 |               0.478261 |
| 76561198037406382 |            55 |             63 |                    87 |                3 |               0.363636 |
| 76561198041731855 |           104 |            123 |                   185 |                5 |               0.615385 |
| 76561198049522054 |            94 |             96 |                   138 |                4 |               0.521277 |
| 76561198060367354 |           104 |             80 |                   138 |                4 |               0.384615 |
| 76561198067722198 |            46 |             32 |                    51 |                1 |               0.565217 |
| 76561198072143352 |            33 |             33 |                    49 |                1 |               0.454545 |
| 76561198081710274 |            61 |             64 |                    96 |                3 |               0.344262 |
| 76561198092722707 |            48 |             65 |                    87 |                3 |               0.5      |
| 76561198094466886 |            37 |             27 |                    49 |                1 |               0.432432 |
| 76561198127570649 |            70 |             64 |                    96 |                3 |               0.3      |
| 76561198136638580 |           112 |             99 |                   152 |                4 |               0.366071 |
| 76561198182135462 |            27 |             34 |                    49 |                1 |               0.481481 |
| 76561198189600467 |            90 |             85 |                   116 |                2 |               0.444444 |
| 76561198192191289 |            33 |             27 |                    49 |                1 |               0.30303  |
| 76561198192352118 |            46 |             73 |                    96 |                3 |               0.434783 |
| 76561198195642865 |            42 |             29 |                    51 |                1 |               0.571429 |
| 76561198246363120 |           102 |            129 |                   185 |                5 |               0.656863 |
| 76561198264088735 |           105 |            102 |                   152 |                4 |               0.419048 |
| 76561198272032394 |            42 |             25 |                    51 |                1 |               0.52381  |
| 76561198273670913 |           106 |             88 |                   138 |                4 |               0.622642 |
| 76561198281767680 |            66 |             90 |                   116 |                2 |               0.545455 |
| 76561198284507358 |            42 |             70 |                    87 |                3 |               0.357143 |
| 76561198361613212 |           123 |            130 |                   185 |                5 |               0.609756 |
| 76561198420647763 |            83 |             89 |                   138 |                4 |               0.481928 |
| 76561198422434044 |            49 |             27 |                    51 |                1 |               0.346939 |
| 76561198423366928 |            73 |             68 |                    96 |                3 |               0.561644 |
| 76561198432922023 |            60 |             70 |                    87 |                3 |               0.666667 |
| 76561198839451691 |            77 |             80 |                   116 |                2 |               0.493506 |
| 76561198848738130 |           154 |            134 |                   185 |                5 |               0.519481 |
| 76561198848891688 |           121 |            104 |                   152 |                4 |               0.553719 |
| 76561198869922486 |            61 |             69 |                   116 |                2 |               0.409836 |
| 76561198939861318 |            48 |             31 |                    49 |                1 |               0.604167 |
| 76561198981301404 |            88 |             74 |                   116 |                2 |               0.602273 |
| 76561199030972198 |           120 |            125 |                   185 |                5 |               0.333333 |
| 76561199038935329 |            86 |             98 |                   152 |                4 |               0.372093 |
| 76561199116709063 |           105 |             99 |                   152 |                4 |               0.638095 |
`;

const singleMessage = rest.post('/api/stats-explorer/single_message', (req, res, ctx) =>
    res(
        ctx.json({
            body: response,
        }),
    ),
);

export default [singleMessage];
