import { rest } from 'msw';

const getUserVerifyRegistration = rest.get('/api/registration/verify', (req, res, ctx) => {
    const url = new URL(req.url);
    const verificationId = url.searchParams.get('verificationId');

    if (!verificationId || verificationId === 'expired') {
        return res(
            ctx.status(401),
            ctx.json({
                errors: [
                    {
                        message: 'Email verification link expired',
                        type: 'link-expired',
                    },
                ],
            }),
        );
    }

    return res(ctx.status(200), ctx.json({ isSuccessful: true }));
});

export default [getUserVerifyRegistration];
