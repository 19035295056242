import { graphql } from 'msw';

export const strapi = graphql.link('http://localhost:3002/content/graphql');

export default strapi.query('GetTournamentHubLogoByDomain', (req, res, ctx) =>
    res(
        ctx.data({
            circuitHubByDomain: null,
        }),
    ),
);
