import isArray from 'lodash/isArray';
import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';
import {
    opacity,
    OpacityProps,
    space,
    SpaceProps,
    system,
    variant,
} from '@components/styled/system';
import iconComponents from './iconsImports';

type IconKeys = keyof typeof iconComponents;
export const iconsList = Object.keys(iconComponents) as Array<IconKeys>;
export type IconNames = IconKeys | string;

const svgSizeVariants = {
    large: { width: 32, height: 32 },
    medium: { width: 24, height: 24 },
    small: { width: 16, height: 16 },
    xsmall: { width: 8, height: 8 },
};

export type IconSize = OneOrMany<keyof typeof svgSizeVariants> | OneOrMany<number>;

interface IconProps extends SpaceProps, OpacityProps {
    icon: IconNames;
    onClick?: Function;
    cursor?: string;
    fill?: string;
    size?: IconSize;
    stroke?: string;
    'data-cy'?: string;
}

const iconSizeString = variant({
    scale: 'svg.size',
    prop: 'size',
    variants: svgSizeVariants,
});

const iconSizeNumber = system({
    sizeWidth: { property: 'width', scale: 'space' },
    sizeHeight: { property: 'height', scale: 'space' },
});

type SizeType = OneOrMany<string> | OneOrMany<number>;

const isSizeAsStringValue = (value: SizeType): value is OneOrMany<string> => {
    if (isArray(value)) return typeof value[0] === 'string';
    return typeof value === 'string';
};

const sizeStyle = ({ theme, size }) =>
    isSizeAsStringValue(size)
        ? iconSizeString({ theme, size })
        : iconSizeNumber({ theme, sizeWidth: size, sizeHeight: size });

const systemStyle = system({
    cursor: { property: 'cursor' },
    fill: { property: 'fill', scale: 'colors' },
    stroke: { property: 'stroke', scale: 'colors' },
});

const createIcon = (iconComponent, iconName: IconNames) => {
    const component = styled(iconComponent, {
        shouldForwardProp: isPropValid,
    })`
        ${space}
        ${opacity}
        ${sizeStyle};
        ${systemStyle};
    `;
    component.defaultProps = {
        size: 'small',
        'data-cy': iconName,
    };
    return component;
};

const styledIcons = {} as Record<string, ReturnType<typeof createIcon>>;
Object.keys(iconComponents).forEach((iconName) => {
    styledIcons[iconName] = createIcon(iconComponents[iconName], iconName);
});

styledIcons['icon-placeholder'] = createIcon(iconComponents.info_power_off, 'icon-placeholder');

interface IconWrapperProps extends IconProps {}

function Icon({ icon, size, 'data-cy': dataCy, ...props }: IconWrapperProps) {
    const IconComponent = styledIcons[icon] || styledIcons['icon-placeholder'];
    const iconDataCy = dataCy || (styledIcons[icon] ? icon : 'icon-placeholder');
    return <IconComponent size={size} data-cy={iconDataCy} {...props} />;
}

export default Icon;
