import logs from '../../../features/widget-livelog/fixtures/logs.json';
import removeEvent from '../../../features/widget-livelog/fixtures/removeEvent.json';
import setStatusInconsistent from '../../../features/widget-livelog/fixtures/setStatusInconsistent.json';
import setStatusConsistent from '../../../features/widget-livelog/fixtures/setStatusConsistent.json';

export default function* generator() {
    // eslint-disable-next-line no-restricted-syntax
    for (const item of logs) {
        yield item;
    }
    yield setStatusConsistent;
    yield setStatusInconsistent;
    yield removeEvent;
}
