import { createContext, useContext } from 'react';

const oneEmInPx = 16;

export const StyledSystemResponsiveContext = createContext<number | null>(null);

export const getCurrentBreakpointIndex = (pxBreakpoints: number[], currentWidth: number) =>
    pxBreakpoints.reduce((acc: number, breakpoint, index) => {
        if (breakpoint <= currentWidth) {
            return index + 1;
        }
        return acc;
    }, 0);

export const applyBreakpointToProp = <T>(
    pxBreakpoints: number[],
    value: Array<T>,
    width: number,
): T => {
    const currentBreakpointIndex = getCurrentBreakpointIndex(pxBreakpoints, width);
    const responsiveValue = value[currentBreakpointIndex] || value[value.length - 1];
    return responsiveValue;
};

export const emToPx = (breakpoint) => parseInt(breakpoint.replace('em', ''), 10) * oneEmInPx;

export const applyBreakpoints = (props, propNames: string[], width: number) =>
    Object.keys(props).reduce((acc, key) => {
        const breakpoints = props.theme.widgetsBreakpoints || props.theme.breakpoints;
        if (!breakpoints) return acc;
        const pxBreakpoints = breakpoints.map(emToPx);
        const value = props[key];

        if (propNames.indexOf(key) > -1 && value && Array.isArray(value)) {
            return { ...acc, [key]: applyBreakpointToProp(pxBreakpoints, value, width) };
        }

        return { ...acc, [key]: value };
    }, {});

export const proxyBreakpointBehaviour = (styleFunc) => {
    const func = (props) => {
        const clientWidth = useContext(StyledSystemResponsiveContext);
        if (!clientWidth) return styleFunc(props);
        const updatedProps = applyBreakpoints(props, styleFunc.propNames, clientWidth);
        return styleFunc(updatedProps);
    };

    func.config = styleFunc.config;
    func.propNames = styleFunc.propNames;
    func.cache = styleFunc.cache;
    return func;
};
