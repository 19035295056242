import { rest } from 'msw';

const toggles = [
    {
        enabled: true,
        name: 'FeatureA',
        variant: {
            enabled: false,
            name: 'disabled',
        },
    },
    {
        enabled: true,
        name: 'FeatureC',
        variant: {
            enabled: false,
            name: 'disabled',
        },
    },
];

const featureSwitches = rest.get('/api/features/proxy', (req, res, ctx) =>
    res(ctx.json({ toggles })),
);

export default [featureSwitches];
