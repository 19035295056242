import Box from '@components/Layout/Grid/Box';
import Bars from '@svg/bars.svg';

import LoadingScreen from './styles';

interface LoadingProps {
    isHidden?: boolean;
}

const Loader = ({ isHidden = false }: LoadingProps) => (
    <LoadingScreen isHidden={isHidden}>
        <Bars height="50" width="50" />
    </LoadingScreen>
);

const LoaderFullPage = ({ isHidden }: LoadingProps) => (
    <Box
        position="absolute"
        top={0}
        left={0}
        height="100vh"
        width="100vw"
        bg="base.90"
        zIndex={1000}
    >
        <Loader isHidden={isHidden} />
    </Box>
);

export default Loader;
export { LoaderFullPage };
