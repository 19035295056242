import { ReactNode } from 'react';
import { Outlet } from 'react-router-dom';

import Flex from '../Grid/Flex';

export interface RootLayoutProps {
    background?: 1 | 2;
    children?: ReactNode;
}

function RootLayout({ background, children }: RootLayoutProps) {
    const backgroundValue = background ? `main.background.${background}` : undefined;

    return (
        <Flex
            flexDirection="column"
            alignItems="stretch"
            width="auto"
            minHeight="100vh"
            bg={backgroundValue}
        >
            {/* Page contents - 2 options to pass them (only one should be used):
                - Directly as children
                - By nested <Route>s when used in parent's <Route element={<PageRootLayout />} */}
            {children || <Outlet />}
        </Flex>
    );
}

export default RootLayout;
