import { rest } from 'msw';

import streamData from '../../../features/widget-video/fixtures/data.json';

const getStreamManagement = rest.get(
    '/b2c/stream-management-service/stream/:seriesId',
    (req, res, ctx) =>
        res(
            ctx.json({
                streamId: 'tian2_2512-fawd',
                seriesId: '1',
            }),
        ),
);

const getStream = rest.get(
    'http://localhost:3002/b2c/video-stream-coordinator/streams/:id',
    (req, res, ctx) => res(ctx.json(streamData)),
);

export default [getStream, getStreamManagement];
