import { graphql } from 'msw';

export const strapi = graphql.link('http://localhost:3002/content/graphql');

const data = {
    promoBanners: [],
    bestOdds: [
        {
            name: 'Gorilla',
            value: 13,
            provider: {
                link: 'https://ggbetpromo.com/l/5c93674dc88d8d0fcc6d9bc1',
                __typename: 'BestoddsProvider',
            },
            logo: null,
            __typename: 'Bestodds',
        },
        {
            name: 'UD Vessuwan',
            value: 3.26,
            provider: {
                link: 'https://ggbetpromo.com/l/5c93674dc88d8d0fcc6d9bc1',
                __typename: 'BestoddsProvider',
            },
            logo: null,
            __typename: 'Bestodds',
        },
    ],
    galleries: [
        {
            id: '72',
            images: [
                {
                    id: '241',
                    fullsize: {
                        alt_text: null,
                        image: {
                            url: 'https://cdn.grid.gg/strapi/Alliance_ABOUTUS_53d85730e5.png',
                            __typename: 'UploadFile',
                        },
                        __typename: 'ComponentImagesImage',
                    },
                    thumbnail: {
                        alt_text: null,
                        image: null,
                        __typename: 'ComponentImagesImage',
                    },
                    cta: {
                        id: '167',
                        text: 'Find out more',
                        href: 'https://thealliance.gg/',
                        variant: 'primary',
                        size: 'medium',
                        __typename: 'ComponentButtonsButton',
                    },
                    __typename: 'ComponentImagesGalleryImages',
                },
            ],
            title: null,
            layout: 'no_preview',
            twitter_social_share: false,
            facebook_social_share: false,
            cta_position: 'bottom_right',
            share_position: 'bottom_left',
            name: 'Alliance left',
            __typename: 'Gallery',
        },
        {
            id: '73',
            images: [
                {
                    id: '242',
                    fullsize: {
                        alt_text: null,
                        image: {
                            url: 'https://cdn.grid.gg/strapi/Alliance_TWITTER_4d6e0a6d41.png',
                            __typename: 'UploadFile',
                        },
                        __typename: 'ComponentImagesImage',
                    },
                    thumbnail: {
                        alt_text: null,
                        image: null,
                        __typename: 'ComponentImagesImage',
                    },
                    cta: {
                        id: '168',
                        text: 'Visit Twitter',
                        href: 'https://twitter.com/theAllianceGG',
                        variant: 'primary',
                        size: 'medium',
                        __typename: 'ComponentButtonsButton',
                    },
                    __typename: 'ComponentImagesGalleryImages',
                },
            ],
            title: null,
            layout: 'no_preview',
            twitter_social_share: false,
            facebook_social_share: false,
            cta_position: 'bottom_right',
            share_position: 'bottom_left',
            name: 'Alliance right',
            __typename: 'Gallery',
        },
    ],
    twitchChannels: [
        {
            tournament_id: '106876',
            channel_id: 'moonstudio_en',
            id: '156',
            name: 'MOON STUDIO',
            __typename: 'TwitchChannels',
        },
    ],
};

export default strapi.query('GetWatchPageData', (req, res, ctx) => res(ctx.data(data)));
