import * as styledSystem from 'styled-system';
import { proxyBreakpointBehaviour } from './helpers';

export * from 'styled-system';

export const margin = proxyBreakpointBehaviour(styledSystem.margin);
export const padding = proxyBreakpointBehaviour(styledSystem.padding);
export const space = proxyBreakpointBehaviour(styledSystem.space);
export const shadow = proxyBreakpointBehaviour(styledSystem.shadow);
export const boxShadow = proxyBreakpointBehaviour(styledSystem.boxShadow);
export const textShadow = proxyBreakpointBehaviour(styledSystem.textShadow);
export const borders = proxyBreakpointBehaviour(styledSystem.borders);
export const buttonStyle = proxyBreakpointBehaviour(styledSystem.buttonStyle);
export const textStyle = proxyBreakpointBehaviour(styledSystem.textStyle);
export const colorStyle = proxyBreakpointBehaviour(styledSystem.colorStyle);
export const width = proxyBreakpointBehaviour(styledSystem.width);
export const height = proxyBreakpointBehaviour(styledSystem.height);
export const minWidth = proxyBreakpointBehaviour(styledSystem.minWidth);
export const minHeight = proxyBreakpointBehaviour(styledSystem.minHeight);
export const maxWidth = proxyBreakpointBehaviour(styledSystem.maxWidth);
export const maxHeight = proxyBreakpointBehaviour(styledSystem.maxHeight);
export const size = proxyBreakpointBehaviour(styledSystem.size);
export const verticalAlign = proxyBreakpointBehaviour(styledSystem.verticalAlign);
export const display = proxyBreakpointBehaviour(styledSystem.display);
export const overflow = proxyBreakpointBehaviour(styledSystem.overflow);
export const opacity = proxyBreakpointBehaviour(styledSystem.opacity);
export const fontSize = proxyBreakpointBehaviour(styledSystem.fontSize);
export const fontFamily = proxyBreakpointBehaviour(styledSystem.fontFamily);
export const fontWeight = proxyBreakpointBehaviour(styledSystem.fontWeight);
export const lineHeight = proxyBreakpointBehaviour(styledSystem.lineHeight);
export const textAlign = proxyBreakpointBehaviour(styledSystem.textAlign);
export const fontStyle = proxyBreakpointBehaviour(styledSystem.fontStyle);
export const letterSpacing = proxyBreakpointBehaviour(styledSystem.letterSpacing);
export const alignItems = proxyBreakpointBehaviour(styledSystem.alignItems);
export const alignContent = proxyBreakpointBehaviour(styledSystem.alignContent);
export const justifyItems = proxyBreakpointBehaviour(styledSystem.justifyItems);
export const justifyContent = proxyBreakpointBehaviour(styledSystem.justifyContent);
export const flexWrap = proxyBreakpointBehaviour(styledSystem.flexWrap);
export const flexDirection = proxyBreakpointBehaviour(styledSystem.flexDirection);
export const flex = proxyBreakpointBehaviour(styledSystem.flex);
export const flexGrow = proxyBreakpointBehaviour(styledSystem.flexGrow);
export const flexShrink = proxyBreakpointBehaviour(styledSystem.flexShrink);
export const flexBasis = proxyBreakpointBehaviour(styledSystem.flexBasis);
export const justifySelf = proxyBreakpointBehaviour(styledSystem.justifySelf);
export const alignSelf = proxyBreakpointBehaviour(styledSystem.alignSelf);
export const order = proxyBreakpointBehaviour(styledSystem.order);
export const gridGap = proxyBreakpointBehaviour(styledSystem.gridGap);
export const gridColumnGap = proxyBreakpointBehaviour(styledSystem.gridColumnGap);
export const gridRowGap = proxyBreakpointBehaviour(styledSystem.gridRowGap);
export const gridColumn = proxyBreakpointBehaviour(styledSystem.gridColumn);
export const gridRow = proxyBreakpointBehaviour(styledSystem.gridRow);
export const gridAutoFlow = proxyBreakpointBehaviour(styledSystem.gridAutoFlow);
export const gridAutoColumns = proxyBreakpointBehaviour(styledSystem.gridAutoColumns);
export const gridAutoRows = proxyBreakpointBehaviour(styledSystem.gridAutoRows);
export const gridTemplateColumns = proxyBreakpointBehaviour(styledSystem.gridTemplateColumns);
export const gridTemplateRows = proxyBreakpointBehaviour(styledSystem.gridTemplateRows);
export const gridTemplateAreas = proxyBreakpointBehaviour(styledSystem.gridTemplateAreas);
export const gridArea = proxyBreakpointBehaviour(styledSystem.gridArea);
export const borderWidth = proxyBreakpointBehaviour(styledSystem.borderWidth);
export const borderStyle = proxyBreakpointBehaviour(styledSystem.borderStyle);
export const borderColor = proxyBreakpointBehaviour(styledSystem.borderColor);
export const borderTop = proxyBreakpointBehaviour(styledSystem.borderTop);
export const borderRight = proxyBreakpointBehaviour(styledSystem.borderRight);
export const borderBottom = proxyBreakpointBehaviour(styledSystem.borderBottom);
export const borderLeft = proxyBreakpointBehaviour(styledSystem.borderLeft);
export const borderRadius = proxyBreakpointBehaviour(styledSystem.borderRadius);
export const backgroundImage = proxyBreakpointBehaviour(styledSystem.backgroundImage);
export const backgroundSize = proxyBreakpointBehaviour(styledSystem.backgroundSize);
export const backgroundPosition = proxyBreakpointBehaviour(styledSystem.backgroundPosition);
export const backgroundRepeat = proxyBreakpointBehaviour(styledSystem.backgroundRepeat);
export const zIndex = proxyBreakpointBehaviour(styledSystem.zIndex);
export const top = proxyBreakpointBehaviour(styledSystem.top);
export const right = proxyBreakpointBehaviour(styledSystem.right);
export const bottom = proxyBreakpointBehaviour(styledSystem.bottom);
export const left = proxyBreakpointBehaviour(styledSystem.left);
export const layout = proxyBreakpointBehaviour(styledSystem.layout);
export const color = proxyBreakpointBehaviour(styledSystem.color);
export const typography = proxyBreakpointBehaviour(styledSystem.typography);
export const flexbox = proxyBreakpointBehaviour(styledSystem.flexbox);
export const grid = proxyBreakpointBehaviour(styledSystem.grid);
export const border = proxyBreakpointBehaviour(styledSystem.border);
export const background = proxyBreakpointBehaviour(styledSystem.background);
export const position = proxyBreakpointBehaviour(styledSystem.position);

export const { get } = styledSystem;
export const compose: typeof styledSystem.compose = (...parsers) => {
    const styleFn = styledSystem.compose(...parsers);
    return proxyBreakpointBehaviour(styleFn);
};
export const system = (config) => {
    const styleFn = styledSystem.system(config);
    return proxyBreakpointBehaviour(styleFn);
};
export const variant: typeof styledSystem.variant = (config) => {
    const styleFn = styledSystem.variant(config);
    return proxyBreakpointBehaviour(styleFn);
};
export const { createStyleFunction } = styledSystem;
export const { createParser } = styledSystem;
