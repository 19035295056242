import jsCookie from 'js-cookie';

const options = { domain: '.grid.gg' };
const legacyRapidsOptions = { domain: '.rapid.grid.gg' };
export const AUTH_TOKEN_COOKIE_KEY = 'Authorization';

export const getAuthToken = (): string | undefined => jsCookie.get(AUTH_TOKEN_COOKIE_KEY);

export const setLegacyAuthToken = (authToken: String) =>
    jsCookie.set(AUTH_TOKEN_COOKIE_KEY, authToken, legacyRapidsOptions);

export const setAuthToken = (authToken: String) => {
    jsCookie.set(AUTH_TOKEN_COOKIE_KEY, authToken, options);
    setLegacyAuthToken(authToken);
};

export const removeAuthToken = () => jsCookie.remove(AUTH_TOKEN_COOKIE_KEY, options);
