const mockData = (increment) => ({
    id: `8dd19c90-527b-42f3-a4af-7c79fadbbbed${increment}`,
    timestamp: '2020-08-12T09:35:46Z',
    seriesId: '1',
    sequenceNumber: 1367 + increment,
    sessionSequenceNumber: 2 + increment,
    events: new Array(1 + Math.floor(increment / 10)).fill({}).map((_, index) => ({
        id: `c4ab9ab8-31b4-4262-b634-8abf2b899a11${increment}-${index}`,
        includesFullState: false,
        type: `player-pickedUp-item${increment}-${index}`,
        actor: {
            type: 'player',
            id: '76561198004854956',
            stateDelta: {
                id: '76561198004854956',
                series: {
                    id: '76561198004854956',
                },
                game: {
                    id: '76561198004854956',
                    inventoryValue: 0,
                    items: [
                        {
                            id: 'bomb',
                            statePath: [
                                {
                                    id: 'bomb',
                                },
                            ],
                            equipped: true,
                        },
                    ],
                    netWorth: 0,
                },
            },
            state: {
                id: '76561198004854956',
                name: 'dupreeh',
                teamId: '110',
                side: 'terrorists',
                series: {
                    id: '76561198004854956',
                    name: 'dupreeh',
                },
                game: {
                    id: '76561198004854956',
                    inventoryValue: 5050,
                    items: [
                        {
                            id: 'bomb',
                            equipped: true,
                            statePath: [
                                {
                                    id: 'bomb',
                                },
                            ],
                        },
                    ],
                    name: 'dupreeh',
                    netWorth: 8600,
                },
            },
        },
        action: 'pickedUp',
        target: {
            type: 'item',
            id: 'bomb',
            stateDelta: {
                id: 'bomb',
                statePath: [
                    {
                        id: 'bomb',
                    },
                ],
                equipped: true,
            },
            state: {
                id: 'bomb',
                equipped: true,
                statePath: [
                    {
                        id: 'bomb',
                    },
                ],
            },
        },
        seriesStateDelta: {
            id: '1',
            games: [
                {
                    id: '445fb94e-4644-4aa3-9904-d743510d2f5f',
                    teams: [
                        {
                            id: '110',
                            inventoryValue: 0,
                            netWorth: 0,
                            players: [
                                {
                                    id: '76561198004854956',
                                    inventoryValue: 0,
                                    items: [
                                        {
                                            id: 'bomb',
                                            statePath: [
                                                {
                                                    id: 'bomb',
                                                },
                                            ],
                                            equipped: true,
                                        },
                                    ],
                                    netWorth: 0,
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        seriesState: {
            id: '1',
            games: [
                {
                    id: '445fb94e-4644-4aa3-9904-d743510d2f5f',
                    sequenceNumber: 1,
                    teams: [
                        {
                            id: '110',
                            inventoryValue: 29300,
                            netWorth: 42000,
                            players: [
                                {
                                    id: '76561198004854956',
                                    inventoryValue: 5050,
                                    items: [
                                        {
                                            id: 'bomb',
                                            equipped: true,
                                            statePath: [
                                                {
                                                    id: 'bomb',
                                                },
                                            ],
                                        },
                                    ],
                                    name: 'dupreeh',
                                    netWorth: 8600,
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    })),
});

export default function* generator() {
    for (let i = 0; i < 100; i += 1) {
        yield mockData(i);
    }
}
