import { prop, theme } from 'styled-tools';
import { Theme } from '@config/themes';

export const themeColorFromProp = (needle) => (props) => {
    const colorNeedle = prop(needle)(props);
    const value = prop(colorNeedle)(props.theme.colors);
    return typeof value === 'string' ? value : colorNeedle;
};
export const radiiFromProp = (size) => (props) => prop(size)(props.theme.radii);

export const color = (colorNeedle?: string, returnNeedleOnError?: boolean) => (props: {
    theme: Theme;
}): string => {
    const value = theme(`colors.${colorNeedle}`)(props);
    if (typeof value === 'string') return value;
    return returnNeedleOnError && colorNeedle ? colorNeedle : '';
};
