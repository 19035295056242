import { AuthContextValues } from '@features/authentication/context/authentication';

import authenticationService from '@features/authentication/services/authentication';
import {
    LoginBody,
    AuthenticationStatus,
    authenticationStatus,
    UserRoles,
} from '@features/authentication/types/authentication';
import { getAuthToken, removeAuthToken } from './authToken';
import client from '../../../apollo/config';

const SERVICE_ROLE_SUFFIX = 'consumer';

export const login = (setAuthState: AuthContextValues['setAuthState']) => async (
    loginId: string,
    password: string,
): Promise<LoginBody> => {
    setAuthState({
        status: 'Loading',
    });

    const {
        response: { status },
        body,
    } = await authenticationService.login(loginId, password);

    const currentAuthStatus: AuthenticationStatus =
        authenticationStatus[status] || authenticationStatus[0];

    setAuthState({
        status: currentAuthStatus,
        errors: body?.errors,
    });
    return body;
};

export const logout = (setAuthState: AuthContextValues['setAuthState']) => async (
    onSuccesfullLogout: () => void = () => {},
) => {
    const {
        response: { status },
    } = await authenticationService.logout();

    if (status === 200) {
        client.clearStore();
        removeAuthToken();
        setAuthState({
            status: 'Unauthorized',
        });
        onSuccesfullLogout();
    }
};

interface UserDetails {
    email: string;
    isOrganisationOwner: () => boolean;
    groups: string[];
    groupsStringified: () => string;
    roles: string[];
    rolesWithoutServices: () => string[];
    uid: string;
    username: string;
}

export const getUserDetails = (): UserDetails | undefined => {
    const authToken = getAuthToken();
    if (authToken) {
        try {
            const jwtPayload = authToken.split('.')[1];
            const decodedPayload = atob(jwtPayload);
            const { sub, preferred_username, groups, roles, email } = JSON.parse(decodedPayload);

            return {
                email,
                groups,
                groupsStringified() {
                    return groups?.join(',');
                },
                isOrganisationOwner() {
                    return this.roles.includes(UserRoles.organisationOwner);
                },
                roles,
                rolesWithoutServices() {
                    return this.roles.filter((role) => !role.endsWith(SERVICE_ROLE_SUFFIX));
                },
                uid: sub,
                username: preferred_username,
            };
        } catch (e) {
            return undefined;
        }
    }
    return undefined;
};
