import { memo, ChangeEvent, useMemo, useState } from 'react';
import debounce from 'lodash/debounce';

import Icon from '@components/Images/Icon';
import Input from '@components/Forms/Input';

export const MIN_SEARCH_LENGTH = 2;

interface SearchBarProps {
    // Provide 0 to disable debouncing
    debounceTime?: number;
    defaultValue?: string;
    // Provide 0 to disable length check
    minLength?: number;
    // Called only when the value is valid and with debouncing. That's where
    // your searching logic should be called.
    onSearch: (value: string) => void;
}

const SearchBar = memo(
    ({
        debounceTime = 250,
        defaultValue = '',
        minLength: minSearchLength = MIN_SEARCH_LENGTH,
        onSearch,
    }: SearchBarProps) => {
        const [searchPhrase, setSearchPhrase] = useState(defaultValue);

        // Need to use useMemo over useCallback, as useCallback has problems
        // with accepting external functions
        const onSearchDebounced = useMemo(
            () => (debounceTime ? debounce(onSearch, debounceTime) : onSearch),
            [debounceTime, onSearch],
        );

        const handleSearchPhraseChange = (event: ChangeEvent<HTMLInputElement>) => {
            const newSearchPhrase = event.target.value;
            const isValidLength =
                newSearchPhrase.length >= minSearchLength ||
                // To always call when deleting text
                newSearchPhrase.length < searchPhrase.length;

            setSearchPhrase(newSearchPhrase);
            if (isValidLength) {
                onSearchDebounced(newSearchPhrase);
            }
        };

        return (
            <Input
                placeholder="Search"
                Icon={() => <Icon icon="search" fill="base.40" />}
                value={searchPhrase}
                onChange={handleSearchPhraseChange}
            />
        );
    },
);

export default SearchBar;
