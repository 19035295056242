import { rest } from 'msw';

const dashboardData = {
    filters: null,
    seriesGroupedByDate: [
        {
            date: '2020-01-01',
            series: [
                {
                    title: {
                        id: '2',
                        shortName: 'dota',
                    },
                    series: {
                        id: '8851',
                        scheduledStartTime: '2020-01-01T03:00:00Z',
                        format: 'Bo3',
                        status: 'upcoming',
                        traderViewUrl: 'https://grid.gg',
                        coverage: 'FULL',
                        productServiceLevels: {
                            liveDataFeed: {
                                serviceLevel: 'FULL',
                            },
                            videoRapid: {
                                serviceLevel: 'FULL',
                            },
                        },
                    },
                    tournament: {
                        id: '50',
                        iconUrl: '',
                        name: 'China Dota2 Development League',
                    },
                    teams: [
                        {
                            id: '476',
                            iconUrl:
                                'https://cdn.grid.gg/assets/team-logos/7c336e7e2d42dddf6507443e7f41848b',
                            name: 'You Know Who',
                            preSeriesPrediction: '0.64',
                        },
                        {
                            id: '307',
                            iconUrl:
                                'https://cdn.grid.gg/assets/team-logos/4a25fcf94791c891191872b0c28055bf',
                            name: 'Sparking Arrow Gaming',
                            preSeriesPrediction: '0.64',
                        },
                    ],
                    rapidView: {
                        status: 'enabled',
                    },
                },
                {
                    title: {
                        id: '1255',
                        shortName: 'kog',
                    },
                    series: {
                        id: '8852',
                        scheduledStartTime: '2020-01-01T03:00:00Z',
                        format: 'Bo3',
                        status: 'upcoming',
                        traderViewUrl: 'https://grid.gg',
                        coverage: 'limited',
                        productServiceLevels: {
                            liveDataFeed: {
                                serviceLevel: 'FULL',
                            },
                            videoRapid: {
                                serviceLevel: 'FULL',
                            },
                        },
                    },
                    tournament: {
                        id: '55',
                        iconUrl: '',
                        name: 'Kings of Glory Tournament',
                    },
                    teams: [
                        {
                            id: '476',
                            iconUrl:
                                'https://cdn.grid.gg/assets/team-logos/7c336e7e2d42dddf6507443e7f41848b',
                            name: "You Don't Know",
                            preSeriesPrediction: '0.64',
                        },
                        {
                            id: '307',
                            iconUrl:
                                'https://cdn.grid.gg/assets/team-logos/4a25fcf94791c891191872b0c28055bf',
                            name: 'GRID-TEST',
                            preSeriesPrediction: '0.64',
                        },
                    ],
                    rapidView: {
                        status: 'enabled',
                    },
                },
                {
                    title: {
                        id: '3',
                        shortName: 'dota',
                    },
                    series: {
                        id: '8853',
                        scheduledStartTime: '2020-01-01T03:00:00Z',
                        format: 'Bo3',
                        status: 'live',
                        coverage: 'FULL',
                        productServiceLevels: {
                            liveDataFeed: {
                                serviceLevel: 'FULL',
                            },
                            videoRapid: {
                                serviceLevel: 'FULL',
                            },
                        },
                    },
                    tournament: {
                        id: '50',
                        iconUrl: '',
                        name: 'China Dota2 Development League',
                    },
                    teams: [
                        {
                            id: '476',
                            iconUrl:
                                'https://cdn.grid.gg/assets/team-logos/7c336e7e2d42dddf6507443e7f41848b',
                            name: 'You Know Who',
                            preSeriesPrediction: '0.64',
                        },
                        {
                            id: '307',
                            iconUrl:
                                'https://cdn.grid.gg/assets/team-logos/4a25fcf94791c891191872b0c28055bf',
                            name: 'Sparking Arrow Gaming',
                            preSeriesPrediction: '0.64',
                        },
                    ],
                    rapidView: {
                        status: 'disabled',
                    },
                },
                {
                    title: {
                        id: '4',
                        shortName: 'dota',
                    },
                    series: {
                        id: '8854',
                        scheduledStartTime: '2020-01-01T03:00:00Z',
                        format: 'Bo3',
                        status: 'live',
                        coverage: 'limited',
                        productServiceLevels: {
                            liveDataFeed: {
                                serviceLevel: 'FULL',
                            },
                            videoRapid: {
                                serviceLevel: 'FULL',
                            },
                        },
                    },
                    tournament: {
                        id: '50',
                        iconUrl: '',
                        name: 'China Dota2 Development League',
                    },
                    teams: [
                        {
                            id: '476',
                            iconUrl:
                                'https://cdn.grid.gg/assets/team-logos/7c336e7e2d42dddf6507443e7f41848b',
                            name: 'You Know Who',
                            preSeriesPrediction: '0.64',
                        },
                        {
                            id: '307',
                            iconUrl:
                                'https://cdn.grid.gg/assets/team-logos/4a25fcf94791c891191872b0c28055bf',
                            name: 'Sparking Arrow Gaming',
                            preSeriesPrediction: '0.64',
                        },
                    ],
                    rapidView: {
                        status: 'disabled',
                    },
                },
                {
                    title: {
                        id: '2',
                        shortName: 'dota',
                    },
                    series: {
                        id: '8855',
                        scheduledStartTime: '2020-01-01T02:00:00Z',
                        format: 'Bo3',
                        status: 'finished',
                        coverage: 'FULL',
                        productServiceLevels: {
                            liveDataFeed: {
                                serviceLevel: 'FULL',
                            },
                            videoRapid: {
                                serviceLevel: 'FULL',
                            },
                        },
                    },
                    tournament: {
                        id: '50',
                        iconUrl: '',
                        name: 'China Dota2 Development League',
                    },
                    teams: [
                        {
                            id: '476',
                            iconUrl:
                                'https://cdn.grid.gg/assets/team-logos/7c336e7e2d42dddf6507443e7f41848b',
                            name: 'You Know Who',
                            preSeriesPrediction: '0.64',
                        },
                        {
                            id: '307',
                            iconUrl:
                                'https://cdn.grid.gg/assets/team-logos/4a25fcf94791c891191872b0c28055bf',
                            name: 'Sparking Arrow Gaming',
                            preSeriesPrediction: '0.64',
                        },
                    ],
                    rapidView: {
                        status: 'enabled',
                    },
                },
            ],
        },
        {
            date: '2020-01-02',
            series: [
                {
                    title: {
                        id: '1',
                        shortName: 'csgo',
                    },
                    series: {
                        id: '1',
                        scheduledStartTime: '2020-01-02T11:31:51Z',
                        format: 'Bo3',
                        status: 'upcoming',
                        coverage: 'FULL',
                        productServiceLevels: {
                            liveDataFeed: {
                                serviceLevel: 'FULL',
                            },
                            videoRapid: {
                                serviceLevel: 'FULL',
                            },
                        },
                    },
                    tournament: {
                        id: '26',
                        iconUrl: '',
                        name: 'GRID-TEST',
                    },
                    teams: [
                        {
                            id: '78',
                            iconUrl:
                                'https://cdn.grid.gg/assets/team-logos/0001db99e7c65ef89e0c3c09093a3ef8',
                            name: 'Astralis',
                            preSeriesPrediction: '0.64',
                        },
                        {
                            id: '89',
                            iconUrl:
                                'https://cdn.grid.gg/assets/team-logos/4a25fcf94791c891191872b0c28055bf',
                            name: 'AVANGAR',
                            preSeriesPrediction: '0.64',
                        },
                    ],
                    rapidView: {
                        status: 'enabled',
                    },
                },
                {
                    title: {
                        id: '2',
                        shortName: 'pubg',
                    },
                    series: {
                        id: '2',
                        scheduledStartTime: '2020-01-02T11:31:51Z',
                        format: 'Bo3',
                        status: 'upcoming',
                        coverage: 'FULL',
                        productServiceLevels: {
                            liveDataFeed: {
                                serviceLevel: 'none',
                            },
                            videoRapid: {
                                serviceLevel: 'FULL',
                            },
                        },
                    },
                    tournament: {
                        id: '26',
                        iconUrl: '',
                        name: 'GRID-TEST111',
                    },
                    teams: [
                        {
                            id: '78',
                            iconUrl:
                                'https://cdn.grid.gg/assets/team-logos/0001db99e7c65ef89e0c3c09093a3ef8',
                            name: 'Astralis',
                            preSeriesPrediction: '0.64',
                        },
                        {
                            id: '89',
                            iconUrl:
                                'https://cdn.grid.gg/assets/team-logos/4a25fcf94791c891191872b0c28055bf',
                            name: 'AVANGAR',
                            preSeriesPrediction: '0.64',
                        },
                    ],
                    rapidView: {
                        status: 'enabled',
                    },
                },
            ],
        },
    ],
};

const rapidDashboard = rest.get('/api/dashboards/rapid-console/series', (req, res, ctx) => {
    const coverage =
        req.url.searchParams
            .get('coverage')
            ?.split(',')
            .filter((i) => i !== '') ?? [];

    const mockFilteredResponse = {
        startCursor: 'PjMLC1oLC0JEQUJAQQsLWgsL',
        endCursor: 'PjMLC1oLC0JEQUJAQQsLWgsL',
        hasNextPage: true,
        hasPreviousPage: false,
        filters: dashboardData.filters,
        totalCount: 200,
        seriesGroupedByDate: dashboardData.seriesGroupedByDate.map((day) => ({
            ...day,
            ...(coverage.length !== 0 && {
                series: day.series.filter((seriesRow) =>
                    coverage.some((coverageOption) => coverageOption === seriesRow.series.coverage),
                ),
            }),
        })),
    };
    return res(ctx.json(mockFilteredResponse));
});

const rapidVirtualDashboard = rest.get('/api/dashboards/virtual-console/series', (req, res, ctx) =>
    res(ctx.json(dashboardData)),
);

export default [rapidDashboard, rapidVirtualDashboard];
