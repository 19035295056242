import { graphql } from 'msw';

export const strapi = graphql.link('http://localhost:3002/content/graphql');

const data = {
    siteByDomain: {
        id: '54',
        google_analytics_code: 'UA-130176846-1',
        override_current_series_id: null,
        name: 'Alliance',
        homepage: 'https://thealliance.gg/',
        header_cta_link: 'https://thealliance.gg/about',
        header_cta_text: 'ABOUT US',
        socials: {
            id: '51',
            facebook: 'https://www.facebook.com/theAllianceGG/',
            instagram: 'https://www.instagram.com/thealliancegg/?hl=en',
            linkedin: null,
            twitch: 'https://www.twitch.tv/alliance',
            twitter: 'https://twitter.com/theAllianceGG',
            youtube: 'https://www.youtube.com/user/theAllianceGG',
            __typename: 'ComponentSocialSocials',
        },
        features: [],
        organisation: {
            tournaments: null,
            __typename: 'Organisation',
        },
        brand: {
            primary_colors: ['#248740', '#278F45', '#2CA24D'],
            secondary_colors: ['#C6C6C8', '#D1D1D2', '#D9D9DA'],
            slate_colors: ['#777777', '#828282', '#8C8C8C', '#979797'],
            dark_colors: ['#181818', '#242424', '#2F2F2F', '#3B3B3B'],
            light_colors: ['#efefef', '#F5F5F5', '#FAFAFA', '#FFFFFF'],
            theme_overrides: {
                colourScheme: 'dark',
                fonts: ["'Poppins', sans-serif"],
                button: {
                    square: {
                        true: {},
                    },
                },
            },
            fontImportUrl:
                "@import url('https://fonts.googleapis.com/css?family=Poppins&display=swap');",
            primary_logo: {
                url: 'https://cdn.grid.gg/strapi/alliance_primary_85efbb01ba.png',
                __typename: 'UploadFile',
            },
            secondary_logo: {
                url: 'https://cdn.grid.gg/strapi/alliance_secondary_88fa6f5df8.png',
                __typename: 'UploadFile',
            },
            __typename: 'Brand',
        },
        site_title: 'Alliance Watch Page',
        favicon: {
            url: 'https://cdn.grid.gg/strapi/alliance_primary_85efbb01ba.png',
            __typename: 'UploadFile',
        },
        domain: 'alliance.grid.gg',
        __typename: 'Site',
    },
    pagesByDomain: [
        {
            id: '78',
            page_type: 'watch_tournament',
            meta: {
                id: '55',
                title: 'Alliance Watch Page',
                slug: 'dota',
                keywords: null,
                description: null,
                __typename: 'ComponentPageComponentsMeta',
            },
            components: [
                {
                    id: '120',
                    featured_betting_provider: null,
                    __typename: 'ComponentPageComponentsBestOdds',
                },
                {
                    id: '63',
                    primary_sponsor: {
                        id: '149',
                        created_at: '2020-10-12T07:31:32.000Z',
                        updated_at: '2022-11-14T10:59:24.000Z',
                        name: 'Alliance',
                        image: {
                            url: 'https://cdn.grid.gg/strapi/alliance_three_ccf183513e.png',
                            __typename: 'UploadFile',
                        },
                        link: 'https://thealliance.gg/',
                        alt_text: 'Alliance',
                        __typename: 'Sponsor',
                    },
                    sponsors: [
                        {
                            id: '100',
                            created_at: '2020-05-19T09:44:30.000Z',
                            updated_at: '2020-05-19T09:44:30.000Z',
                            name: 'Watch Hub',
                            image: {
                                url: 'https://cdn.grid.gg/strapi/watch_5cdf30f687.svg',
                                __typename: 'UploadFile',
                            },
                            link: 'https://watch.grid.gg/',
                            alt_text: 'Watch Hub',
                            __typename: 'Sponsor',
                        },
                        {
                            id: '149',
                            created_at: '2020-10-12T07:31:32.000Z',
                            updated_at: '2022-11-14T10:59:24.000Z',
                            name: 'Alliance',
                            image: {
                                url: 'https://cdn.grid.gg/strapi/alliance_three_ccf183513e.png',
                                __typename: 'UploadFile',
                            },
                            link: 'https://thealliance.gg/',
                            alt_text: 'Alliance',
                            __typename: 'Sponsor',
                        },
                    ],
                    __typename: 'ComponentPageComponentsSponsors',
                },
                {
                    id: '676',
                    name: null,
                    type: 'series_scoreboard',
                    settings: {
                        shouldRestoreDefaultTheme: false,
                    },
                    enabled: true,
                    __typename: 'ComponentPageComponentsVisualisationsWidget',
                },
                {
                    id: '677',
                    name: null,
                    type: 'prediction_tracker',
                    settings: {
                        shouldRestoreDefaultTheme: false,
                    },
                    enabled: true,
                    __typename: 'ComponentPageComponentsVisualisationsWidget',
                },
                {
                    id: '678',
                    name: null,
                    type: 'series_table',
                    settings: {
                        shouldRestoreDefaultTheme: false,
                    },
                    enabled: true,
                    __typename: 'ComponentPageComponentsVisualisationsWidget',
                },
                {
                    id: '679',
                    name: null,
                    type: 'team_scoreboard',
                    settings: {
                        shouldRestoreDefaultTheme: false,
                    },
                    enabled: true,
                    __typename: 'ComponentPageComponentsVisualisationsWidget',
                },
                {
                    id: '680',
                    name: null,
                    type: 'team_scoreboard',
                    settings: {
                        shouldRestoreDefaultTheme: false,
                    },
                    enabled: true,
                    __typename: 'ComponentPageComponentsVisualisationsWidget',
                },
                {
                    id: '681',
                    name: null,
                    type: 'live_log',
                    settings: {
                        shouldRestoreDefaultTheme: false,
                    },
                    enabled: true,
                    __typename: 'ComponentPageComponentsVisualisationsWidget',
                },
                {
                    id: '682',
                    name: null,
                    type: 'timeline',
                    settings: {
                        shouldRestoreDefaultTheme: false,
                    },
                    enabled: true,
                    __typename: 'ComponentPageComponentsVisualisationsWidget',
                },
                {
                    id: '683',
                    name: null,
                    type: 'series_comparison',
                    settings: {
                        shouldRestoreDefaultTheme: false,
                    },
                    enabled: true,
                    __typename: 'ComponentPageComponentsVisualisationsWidget',
                },
                {
                    id: '684',
                    name: null,
                    type: 'series_comparison',
                    settings: {
                        shouldRestoreDefaultTheme: false,
                    },
                    enabled: true,
                    __typename: 'ComponentPageComponentsVisualisationsWidget',
                },
                {
                    id: '120',
                    gallery: {
                        id: '72',
                        created_at: '2022-11-11T15:02:37.000Z',
                        updated_at: '2022-11-11T15:04:32.000Z',
                        images: [
                            {
                                fullsize: {
                                    image: {
                                        url:
                                            'https://cdn.grid.gg/strapi/Alliance_ABOUTUS_53d85730e5.png',
                                        __typename: 'UploadFile',
                                    },
                                    __typename: 'ComponentImagesImage',
                                },
                                thumbnail: {
                                    alt_text: null,
                                    image: null,
                                    __typename: 'ComponentImagesImage',
                                },
                                cta: {
                                    text: 'Find out more',
                                    href: 'https://thealliance.gg/',
                                    variant: 'primary',
                                    size: 'medium',
                                    id: '167',
                                    __typename: 'ComponentButtonsButton',
                                },
                                __typename: 'ComponentImagesGalleryImages',
                            },
                        ],
                        title: null,
                        layout: 'no_preview',
                        twitter_social_share: false,
                        facebook_social_share: false,
                        cta_position: 'bottom_right',
                        share_position: 'bottom_left',
                        name: 'Alliance left',
                        __typename: 'Gallery',
                    },
                    galleryLocation: 'watchTournamentBottomLeft',
                    __typename: 'ComponentPageComponentsGallery',
                },
                {
                    id: '121',
                    gallery: {
                        id: '73',
                        created_at: '2022-11-11T15:04:20.000Z',
                        updated_at: '2022-11-11T15:09:07.000Z',
                        images: [
                            {
                                fullsize: {
                                    image: {
                                        url:
                                            'https://cdn.grid.gg/strapi/Alliance_TWITTER_4d6e0a6d41.png',
                                        __typename: 'UploadFile',
                                    },
                                    __typename: 'ComponentImagesImage',
                                },
                                thumbnail: {
                                    alt_text: null,
                                    image: null,
                                    __typename: 'ComponentImagesImage',
                                },
                                cta: {
                                    text: 'Visit Twitter',
                                    href: 'https://twitter.com/theAllianceGG',
                                    variant: 'primary',
                                    size: 'medium',
                                    id: '168',
                                    __typename: 'ComponentButtonsButton',
                                },
                                __typename: 'ComponentImagesGalleryImages',
                            },
                        ],
                        title: null,
                        layout: 'no_preview',
                        twitter_social_share: false,
                        facebook_social_share: false,
                        cta_position: 'bottom_right',
                        share_position: 'bottom_left',
                        name: 'Alliance right',
                        __typename: 'Gallery',
                    },
                    galleryLocation: 'watchTournamentBottomMiddle',
                    __typename: 'ComponentPageComponentsGallery',
                },
            ],
            overrides: {
                id: '55',
                series: '2543076',
                team: '',
                title: 'dota',
                __typename: 'ComponentGenericOverrides',
            },
            __typename: 'Page',
        },
    ],
};

export default strapi.query('GetWatchPageSiteAndPages', (req, res, ctx) => res(ctx.data(data)));
