import { useUnleashContext } from '@unleash/proxy-client-react';

import useLoginEffect from '@features/authentication/hooks/useLoginEffect';
import { getUserDetails } from '@features/authentication/utilities/authentication';

const useInitializeUnleashContext = () => {
    const setUnleashContext = useUnleashContext();

    useLoginEffect(() => {
        const userId = getUserDetails()?.uid;
        if (userId) {
            setUnleashContext({ userId });
        }
    });
};

export default useInitializeUnleashContext;
