const mockData = {
    seriesId: '1',
    activeGameIndex: 1,
    tournament: {
        name: 'Nine to Five',
    },
    games: [
        {
            status: 'finished',
            mapName: 'vertigo',
            centeredInfoText: 'vertigo',
            centeredInfoTextTranslation: { key: 'translation_key' },
            doughnut: [],
            gameClock: {
                isTicking: false,
                tickingBackwards: false,
                timeDelta: 120,
                startTimestamp: 0,
                color: 'red',
            },
            momentum: [
                { color: 'cs-ct.0', contentIcon: 'objectives/bomb_explode' },
                { color: 'cs-tr.0', contentIcon: 'objectives/ancient' },
                { color: 'cs-tr.0', contentIcon: 'objectives/barrack' },
                { color: 'cs-tr.0', contentIcon: 'objectives/roshan' },
                { color: 'cs-tr.0', contentIcon: 'objectives/tower' },
                { color: 'cs-ct.0', contentIcon: 'objectives/defuse' },
                { color: 'cs-ct.0', contentIcon: 'objectives/time' },
                { color: 'cs-tr.0', contentIcon: 'objectives/dead' },
                { color: 'cs-tr.0', contentIcon: 'objectives/tower' },
                { color: 'cs-ct.0', contentIcon: 'objectives/bomb_explode' },
                { color: 'cs-tr.0', contentIcon: 'objectives/ancient' },
                { color: 'cs-tr.0', contentIcon: 'objectives/barrack' },
                { color: 'cs-tr.0', contentIcon: 'objectives/roshan' },
                { color: 'cs-tr.0', contentIcon: 'objectives/tower' },
                { color: 'cs-ct.0', contentIcon: 'objectives/defuse' },
                { color: 'cs-ct.0', contentIcon: 'objectives/time' },
                { color: 'cs-tr.0', contentIcon: 'objectives/dead' },
                { color: 'cs-tr.0', contentIcon: 'objectives/tower' },
                { color: 'cs-ct.0', contentIcon: 'objectives/bomb_explode' },
                { color: 'cs-tr.0', contentIcon: 'objectives/ancient' },
                { color: 'cs-tr.0', contentIcon: 'objectives/barrack' },
                { color: 'cs-tr.0', contentIcon: 'objectives/roshan' },
                { color: 'cs-tr.0', contentIcon: 'objectives/tower' },
                { color: 'cs-ct.0', contentIcon: 'objectives/defuse' },
                { color: 'cs-ct.0', contentIcon: 'objectives/time', active: true },
                { color: 'white', contentText: '25' },
                { color: 'white', contentText: '26' },
                { color: 'white', contentText: '27' },
                { color: 'white', contentText: '28' },
                { color: 'white', contentText: '29' },
                { color: 'white', contentText: '30' },
            ],
            teams: [
                {
                    id: 1,
                    score: 10,
                    won: false,
                    infoText: { text: 'Terrorist', color: 'orange' },
                },
                {
                    id: 2,
                    score: 16,
                    won: true,
                    infoText: { text: 'Counter-Terrorist', color: 'blue' },
                },
            ],
        },
        {
            status: 'live',
            mapName: 'vertigo',
            centeredInfoText: 'vertigo',
            centeredInfoTextTranslation: { key: 'translation_key' },
            doughnut: [],
            gameClock: {
                isTicking: true,
                tickingBackwards: true,
                timeDelta: 120,
                startTimestamp: 1482363367071,
                color: 'red',
            },
            momentum: [
                { color: 'cs-ct.0', contentIcon: 'objectives/bomb_explode' },
                { color: 'cs-tr.0', contentIcon: 'objectives/ancient' },
                { color: 'cs-tr.0', contentIcon: 'objectives/barrack' },
                { color: 'cs-tr.0', contentIcon: 'objectives/roshan' },
                { color: 'cs-tr.0', contentIcon: 'objectives/tower' },
                { color: 'cs-ct.0', contentIcon: 'objectives/defuse' },
                { color: 'cs-ct.0', contentIcon: 'objectives/time' },
                { color: 'cs-tr.0', contentIcon: 'objectives/dead' },
                { color: 'cs-tr.0', contentIcon: 'objectives/tower' },
                { color: 'cs-ct.0', contentIcon: 'objectives/bomb_explode' },
                { color: 'cs-tr.0', contentIcon: 'objectives/ancient' },
                { color: 'cs-tr.0', contentIcon: 'objectives/barrack' },
                { color: 'cs-tr.0', contentIcon: 'objectives/roshan' },
                { color: 'cs-tr.0', contentIcon: 'objectives/tower' },
                { color: 'cs-ct.0', contentIcon: 'objectives/defuse' },
                { color: 'cs-ct.0', contentIcon: 'objectives/time' },
                { color: 'cs-tr.0', contentIcon: 'objectives/dead' },
                { color: 'cs-tr.0', contentIcon: 'objectives/tower' },
                { color: 'cs-ct.0', contentIcon: 'objectives/bomb_explode' },
                { color: 'cs-tr.0', contentIcon: 'objectives/ancient' },
                { color: 'cs-tr.0', contentIcon: 'objectives/barrack' },
                { color: 'cs-tr.0', contentIcon: 'objectives/roshan' },
                { color: 'cs-tr.0', contentIcon: 'objectives/tower' },
                { color: 'cs-ct.0', contentIcon: 'objectives/defuse' },
                { color: 'cs-ct.0', contentIcon: 'objectives/time', active: true },
                { color: 'white', contentText: '25' },
                { color: 'white', contentText: '26' },
                { color: 'white', contentText: '27' },
                { color: 'white', contentText: '28' },
                { color: 'white', contentText: '29' },
                { color: 'white', contentText: '30' },
            ],
            teams: [
                {
                    id: 1,
                    score: 10,
                    won: false,
                    infoText: { text: 'T', color: 'rgb(238, 183, 48)' },
                },
                {
                    id: 2,
                    score: 15,
                    won: false,
                    infoText: { text: 'CT', color: 'rgb(238, 183, 48)' },
                },
            ],
        },
        {
            status: 'upcoming',
            mapName: 'vertigo',
            centeredInfoText: 'TBD',
            centeredInfoTextTranslation: { key: 'translation_key' },
            doughnut: [],
            gameClock: {
                isTicking: false,
                tickingBackwards: false,
                timeDelta: 120,
                startTimestamp: 0,
                color: 'red',
            },
            momentum: [],
            teams: [
                {
                    id: 1,
                    score: 10,
                    won: false,
                    infoText: { text: 'Terrorist', color: 'red' },
                },
                {
                    id: 2,
                    score: 15,
                    won: false,
                    infoText: { text: 'Counter-Terrorist', color: 'red' },
                },
            ],
        },
    ],
    series: {
        startTimeDate: new Date(1482363367071).toISOString(),
        endTimeDate: '',
        status: 'live',
        title: 'csgo',
        teams: [
            {
                id: '1',
                name: 'Secret',
                logoUrl: '/assets/images/teams/team-icon.png',
                teamColor: 'teams.1698',
                score: 0,
                won: false,
            },
            {
                id: '2',
                name: 'Virtus.Pro',
                logoUrl: '/assets/images/teams/team-icon.png',
                teamColor: 'teams.1698',
                score: 1,
                won: false,
            },
        ],
    },
};

export default function* generator() {
    yield mockData;
}
