import config from '@config';
import getHostname from '@utilities/getHostname';
import getDataPortal from '@config/themes/portalConfig';

import { useGetSiteBrandingQuery } from '../../queries/__generated__/getSiteBranding.generated';

import { DeepPartial, getTheme, Theme, ThemeConfig } from './index';
import transformBrandToThemeOverrides from './transformBrandToThemeOverrides';

const useBrandTheme = (): { theme: ThemeConfig & DeepPartial<Theme>; isLoading: boolean } => {
    const hostname = getHostname();

    const { data, loading: isRequestLoading } = useGetSiteBrandingQuery({
        context: { linkName: 'strapi' },
        variables: {
            domain: hostname,
        },
    });
    const brand = data?.siteByDomain?.brand;

    const shouldLoadBranding = !(config.domainsWithoutStrapiBranding || []).find(
        (domain) => hostname === domain,
    );
    const brandThemeOverrides = shouldLoadBranding ? transformBrandToThemeOverrides(brand) : {};

    const themeOverrides = getDataPortal(hostname).theme;

    const fontImportUrl = brand?.fontImportUrl;

    const theme = getTheme({ ...brandThemeOverrides, ...themeOverrides, fontImportUrl });

    return {
        theme,
        isLoading: shouldLoadBranding ? isRequestLoading : false,
    };
};

export default useBrandTheme;
