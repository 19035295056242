import {
    Enum_Componentbuttonsbutton_Size,
    Enum_Componentbuttonsbutton_Variant,
    Enum_Componentcircuithubsectionimage_Position,
    Enum_Componentcircuithubsectionstyle_Backgroundtype,
    Enum_Componentcircuithubsectionstyle_Verticalspace,
    Enum_Componentcircuithubtournamentconfig_Status,
} from '@generated';
import type {
    CircuitHubFragment,
    TournamentSectionFragment,
} from '../queries/__generated__/getCircuitHub.generated';
import { CircuitHubDetailsFragment } from '../queries/__generated__/getCircuitHubContentPages.generated';

const pageLayout: Omit<CircuitHubFragment | CircuitHubDetailsFragment, '__typename' | 'id'> = {
    navigation: {
        items: [
            {
                id: '1',
                name: 'Home',
                url: '/#home',
                icon: null,
                __typename: 'ComponentGenericNavigationItem',
            },
            {
                id: '2',
                name: 'Partners',
                url: '/#foundational-partners',
                icon: null,
                __typename: 'ComponentGenericNavigationItem',
            },
            {
                id: '16',
                name: 'Press',
                url: '/#press',
                icon: null,
                __typename: 'ComponentGenericNavigationItem',
            },
            {
                id: '17',
                name: 'Partnerships Overview',
                url: '/#partnershipsoverview',
                icon: null,
                __typename: 'ComponentGenericNavigationItem',
            },
            {
                id: '3',
                name: 'The CCT 22-24 Tour',
                url: '/#cctour',
                icon: null,
                __typename: 'ComponentGenericNavigationItem',
            },
            {
                id: '4',
                name: 'CCT Regional Impact',
                url: '/#structure',
                icon: null,
                __typename: 'ComponentGenericNavigationItem',
            },
            {
                id: '5',
                name: 'CCT Watch HUB',
                url: '/tournaments',
                icon: null,
                __typename: 'ComponentGenericNavigationItem',
            },
            {
                id: '6',
                name: 'CCT Calendar',
                url: '/#calendar',
                icon: null,
                __typename: 'ComponentGenericNavigationItem',
            },
        ],
        __typename: 'ComponentGenericNavigation',
    },
    tourLogo: {
        __typename: 'ComponentImagesImage',
        id: '1',
        alt_text: 'Alt text',
        image: {
            id: '1',
            alternativeText: '',
            caption: '',
            width: 600,
            height: 600,
            url: 'https://cdn.grid.gg/strapi/logo_main_1986c42bf7.png',
            previewUrl: null,
            __typename: 'UploadFile',
        },
    },
    site: {
        id: '62',
        domain: 'https:/cctour.gg/',
        site_title: 'Champion of Champions Tour',
        name: 'Champion of Champions Tour',
        brand: {
            id: '64',
            name: 'Champion of Champions Tour',
            primary_colors: ['#FFE55D', '#FFE042', '#FFD90F'],
            secondary_colors: ['#dbb251'],
            light_colors: ['#f2f2f3', '#e5e5e8', '#d9d9dc', '#ccccd0'],
            dark_colors: ['#0F0F0F', '#0F0F0F', '#0F0F0F', '#0F0F0F'],
            slate_colors: ['#92929c', '#858590', '#797985', '#6d6d78'],
            theme_overrides: {
                colourScheme: 'dark',
                fonts: ["'Fjalla One', sans-serif", "'Lato', sans-serif"],
                fontImports: [
                    "@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&family=Lato:wght@300&display=swap');",
                ],
                colors: {
                    base: {
                        '0': '#FFFFFF',
                        '5': '#F2F2F2',
                        '10': '#E6E6E6',
                        '15': '#D9D9D9',
                        '20': '#CCCCCC',
                        '25': '#BFBFBF',
                        '30': '#B2B2B2',
                        '35': '#A6A6A6',
                        '40': '#999999',
                        '45': '#8C8C8C',
                        '50': '#808080',
                        '55': '#737373',
                        '60': '#666666',
                        '65': '#595959',
                        '70': '#4D4D4D',
                        '75': '#404040',
                        '80': '#090808',
                        '85': '#090808',
                        '90': '#1A1A1A',
                        '95': '#0D0D0D',
                        '100': '#000000',
                    },
                },
                heading: {
                    size: {
                        h1: { fontSize: '42px', fontFamily: 0, lineHeight: '64px' },
                        h2: { fontSize: '40px', fontFamily: 0, lineHeight: '48px' },
                        h3: { fontSize: '32px', fontFamily: 0, lineHeight: '40px' },
                        h4: { fontSize: '24px', fontFamily: 0, lineHeight: '32px' },
                        h5: { fontSize: '14px', fontFamily: 1, lineHeight: '20px' },
                        h6: { fontSize: '10px', fontFamily: 1, lineHeight: '12px' },
                    },
                },
                text: {
                    size: {
                        large: {
                            fontSize: '18px',
                            lineHeight: '24px',
                            fontFamily: 1,
                            fontWeight: 300,
                        },
                        medium: {
                            fontSize: '16px',
                            lineHeight: '24px',
                            fontFamily: 1,
                            fontWeight: 300,
                        },
                        small: {
                            fontSize: '14px',
                            lineHeight: '20px',
                            fontFamily: 1,
                            fontWeight: 300,
                        },
                        xSmall: {
                            fontSize: '12px',
                            lineHeight: '16px',
                            fontFamily: 1,
                            fontWeight: 300,
                        },
                    },
                },
            },
            __typename: 'Brand',
        },
        socials: {
            id: '60',
            facebook: null,
            instagram: null,
            linkedin: null,
            twitch: 'https://www.twitch.tv/cct_en',
            twitter: 'https://twitter.com/cctour_gg',
            youtube: 'https://www.youtube.com/channel/UCrN2GuYzV7D8hcyMrq7ImUA',
            __typename: 'ComponentSocialSocials',
        },
        sponsors: [
            {
                id: '1',
                link: 'https://www.grid.gg',
                name: 'GRID',
                image: {
                    alternativeText: '',
                    caption: '',
                    width: 1662,
                    height: 918,
                    url: 'https://cdn.grid.gg/strapi/GRID_1_b1d49558b4.png',
                    previewUrl: null,
                    __typename: 'UploadFile',
                },
                __typename: 'Sponsor',
            },
            {
                id: '171',
                link: 'https://www.streamcards.gg/',
                name: 'StreamCards',
                image: {
                    alternativeText: '',
                    caption: '',
                    width: 755,
                    height: 488,
                    url: 'https://cdn.grid.gg/strapi/Stream_Cards_773d7180d2.png',
                    previewUrl: null,
                    __typename: 'UploadFile',
                },
                __typename: 'Sponsor',
            },
            {
                id: '204',
                link: 'https://aws.amazon.com/?nc2=h_lg',
                name: 'AWS',
                image: {
                    alternativeText: '',
                    caption: '',
                    width: 1200,
                    height: 719,
                    url: 'https://cdn.grid.gg/strapi/aws_1_5a8983af4a.png',
                    previewUrl: null,
                    __typename: 'UploadFile',
                },
                __typename: 'Sponsor',
            },
        ],
        __typename: 'Site',
    },
    footer: {
        linksColumnLeft: {
            items: [
                {
                    name: 'Privacy Policy',
                    url: 'http://example.com',
                    __typename: 'ComponentGenericNavigationItem',
                },
                {
                    name: 'Terms & Conditions',
                    url: 'http://example.com',
                    __typename: 'ComponentGenericNavigationItem',
                },
                {
                    name: 'Cookie Policy',
                    url: 'http://example.com',
                    __typename: 'ComponentGenericNavigationItem',
                },
                {
                    name: 'GRID',
                    url: 'grid.gg',
                    __typename: 'ComponentGenericNavigationItem',
                },
            ],
            title: 'Links',
            __typename: 'ComponentGenericNavigation',
        },
        linksColumnRight: {
            items: [
                {
                    name: 'support@grid.gg',
                    url: 'mailto:support@grid.gg',
                    __typename: 'ComponentGenericNavigationItem',
                },
                {
                    name: 'help@grid.gg',
                    url: 'mailto:support@grid.gg',
                    __typename: 'ComponentGenericNavigationItem',
                },
                {
                    name: 'cct@grid.gg',
                    url: 'cct@grid.gg',
                    __typename: 'ComponentGenericNavigationItem',
                },
            ],
            title: 'Contacts',
            __typename: 'ComponentGenericNavigation',
        },
        __typename: 'ComponentCircuitHubFooter',
    },
};

export const tournamentSection: TournamentSectionFragment = {
    __typename: 'ComponentCircuitHubTournaments',
    id: '1',
    sectionId: 'some-section',
    action: {
        id: 'tournaments',
        text: 'All Tournaments',
        href: '/tournaments',
        size: Enum_Componentbuttonsbutton_Size.Large,
        variant: Enum_Componentbuttonsbutton_Variant.Primary,
    },
    title: 'Circuit Tournaments',
    tournaments: [
        {
            id: '3',
            created_at: '2020-03-26T20:34:39.000Z',
            name: 'GRID Esports Watch Page',
            meta: {
                slug: 'csgo',
                __typename: 'ComponentPageComponentsMeta',
            },
            components: [
                {
                    __typename: 'ComponentPageComponentsSponsors',
                },
                {
                    __typename: 'ComponentPageComponentsPromoBanner',
                },
                {
                    __typename: 'ComponentPageComponentsVisualisationsWidget',
                },
                {
                    __typename: 'ComponentPageComponentsGallery',
                },
                {
                    __typename: 'ComponentPageComponentsGallery',
                },
                {
                    __typename: 'ComponentPageComponentsGallery',
                },
                {
                    __typename: 'ComponentPageComponentsPromoBanner',
                },
                {
                    __typename: 'ComponentPageComponentsVisualisationsWidget',
                },
                {
                    __typename: 'ComponentPageComponentsBestOdds',
                },
                {
                    __typename: 'ComponentPageComponentsVisualisationsWidget',
                },
                {
                    __typename: 'ComponentPageComponentsGallery',
                },
                {
                    __typename: 'ComponentPageComponentsVisualisationsWidget',
                },
                {
                    __typename: 'ComponentPageComponentsVisualisationsWidget',
                },
                {
                    __typename: 'ComponentPageComponentsVisualisationsWidget',
                },
                {
                    __typename: 'ComponentPageComponentsVisualisationsWidget',
                },
                {
                    __typename: 'ComponentPageComponentsVisualisationsWidget',
                },
                {
                    __typename: 'ComponentPageComponentsVisualisationsWidget',
                },
                {
                    __typename: 'ComponentPageComponentsVisualisationsWidget',
                },
                {
                    logo: {
                        url:
                            'https://cdn-stg.grid.gg/strapi/2e7204b9f0c86ce0ee42c781d93b32f5_1e7be28078.png',
                        __typename: 'UploadFile',
                    },
                    status: Enum_Componentcircuithubtournamentconfig_Status.Completed,
                    period: 'Next Year',
                    name: 'Whitelabel Tour',
                    __typename: 'ComponentCircuitHubTournamentConfig',
                },
            ],
            __typename: 'Page',
        },
        {
            __typename: 'Page',
            id: '11',
            created_at: '2022-03-04T09:32:42.000Z',
            name: 'GRID Tour Watch Page',
            meta: {
                slug: 'csgo',
                __typename: 'ComponentPageComponentsMeta',
            },
            components: [
                {
                    __typename: 'ComponentPageComponentsSponsors',
                },
                {
                    __typename: 'ComponentPageComponentsPromoBanner',
                },
                {
                    __typename: 'ComponentPageComponentsVisualisationsWidget',
                },
                {
                    __typename: 'ComponentPageComponentsGallery',
                },
                {
                    __typename: 'ComponentPageComponentsGallery',
                },
                {
                    __typename: 'ComponentPageComponentsGallery',
                },
                {
                    __typename: 'ComponentPageComponentsPromoBanner',
                },
                {
                    __typename: 'ComponentPageComponentsVisualisationsWidget',
                },
                {
                    __typename: 'ComponentPageComponentsBestOdds',
                },
                {
                    __typename: 'ComponentPageComponentsVisualisationsWidget',
                },
                {
                    __typename: 'ComponentPageComponentsGallery',
                },
                {
                    __typename: 'ComponentPageComponentsVisualisationsWidget',
                },
                {
                    __typename: 'ComponentPageComponentsVisualisationsWidget',
                },
                {
                    __typename: 'ComponentPageComponentsVisualisationsWidget',
                },
                {
                    __typename: 'ComponentPageComponentsVisualisationsWidget',
                },
                {
                    __typename: 'ComponentPageComponentsVisualisationsWidget',
                },
                {
                    __typename: 'ComponentPageComponentsVisualisationsWidget',
                },
                {
                    __typename: 'ComponentPageComponentsVisualisationsWidget',
                },
                {
                    logo: null,
                    status: Enum_Componentcircuithubtournamentconfig_Status.Live,
                    period: 'Ryan',
                    name: 'GRID Championship',
                    __typename: 'ComponentCircuitHubTournamentConfig',
                },
                {
                    __typename: 'ComponentPageComponentsVisualisationsWidget',
                },
                {
                    __typename: 'ComponentPageComponentsVisualisationsWidget',
                },
            ],
        },
    ],
};

const circuitHubFixture: CircuitHubFragment = {
    ...pageLayout,
    id: '1',
    __typename: 'CircuitHub',
    sections: [
        {
            __typename: 'ComponentCircuitHubBasicSection',
            id: '4',
            sectionId: 'home',
            text: {
                id: '4',
                title: 'THE LARGEST OPEN CSGO TOUR IN THE WORLD',
                subtitle: 'CHAMPION OF CHAMPIONS TOUR JUNE 2022 - JANUARY 2024',
                description:
                    "Welcome to the new global series of CS:GO competitions - **The Champions of Champions Tour** brought to you by GRID, the world's leading esports data platform, in partnership with 10-11 International Tournament Operators. Join us and experience an incredible fight for one of the biggest prize pools in CS:GO history - **$3,400,000**.",
                __typename: 'ComponentCircuitHubSectionText',
            },
            image: {
                id: '4',
                content: {
                    id: '436',
                    alt_text: null,
                    image: {
                        id: '3816',
                        alternativeText: '',
                        caption: '',
                        width: 2076,
                        height: 1870,
                        url: 'https://cdn.grid.gg/strapi/Frame_368_c7653aa6b5.png',
                        previewUrl: null,
                        __typename: 'UploadFile',
                    },
                    __typename: 'ComponentImagesImage',
                },
                position: Enum_Componentcircuithubsectionimage_Position.Right,
                __typename: 'ComponentCircuitHubSectionImage',
            },
            style: {
                id: '4',
                backgroundColor: 'base.85',
                backgroundType: Enum_Componentcircuithubsectionstyle_Backgroundtype.Rectangle,
                verticalSpace: Enum_Componentcircuithubsectionstyle_Verticalspace.None,
                __typename: 'ComponentCircuitHubSectionStyle',
            },
            action: null,
        },
        {
            __typename: 'ComponentCircuitHubPointsSection',
            id: '3',
            sectionId: null,
            points: [
                {
                    id: '7',
                    textTop: 'Spanning 7 Tranches',
                    textBottom: 'Across the Globe',
                    icon: null,
                    __typename: 'ComponentCircuitHubSectionPoint',
                },
                {
                    id: '8',
                    textTop: 'Over 3000 Bo3',
                    textBottom: 'Match Ups',
                    icon: null,
                    __typename: 'ComponentCircuitHubSectionPoint',
                },
                {
                    id: '11',
                    textTop: 'Over 10000 Hours',
                    textBottom: 'Of Broadcast',
                    icon: null,
                    __typename: 'ComponentCircuitHubSectionPoint',
                },
                {
                    id: '12',
                    textTop: 'Consolidating 10-11 Leading',
                    textBottom: 'Tournament Organisers',
                    icon: null,
                    __typename: 'ComponentCircuitHubSectionPoint',
                },
            ],
            style: {
                id: '31',
                backgroundColor: null,
                backgroundType: null,
                verticalSpace: Enum_Componentcircuithubsectionstyle_Verticalspace.None,
                __typename: 'ComponentCircuitHubSectionStyle',
            },
        },
        {
            __typename: 'ComponentCircuitHubBasicSection',
            id: '5',
            sectionId: 'foundational-partners',
            text: {
                id: '5',
                title: '',
                subtitle: '',
                description: '',
                __typename: 'ComponentCircuitHubSectionText',
            },
            image: {
                id: '41',
                content: {
                    id: '473',
                    alt_text: null,
                    image: {
                        id: '3954',
                        alternativeText: '',
                        caption: '',
                        width: 6540,
                        height: 2601,
                        url: 'https://cdn.grid.gg/strapi/foundational_3504ab4b91.png',
                        previewUrl: null,
                        __typename: 'UploadFile',
                    },
                    __typename: 'ComponentImagesImage',
                },
                position: Enum_Componentcircuithubsectionimage_Position.Stretch,
                __typename: 'ComponentCircuitHubSectionImage',
            },
            style: {
                id: '3',
                backgroundColor: 'base.85',
                backgroundType: Enum_Componentcircuithubsectionstyle_Backgroundtype.DiagonalDown,
                verticalSpace: Enum_Componentcircuithubsectionstyle_Verticalspace.None,
                __typename: 'ComponentCircuitHubSectionStyle',
            },
            action: null,
        },
        {
            __typename: 'ComponentCircuitHubBasicSection',
            id: '19',
            sectionId: 'regional-partners',
            text: {
                id: '15',
                title: '',
                subtitle: '',
                description: '',
                __typename: 'ComponentCircuitHubSectionText',
            },
            image: {
                id: '42',
                content: {
                    id: '474',
                    alt_text: null,
                    image: {
                        id: '4081',
                        alternativeText: '',
                        caption: '',
                        width: 3270,
                        height: 1715,
                        url: 'https://cdn.grid.gg/strapi/REGIO_5ef7600809.png',
                        previewUrl: null,
                        __typename: 'UploadFile',
                    },
                    __typename: 'ComponentImagesImage',
                },
                position: Enum_Componentcircuithubsectionimage_Position.Stretch,
                __typename: 'ComponentCircuitHubSectionImage',
            },
            style: {
                id: '10',
                backgroundColor: 'base.85',
                backgroundType: Enum_Componentcircuithubsectionstyle_Backgroundtype.DiagonalUp,
                verticalSpace: Enum_Componentcircuithubsectionstyle_Verticalspace.None,
                __typename: 'ComponentCircuitHubSectionStyle',
            },
            action: null,
        },
        {
            __typename: 'ComponentCircuitHubBasicSection',
            id: '18',
            sectionId: 'press',
            text: {
                id: '14',
                title: 'PRESS',
                subtitle: null,
                description: 'Find out more about the path to CCT 22 - 24.',
                __typename: 'ComponentCircuitHubSectionText',
            },
            image: {
                id: '17',
                content: {
                    id: '449',
                    alt_text: null,
                    image: {
                        id: '3949',
                        alternativeText: '',
                        caption: '',
                        width: 1091,
                        height: 708,
                        url: 'https://cdn.grid.gg/strapi/Stage_path_0b5eed7b1d.png',
                        previewUrl: null,
                        __typename: 'UploadFile',
                    },
                    __typename: 'ComponentImagesImage',
                },
                position: Enum_Componentcircuithubsectionimage_Position.Left,
                __typename: 'ComponentCircuitHubSectionImage',
            },
            style: {
                id: '8',
                backgroundColor: 'base.85',
                backgroundType: Enum_Componentcircuithubsectionstyle_Backgroundtype.DiagonalDown,
                verticalSpace: null,
                __typename: 'ComponentCircuitHubSectionStyle',
            },
            action: {
                id: '79',
                text: 'VISIT OUR PRESS PAGE',
                href: '/press',
                variant: null,
                size: Enum_Componentbuttonsbutton_Size.Large,
                __typename: 'ComponentButtonsButton',
            },
        },
        {
            __typename: 'ComponentCircuitHubBasicSection',
            id: '21',
            sectionId: 'partnershipsoverview',
            text: {
                id: '17',
                title: 'PARTNERSHIPS OVERVIEW',
                subtitle: null,
                description:
                    "An overview and description of each of GRID's foundational & regional partners.  Brief description of their history, speciality, and regional focus for CCT. ",
                __typename: 'ComponentCircuitHubSectionText',
            },
            image: {
                id: '21',
                content: {
                    id: '453',
                    alt_text: null,
                    image: {
                        id: '3791',
                        alternativeText: '',
                        caption: '',
                        width: 1091,
                        height: 708,
                        url: 'https://cdn.grid.gg/strapi/partnership_overview_a61ca78fd5.png',
                        previewUrl: null,
                        __typename: 'UploadFile',
                    },
                    __typename: 'ComponentImagesImage',
                },
                position: Enum_Componentcircuithubsectionimage_Position.Right,
                __typename: 'ComponentCircuitHubSectionImage',
            },
            style: {
                id: '11',
                backgroundColor: 'base.85',
                backgroundType: Enum_Componentcircuithubsectionstyle_Backgroundtype.DiagonalDown,
                verticalSpace: null,
                __typename: 'ComponentCircuitHubSectionStyle',
            },
            action: {
                id: '83',
                text: 'CHECK OUR OVERVIEW',
                href: '/partnershipsoverview',
                variant: Enum_Componentbuttonsbutton_Variant.Primary,
                size: Enum_Componentbuttonsbutton_Size.Large,
                __typename: 'ComponentButtonsButton',
            },
        },
        {
            __typename: 'ComponentCircuitHubTabsSection',
            id: '1',
            sectionId: 'cctour',
            title: 'THE CCT 22-24 TOUR',
            tabs: [
                {
                    id: '1',
                    title: 'Prize Pool',
                    content: [
                        {
                            __typename: 'ComponentCircuitHubBasicSection',
                            id: '7',
                            sectionId: 'intro',
                            text: {
                                id: '7',
                                title: null,
                                subtitle: null,
                                description:
                                    'The combined total Prize Pool of the Champion of Champions Tour shall be **$3,400,000**. A total of **$1,800,000** shall be distributed across 44 Online Cups, along with **$1,100,000** across 11 Studio Finals, culminating in a **$500,000** Arena Final. The Prize Pool is distributed through **7** unique tranches and shall help bring sustainability and integrity to competitions throughout CSGO.',
                                __typename: 'ComponentCircuitHubSectionText',
                            },
                            image: {
                                id: '10',
                                content: {
                                    id: '442',
                                    alt_text: null,
                                    image: {
                                        id: '4029',
                                        alternativeText: '',
                                        caption: '',
                                        width: 1983,
                                        height: 684,
                                        url:
                                            'https://cdn.grid.gg/strapi/Group_11822_f82d6184a8.png',
                                        previewUrl: null,
                                        __typename: 'UploadFile',
                                    },
                                    __typename: 'ComponentImagesImage',
                                },
                                position: Enum_Componentcircuithubsectionimage_Position.Right,
                                __typename: 'ComponentCircuitHubSectionImage',
                            },
                            style: null,
                            action: null,
                        },
                        {
                            __typename: 'ComponentCircuitHubBasicSection',
                            id: '8',
                            sectionId: 'image',
                            text: {
                                id: '8',
                                title: null,
                                subtitle: null,
                                description: null,
                                __typename: 'ComponentCircuitHubSectionText',
                            },
                            image: {
                                id: '11',
                                content: {
                                    id: '443',
                                    alt_text: null,
                                    image: {
                                        id: '4030',
                                        alternativeText: '',
                                        caption: '',
                                        width: 2010,
                                        height: 999,
                                        url:
                                            'https://cdn.grid.gg/strapi/Group_11623_b6c7240e97.png',
                                        previewUrl: null,
                                        __typename: 'UploadFile',
                                    },
                                    __typename: 'ComponentImagesImage',
                                },
                                position: Enum_Componentcircuithubsectionimage_Position.Stretch,
                                __typename: 'ComponentCircuitHubSectionImage',
                            },
                            style: null,
                            action: null,
                        },
                    ],
                    __typename: 'ComponentCircuitHubSectionTab',
                },
                {
                    id: '6',
                    title: 'Open Tour Points',
                    content: [
                        {
                            __typename: 'ComponentCircuitHubBasicSection',
                            id: '37',
                            sectionId: 'points',
                            text: {
                                id: '32',
                                title: null,
                                subtitle: null,
                                description:
                                    'The Point System will follow, quantify, and reward the performance of CCT participants. Teams shall get points based on their placement in the following types of individual tournaments - Online Cups and Studio Finals. There will be Regional and Global rankings that will respectively provide slots for the Studio LAN Events and the Global Finals Event.\n',
                                __typename: 'ComponentCircuitHubSectionText',
                            },
                            image: null,
                            style: null,
                            action: null,
                        },
                        {
                            __typename: 'ComponentCircuitHubBasicSection',
                            id: '38',
                            sectionId: 'image',
                            text: null,
                            image: {
                                id: '37',
                                content: {
                                    id: '469',
                                    alt_text: null,
                                    image: {
                                        id: '4073',
                                        alternativeText: '',
                                        caption: '',
                                        width: 7680,
                                        height: 4320,
                                        url:
                                            'https://cdn.grid.gg/strapi/Open_tour_points_min_ceb7952d8e.png',
                                        previewUrl: null,
                                        __typename: 'UploadFile',
                                    },
                                    __typename: 'ComponentImagesImage',
                                },
                                position: Enum_Componentcircuithubsectionimage_Position.Stretch,
                                __typename: 'ComponentCircuitHubSectionImage',
                            },
                            style: null,
                            action: null,
                        },
                    ],
                    __typename: 'ComponentCircuitHubSectionTab',
                },
                {
                    id: '7',
                    title: 'Regional Seedings',
                    content: [
                        {
                            __typename: 'ComponentCircuitHubBasicSection',
                            id: '39',
                            sectionId: 'regionalseedings',
                            text: {
                                id: '33',
                                title: null,
                                subtitle: null,
                                description:
                                    'Teams get seeded in the various tournament stages of the Champion of Champions tour by qualifying from a previous stage or getting a direct invite. Direct invite seeds can be activated by regional partners through tournaments/academy leagues outside CCT or awarded to specific teams based on their overall performance and prestige. \nFurthermore, the Blast Rising winners will participate in a special event for a chance to earn a spot in the Global Finals while the CCT crowned champion shall qualify for Blast Premier.',
                                __typename: 'ComponentCircuitHubSectionText',
                            },
                            image: null,
                            style: null,
                            action: null,
                        },
                        {
                            __typename: 'ComponentCircuitHubBasicSection',
                            id: '40',
                            sectionId: 'image',
                            text: null,
                            image: {
                                id: '38',
                                content: {
                                    id: '470',
                                    alt_text: null,
                                    image: {
                                        id: '4072',
                                        alternativeText: '',
                                        caption: '',
                                        width: 5760,
                                        height: 2622,
                                        url:
                                            'https://cdn.grid.gg/strapi/Regional_Seedings_min_e1b14601af.png',
                                        previewUrl: null,
                                        __typename: 'UploadFile',
                                    },
                                    __typename: 'ComponentImagesImage',
                                },
                                position: Enum_Componentcircuithubsectionimage_Position.Stretch,
                                __typename: 'ComponentCircuitHubSectionImage',
                            },
                            style: null,
                            action: null,
                        },
                    ],
                    __typename: 'ComponentCircuitHubSectionTab',
                },
                {
                    id: '8',
                    title: 'Format',
                    content: [
                        {
                            __typename: 'ComponentCircuitHubBasicSection',
                            id: '41',
                            sectionId: 'format',
                            text: {
                                id: '34',
                                title: null,
                                subtitle: null,
                                description:
                                    'Across each section of The Tour (Online/Studio/Arena) the format and match faciliation shall be consistent and uniform. The Online Cups shall consist of a Regional Open Qualification that shall submit 4 Regional Qualifiers to a Play In. From the Play In teams shall graduate to the Main Online stage of the Tour. Winners of the Main Online Stage shall be rewarded with spots in the CCT Studio Finals. \n',
                                __typename: 'ComponentCircuitHubSectionText',
                            },
                            image: null,
                            style: null,
                            action: null,
                        },
                        {
                            __typename: 'ComponentCircuitHubBasicSection',
                            id: '42',
                            sectionId: 'image',
                            text: {
                                id: '35',
                                title: null,
                                subtitle: null,
                                description: null,
                                __typename: 'ComponentCircuitHubSectionText',
                            },
                            image: {
                                id: '40',
                                content: {
                                    id: '472',
                                    alt_text: null,
                                    image: {
                                        id: '3783',
                                        alternativeText: '',
                                        caption: '',
                                        width: 2292,
                                        height: 1382,
                                        url:
                                            'https://cdn.grid.gg/strapi/format_asset_517843b4a2.png',
                                        previewUrl: null,
                                        __typename: 'UploadFile',
                                    },
                                    __typename: 'ComponentImagesImage',
                                },
                                position: Enum_Componentcircuithubsectionimage_Position.Stretch,
                                __typename: 'ComponentCircuitHubSectionImage',
                            },
                            style: null,
                            action: null,
                        },
                    ],
                    __typename: 'ComponentCircuitHubSectionTab',
                },
                {
                    id: '9',
                    title: 'Tournaments',
                    content: [
                        {
                            __typename: 'ComponentCircuitHubBasicSection',
                            id: '43',
                            sectionId: 'tournaments',
                            text: {
                                id: '36',
                                title: null,
                                subtitle: null,
                                description:
                                    'In total CCT 22-24 shall make up 55 competitions within 7 regions that span the globe. The tournaments shall be played across several time zones which should facilitate around the clock CSGO. Each regional tranche shall incorporate 4 Online Cups. Online Cup winners shall graduate to the Studio Finals, where winners shall then have the opportunity to complete the first CCT Arena Final. ',
                                __typename: 'ComponentCircuitHubSectionText',
                            },
                            image: null,
                            style: null,
                            action: null,
                        },
                        {
                            __typename: 'ComponentCircuitHubBasicSection',
                            id: '44',
                            sectionId: null,
                            text: null,
                            image: {
                                id: '39',
                                content: {
                                    id: '471',
                                    alt_text: null,
                                    image: {
                                        id: '4009',
                                        alternativeText: '',
                                        caption: '',
                                        width: 4952,
                                        height: 2508,
                                        url:
                                            'https://cdn.grid.gg/strapi/Frame_17908_508362da0b.png',
                                        previewUrl: null,
                                        __typename: 'UploadFile',
                                    },
                                    __typename: 'ComponentImagesImage',
                                },
                                position: Enum_Componentcircuithubsectionimage_Position.Stretch,
                                __typename: 'ComponentCircuitHubSectionImage',
                            },
                            style: null,
                            action: null,
                        },
                    ],
                    __typename: 'ComponentCircuitHubSectionTab',
                },
            ],
            style: null,
        },
        {
            __typename: 'ComponentCircuitHubTabsSection',
            id: '2',
            sectionId: null,
            title: 'CCT REGIONAL IMPACT',
            tabs: [
                {
                    id: '2',
                    title: 'Global',
                    content: [
                        {
                            __typename: 'ComponentCircuitHubBasicSection',
                            id: '9',
                            sectionId: 'structure',
                            text: null,
                            image: {
                                id: '5',
                                content: {
                                    id: '437',
                                    alt_text: null,
                                    image: {
                                        id: '4056',
                                        alternativeText: '',
                                        caption: '',
                                        width: 2089,
                                        height: 1219,
                                        url:
                                            'https://cdn.grid.gg/strapi/map_website_7602a914ce.png',
                                        previewUrl: null,
                                        __typename: 'UploadFile',
                                    },
                                    __typename: 'ComponentImagesImage',
                                },
                                position: Enum_Componentcircuithubsectionimage_Position.Stretch,
                                __typename: 'ComponentCircuitHubSectionImage',
                            },
                            style: null,
                            action: null,
                        },
                    ],
                    __typename: 'ComponentCircuitHubSectionTab',
                },
                {
                    id: '15',
                    title: 'North America',
                    content: [
                        {
                            __typename: 'ComponentCircuitHubBasicSection',
                            id: '50',
                            sectionId: null,
                            text: null,
                            image: {
                                id: '48',
                                content: {
                                    id: '480',
                                    alt_text: null,
                                    image: {
                                        id: '4048',
                                        alternativeText: '',
                                        caption: '',
                                        width: 3215,
                                        height: 2324,
                                        url:
                                            'https://cdn.grid.gg/strapi/North_America_1aabe62aef.png',
                                        previewUrl: null,
                                        __typename: 'UploadFile',
                                    },
                                    __typename: 'ComponentImagesImage',
                                },
                                position: Enum_Componentcircuithubsectionimage_Position.Stretch,
                                __typename: 'ComponentCircuitHubSectionImage',
                            },
                            style: null,
                            action: null,
                        },
                    ],
                    __typename: 'ComponentCircuitHubSectionTab',
                },
                {
                    id: '14',
                    title: 'South America',
                    content: [
                        {
                            __typename: 'ComponentCircuitHubBasicSection',
                            id: '49',
                            sectionId: null,
                            text: null,
                            image: {
                                id: '47',
                                content: {
                                    id: '479',
                                    alt_text: null,
                                    image: {
                                        id: '4079',
                                        alternativeText: '',
                                        caption: '',
                                        width: 3215,
                                        height: 2324,
                                        url:
                                            'https://cdn.grid.gg/strapi/South_America_1918209bec.png',
                                        previewUrl: null,
                                        __typename: 'UploadFile',
                                    },
                                    __typename: 'ComponentImagesImage',
                                },
                                position: Enum_Componentcircuithubsectionimage_Position.Stretch,
                                __typename: 'ComponentCircuitHubSectionImage',
                            },
                            style: null,
                            action: null,
                        },
                    ],
                    __typename: 'ComponentCircuitHubSectionTab',
                },
                {
                    id: '12',
                    title: 'West Europe',
                    content: [
                        {
                            __typename: 'ComponentCircuitHubBasicSection',
                            id: '47',
                            sectionId: null,
                            text: null,
                            image: {
                                id: '45',
                                content: {
                                    id: '477',
                                    alt_text: null,
                                    image: {
                                        id: '4046',
                                        alternativeText: '',
                                        caption: '',
                                        width: 3215,
                                        height: 2324,
                                        url:
                                            'https://cdn.grid.gg/strapi/Western_Europe_534f077daa.png',
                                        previewUrl: null,
                                        __typename: 'UploadFile',
                                    },
                                    __typename: 'ComponentImagesImage',
                                },
                                position: Enum_Componentcircuithubsectionimage_Position.Stretch,
                                __typename: 'ComponentCircuitHubSectionImage',
                            },
                            style: null,
                            action: null,
                        },
                    ],
                    __typename: 'ComponentCircuitHubSectionTab',
                },
                {
                    id: '16',
                    title: 'Central Europe',
                    content: [
                        {
                            __typename: 'ComponentCircuitHubBasicSection',
                            id: '52',
                            sectionId: null,
                            text: null,
                            image: {
                                id: '50',
                                content: {
                                    id: '482',
                                    alt_text: null,
                                    image: {
                                        id: '4045',
                                        alternativeText: '',
                                        caption: '',
                                        width: 3215,
                                        height: 2324,
                                        url:
                                            'https://cdn.grid.gg/strapi/Central_Europe_7d7e2651e5.png',
                                        previewUrl: null,
                                        __typename: 'UploadFile',
                                    },
                                    __typename: 'ComponentImagesImage',
                                },
                                position: Enum_Componentcircuithubsectionimage_Position.Stretch,
                                __typename: 'ComponentCircuitHubSectionImage',
                            },
                            style: null,
                            action: null,
                        },
                    ],
                    __typename: 'ComponentCircuitHubSectionTab',
                },
                {
                    id: '13',
                    title: 'North Europe',
                    content: [
                        {
                            __typename: 'ComponentCircuitHubBasicSection',
                            id: '48',
                            sectionId: null,
                            text: null,
                            image: {
                                id: '46',
                                content: {
                                    id: '478',
                                    alt_text: null,
                                    image: {
                                        id: '4042',
                                        alternativeText: '',
                                        caption: '',
                                        width: 3215,
                                        height: 2324,
                                        url: 'https://cdn.grid.gg/strapi/Nordics_0883eb600e.png',
                                        previewUrl: null,
                                        __typename: 'UploadFile',
                                    },
                                    __typename: 'ComponentImagesImage',
                                },
                                position: Enum_Componentcircuithubsectionimage_Position.Stretch,
                                __typename: 'ComponentCircuitHubSectionImage',
                            },
                            style: null,
                            action: null,
                        },
                    ],
                    __typename: 'ComponentCircuitHubSectionTab',
                },
                {
                    id: '10',
                    title: 'South Europe',
                    content: [
                        {
                            __typename: 'ComponentCircuitHubBasicSection',
                            id: '45',
                            sectionId: null,
                            text: null,
                            image: {
                                id: '43',
                                content: {
                                    id: '475',
                                    alt_text: null,
                                    image: {
                                        id: '4077',
                                        alternativeText: '',
                                        caption: '',
                                        width: 3215,
                                        height: 2324,
                                        url:
                                            'https://cdn.grid.gg/strapi/Southern_Europe_2d4c77f736.png',
                                        previewUrl: null,
                                        __typename: 'UploadFile',
                                    },
                                    __typename: 'ComponentImagesImage',
                                },
                                position: Enum_Componentcircuithubsectionimage_Position.Stretch,
                                __typename: 'ComponentCircuitHubSectionImage',
                            },
                            style: null,
                            action: null,
                        },
                    ],
                    __typename: 'ComponentCircuitHubSectionTab',
                },
                {
                    id: '11',
                    title: 'East Europe',
                    content: [
                        {
                            __typename: 'ComponentCircuitHubBasicSection',
                            id: '46',
                            sectionId: null,
                            text: null,
                            image: {
                                id: '44',
                                content: {
                                    id: '476',
                                    alt_text: null,
                                    image: {
                                        id: '4047',
                                        alternativeText: '',
                                        caption: '',
                                        width: 3215,
                                        height: 2324,
                                        url:
                                            'https://cdn.grid.gg/strapi/Eastern_Europe_b62d446688.png',
                                        previewUrl: null,
                                        __typename: 'UploadFile',
                                    },
                                    __typename: 'ComponentImagesImage',
                                },
                                position: Enum_Componentcircuithubsectionimage_Position.Stretch,
                                __typename: 'ComponentCircuitHubSectionImage',
                            },
                            style: null,
                            action: null,
                        },
                    ],
                    __typename: 'ComponentCircuitHubSectionTab',
                },
            ],
            style: {
                id: '6',
                backgroundColor: 'base.85',
                backgroundType: Enum_Componentcircuithubsectionstyle_Backgroundtype.Rectangle,
                verticalSpace: null,
                __typename: 'ComponentCircuitHubSectionStyle',
            },
        },
        {
            __typename: 'ComponentCircuitHubTournaments',
            id: '1',
            sectionId: 'tournaments',
            title: 'CCT WATCH HUB',
            action: {
                id: '76',
                text: 'ACCESS ALL TOURNAMENTS HERE',
                href: 'https://cctour.gg/tournaments',
                variant: null,
                size: null,
                __typename: 'ComponentButtonsButton',
            },
            tournaments: [
                {
                    id: '87',
                    created_at: '2022-04-14T10:50:50.000Z',
                    name: '0 - CCT_East Europe Watch Page',
                    meta: { slug: 'csgo', __typename: 'ComponentPageComponentsMeta' },
                    components: [
                        { __typename: 'ComponentPageComponentsSponsors' },
                        { __typename: 'ComponentPageComponentsPromoBanner' },
                        { __typename: 'ComponentPageComponentsGallery' },
                        { __typename: 'ComponentPageComponentsGallery' },
                        { __typename: 'ComponentPageComponentsGallery' },
                        { __typename: 'ComponentPageComponentsPromoBanner' },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                        { __typename: 'ComponentPageComponentsBestOdds' },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                        {
                            logo: {
                                url: 'https://cdn.grid.gg/strapi/CCT_EE_LOGO_e8cca8bf91.png',
                                __typename: 'UploadFile',
                            },
                            status: Enum_Componentcircuithubtournamentconfig_Status.Upcoming,
                            period: '',
                            name: 'CCT EAST EUROPE',
                            __typename: 'ComponentCircuitHubTournamentConfig',
                        },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                    ],
                    __typename: 'Page',
                },
                {
                    id: '88',
                    created_at: '2022-04-21T11:56:53.000Z',
                    name: '0 - CCT_West Europe Watch Page',
                    meta: { slug: 'csgo', __typename: 'ComponentPageComponentsMeta' },
                    components: [
                        { __typename: 'ComponentPageComponentsSponsors' },
                        { __typename: 'ComponentPageComponentsPromoBanner' },
                        { __typename: 'ComponentPageComponentsGallery' },
                        { __typename: 'ComponentPageComponentsGallery' },
                        { __typename: 'ComponentPageComponentsGallery' },
                        { __typename: 'ComponentPageComponentsPromoBanner' },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                        { __typename: 'ComponentPageComponentsBestOdds' },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                        {
                            logo: {
                                url: 'https://cdn.grid.gg/strapi/CCT_WE_LOGO_4fe56b65af.png',
                                __typename: 'UploadFile',
                            },
                            status: Enum_Componentcircuithubtournamentconfig_Status.Upcoming,
                            period: '',
                            name: 'CCT WEST EUROPE',
                            __typename: 'ComponentCircuitHubTournamentConfig',
                        },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                    ],
                    __typename: 'Page',
                },
                {
                    id: '89',
                    created_at: '2022-04-21T11:59:48.000Z',
                    name: '0 - CCT_South Europe Watch Page',
                    meta: { slug: 'csgo', __typename: 'ComponentPageComponentsMeta' },
                    components: [
                        { __typename: 'ComponentPageComponentsSponsors' },
                        { __typename: 'ComponentPageComponentsPromoBanner' },
                        { __typename: 'ComponentPageComponentsGallery' },
                        { __typename: 'ComponentPageComponentsGallery' },
                        { __typename: 'ComponentPageComponentsGallery' },
                        { __typename: 'ComponentPageComponentsPromoBanner' },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                        { __typename: 'ComponentPageComponentsBestOdds' },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                        {
                            logo: {
                                url: 'https://cdn.grid.gg/strapi/CCT_SE_LOGO_531e668e9b.png',
                                __typename: 'UploadFile',
                            },
                            status: Enum_Componentcircuithubtournamentconfig_Status.Upcoming,
                            period: '',
                            name: 'CCT SOUTH EUROPE',
                            __typename: 'ComponentCircuitHubTournamentConfig',
                        },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                    ],
                    __typename: 'Page',
                },
                {
                    id: '90',
                    created_at: '2022-04-21T12:09:15.000Z',
                    name: '0 - CCT_Central Europe Watch Page',
                    meta: { slug: 'csgo', __typename: 'ComponentPageComponentsMeta' },
                    components: [
                        { __typename: 'ComponentPageComponentsSponsors' },
                        { __typename: 'ComponentPageComponentsPromoBanner' },
                        { __typename: 'ComponentPageComponentsGallery' },
                        { __typename: 'ComponentPageComponentsGallery' },
                        { __typename: 'ComponentPageComponentsGallery' },
                        { __typename: 'ComponentPageComponentsPromoBanner' },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                        { __typename: 'ComponentPageComponentsBestOdds' },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                        {
                            logo: {
                                url: 'https://cdn.grid.gg/strapi/CCT_CE_LOGO_1b08f316fe.png',
                                __typename: 'UploadFile',
                            },
                            status: Enum_Componentcircuithubtournamentconfig_Status.Upcoming,
                            period: '',
                            name: 'CCT CENTRAL EUROPE',
                            __typename: 'ComponentCircuitHubTournamentConfig',
                        },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                    ],
                    __typename: 'Page',
                },
                {
                    id: '91',
                    created_at: '2022-04-21T12:27:29.000Z',
                    name: '0 - CCT_North Europe Watch Page',
                    meta: { slug: 'csgo', __typename: 'ComponentPageComponentsMeta' },
                    components: [
                        { __typename: 'ComponentPageComponentsSponsors' },
                        { __typename: 'ComponentPageComponentsPromoBanner' },
                        { __typename: 'ComponentPageComponentsGallery' },
                        { __typename: 'ComponentPageComponentsGallery' },
                        { __typename: 'ComponentPageComponentsGallery' },
                        { __typename: 'ComponentPageComponentsPromoBanner' },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                        { __typename: 'ComponentPageComponentsBestOdds' },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                        {
                            logo: {
                                url: 'https://cdn.grid.gg/strapi/CCT_NE_LOGO_7ff2473342.png',
                                __typename: 'UploadFile',
                            },
                            status: Enum_Componentcircuithubtournamentconfig_Status.Upcoming,
                            period: '',
                            name: 'CCT NORTH EUROPE',
                            __typename: 'ComponentCircuitHubTournamentConfig',
                        },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                    ],
                    __typename: 'Page',
                },
                {
                    id: '92',
                    created_at: '2022-04-21T12:33:41.000Z',
                    name: '0 - CCT_North America Watch Page',
                    meta: { slug: 'csgo', __typename: 'ComponentPageComponentsMeta' },
                    components: [
                        { __typename: 'ComponentPageComponentsSponsors' },
                        { __typename: 'ComponentPageComponentsPromoBanner' },
                        { __typename: 'ComponentPageComponentsGallery' },
                        { __typename: 'ComponentPageComponentsGallery' },
                        { __typename: 'ComponentPageComponentsGallery' },
                        { __typename: 'ComponentPageComponentsPromoBanner' },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                        { __typename: 'ComponentPageComponentsBestOdds' },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                        {
                            logo: {
                                url: 'https://cdn.grid.gg/strapi/CCT_NA_LOGO_eee99f6f71.png',
                                __typename: 'UploadFile',
                            },
                            status: Enum_Componentcircuithubtournamentconfig_Status.Upcoming,
                            period: '',
                            name: 'CCT NORTH AMERICA',
                            __typename: 'ComponentCircuitHubTournamentConfig',
                        },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                    ],
                    __typename: 'Page',
                },
                {
                    id: '93',
                    created_at: '2022-04-21T12:37:48.000Z',
                    name: '0 - CCT_South America Watch Page',
                    meta: { slug: 'csgo', __typename: 'ComponentPageComponentsMeta' },
                    components: [
                        { __typename: 'ComponentPageComponentsSponsors' },
                        { __typename: 'ComponentPageComponentsPromoBanner' },
                        { __typename: 'ComponentPageComponentsGallery' },
                        { __typename: 'ComponentPageComponentsGallery' },
                        { __typename: 'ComponentPageComponentsGallery' },
                        { __typename: 'ComponentPageComponentsPromoBanner' },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                        { __typename: 'ComponentPageComponentsBestOdds' },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                        {
                            logo: {
                                url: 'https://cdn.grid.gg/strapi/CCT_SA_LOGO_ea16786f93.png',
                                __typename: 'UploadFile',
                            },
                            status: Enum_Componentcircuithubtournamentconfig_Status.Upcoming,
                            period: '',
                            name: 'CCT SOUTH AMERICA',
                            __typename: 'ComponentCircuitHubTournamentConfig',
                        },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                        { __typename: 'ComponentPageComponentsVisualisationsWidget' },
                    ],
                    __typename: 'Page',
                },
            ],
        },
        {
            __typename: 'ComponentCircuitHubTabsSection',
            id: '3',
            sectionId: 'calendar',
            title: 'CCT CALENDAR',
            tabs: [
                {
                    id: '3',
                    title: 'Overview',
                    content: [
                        {
                            __typename: 'ComponentCircuitHubBasicSection',
                            id: '10',
                            sectionId: 'online',
                            text: null,
                            image: {
                                id: '6',
                                content: {
                                    id: '438',
                                    alt_text: null,
                                    image: {
                                        id: '3827',
                                        alternativeText: '',
                                        caption: '',
                                        width: 1028,
                                        height: 457,
                                        url:
                                            'https://cdn.grid.gg/strapi/CCT_Roadmap_f1849051f2.png',
                                        previewUrl: null,
                                        __typename: 'UploadFile',
                                    },
                                    __typename: 'ComponentImagesImage',
                                },
                                position: Enum_Componentcircuithubsectionimage_Position.Stretch,
                                __typename: 'ComponentCircuitHubSectionImage',
                            },
                            style: null,
                            action: null,
                        },
                    ],
                    __typename: 'ComponentCircuitHubSectionTab',
                },
            ],
            style: {
                id: '7',
                backgroundColor: 'base.85',
                backgroundType: Enum_Componentcircuithubsectionstyle_Backgroundtype.Rectangle,
                verticalSpace: null,
                __typename: 'ComponentCircuitHubSectionStyle',
            },
        },
        {
            __typename: 'ComponentCircuitHubPartnersSection',
            id: '6',
            sectionId: '#partners',
            style: {
                __typename: 'ComponentCircuitHubSectionStyle',
                id: 'styled.1',
                backgroundType: Enum_Componentcircuithubsectionstyle_Backgroundtype.None,
                backgroundColor: null,
                verticalSpace: null,
            },
            action: {
                __typename: 'ComponentButtonsButton',
                id: 'action.1',
                href: 'https://www.grid.gg',
                text: 'BECOME A PARTNER',
                size: Enum_Componentbuttonsbutton_Size.Large,
                variant: Enum_Componentbuttonsbutton_Variant.Primary,
            },
            partners: Array(11)
                .fill(1)
                .map((one, index) => one + index)
                .map((value) => ({
                    __typename: 'Sponsor',
                    id: `${value}`,
                    name: `Partner ${value}`,
                    link: 'https://www.grid.gg',
                    image: {
                        __typename: 'UploadFile',
                        url: `http://via.placeholder.com/128x48/333/d2d2d2?text=Partner+${value}`,
                        alternativeText: `Partner ${value}`,
                        height: 48,
                        width: 128,
                        previewUrl: null,
                        caption: null,
                    },
                })),
        },
    ],
};

export const ContentPageData: CircuitHubDetailsFragment = {
    __typename: 'CircuitHub',
    id: '1',
    navigation: {
        items: [
            {
                id: '1',
                name: 'Home',
                url: '/#home',
                icon: '',
                __typename: 'ComponentGenericNavigationItem',
            },
            {
                id: '8',
                name: 'Partners',
                url: '/#partners',
                icon: '',
                __typename: 'ComponentGenericNavigationItem',
            },
            {
                id: '4',
                name: 'Competitions',
                url: '/#competitions',
                icon: '',
                __typename: 'ComponentGenericNavigationItem',
            },
            {
                id: '9',
                name: 'Structure',
                url: '/#structure',
                icon: '',
                __typename: 'ComponentGenericNavigationItem',
            },
            {
                id: '5',
                name: 'Tournaments',
                url: '#tournaments',
                icon: '',
                __typename: 'ComponentGenericNavigationItem',
            },
            {
                id: '7',
                name: 'Roadmap',
                url: '/#roadmap',
                icon: '',
                __typename: 'ComponentGenericNavigationItem',
            },
            {
                id: '10',
                name: 'F.A.Q.',
                url: '/#faq',
                icon: '',
                __typename: 'ComponentGenericNavigationItem',
            },
        ],
        __typename: 'ComponentGenericNavigation',
    },
    tourLogo: {
        id: '31',
        alt_text: 'Champions of Champions',
        image: {
            id: '153',
            alternativeText: '',
            caption: '',
            width: 120,
            height: 120,
            url: 'https://cdn-stg.grid.gg/strapi/Group_1951_32af569526.png',
            previewUrl: null,
            __typename: 'UploadFile',
        },
        __typename: 'ComponentImagesImage',
    },
    site: {
        id: '5',
        domain: 'watch-circuit-stg.grid.gg,localhost',
        site_title: 'GRID Test Watch Page',
        name: 'GRID Esports THUB Site',
        brand: {
            id: '7',
            name: 'CCT',
            primary_colors: ['#FFE55D', '#FFE042', '#FFD90F'],
            secondary_colors: ['#dbb251'],
            light_colors: ['#f2f2f3', '#e5e5e8', '#d9d9dc', '#ccccd0'],
            dark_colors: ['#262626', '#27272b', '#1D1D1D', '#0a0a0b'],
            slate_colors: ['#92929c', '#858590', '#797985', '#6d6d78'],
            theme_overrides: {
                colourScheme: 'dark',
                fonts: ["'Fjalla One', sans-serif", "'Lato', sans-serif"],
                fontImports: [
                    "@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&family=Lato:wght@300&display=swap');",
                ],
                colors: {
                    base: {
                        '0': '#FFFFFF',
                        '5': '#f2f2f2',
                        '10': '#E6E6E6',
                        '15': '#D9D9D9',
                        '20': '#CCCCCC',
                        '25': '#BFBFBF',
                        '30': '#B2B2B2',
                        '35': '#A6A6A6',
                        '40': '#999999',
                        '45': '#8C8C8C',
                        '50': '#808080',
                        '55': '#737373',
                        '60': '#666666',
                        '65': '#595959',
                        '70': '#4D4D4D',
                        '75': '#404040',
                        '80': '#333333',
                        '85': '#262626',
                        '90': '#1A1A1A',
                        '95': '#0D0D0D',
                        '100': '#000000',
                    },
                },
                heading: {
                    size: {
                        h1: { fontSize: '48px', fontFamily: 0, lineHeight: '64px' },
                        h2: { fontSize: '40px', fontFamily: 0, lineHeight: '48px' },
                        h3: { fontSize: '32px', fontFamily: 0, lineHeight: '40px' },
                        h4: { fontSize: '24px', fontFamily: 0, lineHeight: '32px' },
                        h5: { fontSize: '14px', fontFamily: 1, lineHeight: '20px' },
                        h6: { fontSize: '10px', fontFamily: 1, lineHeight: '12px' },
                    },
                },
                text: {
                    size: {
                        large: {
                            fontSize: '20px',
                            lineHeight: '24px',
                            fontFamily: 1,
                            fontWeight: 300,
                        },
                        medium: {
                            fontSize: '16px',
                            lineHeight: '24px',
                            fontFamily: 1,
                            fontWeight: 300,
                        },
                        small: {
                            fontSize: '14px',
                            lineHeight: '20px',
                            fontFamily: 1,
                            fontWeight: 300,
                        },
                        xSmall: {
                            fontSize: '12px',
                            lineHeight: '16px',
                            fontFamily: 1,
                            fontWeight: 300,
                        },
                    },
                },
            },
            __typename: 'Brand',
        },
        socials: {
            id: '9',
            facebook: 'https://www.facebook.com',
            instagram: 'https://www.instagram.com',
            linkedin: 'https://www.linkedin.com',
            twitch: 'https://www.twich.com',
            twitter: 'https://www.twitter.com',
            youtube: 'https://www.youtube.com',
            __typename: 'ComponentSocialSocials',
        },
        sponsors: [
            {
                id: '10',
                link: 'http://webuy.com/',
                name: 'CeX',
                image: {
                    alternativeText: null,
                    caption: null,
                    width: null,
                    height: null,
                    url: 'https://cdn-stg.grid.gg/strapi/cf8625fe30e84a07be20c33ae85f3731.png',
                    previewUrl: null,
                    __typename: 'UploadFile',
                },
                __typename: 'Sponsor',
            },
            {
                id: '12',
                link: 'https://rog.asus.com/',
                name: 'RoG',
                image: {
                    alternativeText: null,
                    caption: null,
                    width: null,
                    height: null,
                    url: 'https://cdn-stg.grid.gg/strapi/09b9ef5114294c52a09f2b5ec81d80c6.png',
                    previewUrl: null,
                    __typename: 'UploadFile',
                },
                __typename: 'Sponsor',
            },
            {
                id: '14',
                link: null,
                name: 'MAD Lions Test',
                image: {
                    alternativeText: null,
                    caption: null,
                    width: null,
                    height: null,
                    url: 'https://cdn-stg.grid.gg/strapi/7bb4344a58f54643a500d86ef437c683.png',
                    previewUrl: null,
                    __typename: 'UploadFile',
                },
                __typename: 'Sponsor',
            },
            {
                id: '24',
                link: null,
                name: 'FoundingMember',
                image: {
                    alternativeText: null,
                    caption: null,
                    width: null,
                    height: null,
                    url: 'https://cdn-stg.grid.gg/strapi/018ee321e88f40cdbff5776da2152d74.png',
                    previewUrl: null,
                    __typename: 'UploadFile',
                },
                __typename: 'Sponsor',
            },
        ],
        __typename: 'Site',
    },
    footer: {
        linksColumnLeft: {
            items: [
                {
                    name: 'Privacy Policy',
                    url: 'http://example.com',
                    __typename: 'ComponentGenericNavigationItem',
                },
                {
                    name: 'Terms & Conditions',
                    url: 'http://example.com',
                    __typename: 'ComponentGenericNavigationItem',
                },
                {
                    name: 'Cookie Policy',
                    url: 'http://example.com',
                    __typename: 'ComponentGenericNavigationItem',
                },
                {
                    name: 'GRID',
                    url: 'https://portal.stg.grid.gg/',
                    __typename: 'ComponentGenericNavigationItem',
                },
            ],
            title: 'Links',
            __typename: 'ComponentGenericNavigation',
        },
        linksColumnRight: {
            items: [
                {
                    name: 'support@grid.gg',
                    url: 'mailto:support@grid.gg',
                    __typename: 'ComponentGenericNavigationItem',
                },
                {
                    name: 'help@grid.gg',
                    url: 'mailto:support@grid.gg',
                    __typename: 'ComponentGenericNavigationItem',
                },
                {
                    name: 'partners@cct.gg',
                    url: 'mailto:support@grid.gg',
                    __typename: 'ComponentGenericNavigationItem',
                },
                {
                    name: 'sponsors@cct.gg',
                    url: 'mailto:support@grid.gg',
                    __typename: 'ComponentGenericNavigationItem',
                },
            ],
            title: 'Contacts',
            __typename: 'ComponentGenericNavigation',
        },
        __typename: 'ComponentCircuitHubFooter',
    },
    content_pages: [
        {
            __typename: 'ContentPage',
            meta: { __typename: 'ComponentPageComponentsMeta', slug: 'faq', title: 'FAQ' },
            content: [
                {
                    __typename: 'ComponentCircuitHubBasicSection',
                    id: '22',
                    sectionId: null,
                    text: {
                        id: '15',
                        title: 'Some Title',
                        subtitle: 'Something',
                        description: '//Content',
                        __typename: 'ComponentCircuitHubSectionText',
                    },
                    image: null,
                    style: null,
                    action: null,
                },
                {
                    __typename: 'ComponentCircuitHubPointsSection',
                    id: '3',
                    sectionId: null,
                    points: [
                        {
                            id: '7',
                            textTop: 'Spanning 7 Tranches',
                            textBottom: 'Across the Globe',
                            icon: null,
                            __typename: 'ComponentCircuitHubSectionPoint',
                        },
                        {
                            id: '8',
                            textTop: 'Over 3000 Bo3',
                            textBottom: 'Match Ups',
                            icon: null,
                            __typename: 'ComponentCircuitHubSectionPoint',
                        },
                        {
                            id: '11',
                            textTop: 'Over 10000 Hours',
                            textBottom: 'Of Broadcast',
                            icon: null,
                            __typename: 'ComponentCircuitHubSectionPoint',
                        },
                        {
                            id: '12',
                            textTop: 'Consolidating 10-11 Leading',
                            textBottom: 'Tournament Organisers',
                            icon: null,
                            __typename: 'ComponentCircuitHubSectionPoint',
                        },
                    ],
                    style: {
                        id: '31',
                        backgroundColor: null,
                        backgroundType: null,
                        verticalSpace: Enum_Componentcircuithubsectionstyle_Verticalspace.None,
                        __typename: 'ComponentCircuitHubSectionStyle',
                    },
                },
                {
                    __typename: 'ComponentCircuitHubTabsSection',
                    id: '1',
                    sectionId: 'cctour',
                    title: 'THE CCT 22-24 TOUR',
                    tabs: [
                        {
                            id: '1',
                            title: 'Prize Pool',
                            content: [
                                {
                                    __typename: 'ComponentCircuitHubBasicSection',
                                    id: '7',
                                    sectionId: 'intro',
                                    text: {
                                        id: '7',
                                        title: null,
                                        subtitle: null,
                                        description:
                                            'The combined total Prize Pool of the Champion of Champions Tour shall be **$3,400,000**. A total of **$1,800,000** shall be distributed across 44 Online Cups, along with **$1,100,000** across 11 Studio Finals, culminating in a **$500,000** Arena Final. The Prize Pool is distributed through **7** unique tranches and shall help bring sustainability and integrity to competitions throughout CSGO.',
                                        __typename: 'ComponentCircuitHubSectionText',
                                    },
                                    image: {
                                        id: '10',
                                        content: {
                                            id: '442',
                                            alt_text: null,
                                            image: {
                                                id: '4029',
                                                alternativeText: '',
                                                caption: '',
                                                width: 1983,
                                                height: 684,
                                                url:
                                                    'https://cdn.grid.gg/strapi/Group_11822_f82d6184a8.png',
                                                previewUrl: null,
                                                __typename: 'UploadFile',
                                            },
                                            __typename: 'ComponentImagesImage',
                                        },
                                        position:
                                            Enum_Componentcircuithubsectionimage_Position.Right,
                                        __typename: 'ComponentCircuitHubSectionImage',
                                    },
                                    style: null,
                                    action: null,
                                },
                                {
                                    __typename: 'ComponentCircuitHubBasicSection',
                                    id: '8',
                                    sectionId: 'image',
                                    text: {
                                        id: '8',
                                        title: null,
                                        subtitle: null,
                                        description: null,
                                        __typename: 'ComponentCircuitHubSectionText',
                                    },
                                    image: {
                                        id: '11',
                                        content: {
                                            id: '443',
                                            alt_text: null,
                                            image: {
                                                id: '4030',
                                                alternativeText: '',
                                                caption: '',
                                                width: 2010,
                                                height: 999,
                                                url:
                                                    'https://cdn.grid.gg/strapi/Group_11623_b6c7240e97.png',
                                                previewUrl: null,
                                                __typename: 'UploadFile',
                                            },
                                            __typename: 'ComponentImagesImage',
                                        },
                                        position:
                                            Enum_Componentcircuithubsectionimage_Position.Stretch,
                                        __typename: 'ComponentCircuitHubSectionImage',
                                    },
                                    style: null,
                                    action: null,
                                },
                            ],
                            __typename: 'ComponentCircuitHubSectionTab',
                        },
                        {
                            id: '6',
                            title: 'Open Tour Points',
                            content: [
                                {
                                    __typename: 'ComponentCircuitHubBasicSection',
                                    id: '37',
                                    sectionId: 'points',
                                    text: {
                                        id: '32',
                                        title: null,
                                        subtitle: null,
                                        description:
                                            'The Point System will follow, quantify, and reward the performance of CCT participants. Teams shall get points based on their placement in the following types of individual tournaments - Online Cups and Studio Finals. There will be Regional and Global rankings that will respectively provide slots for the Studio LAN Events and the Global Finals Event.\n',
                                        __typename: 'ComponentCircuitHubSectionText',
                                    },
                                    image: null,
                                    style: null,
                                    action: null,
                                },
                                {
                                    __typename: 'ComponentCircuitHubBasicSection',
                                    id: '38',
                                    sectionId: 'image',
                                    text: null,
                                    image: {
                                        id: '37',
                                        content: {
                                            id: '469',
                                            alt_text: null,
                                            image: {
                                                id: '4073',
                                                alternativeText: '',
                                                caption: '',
                                                width: 7680,
                                                height: 4320,
                                                url:
                                                    'https://cdn.grid.gg/strapi/Open_tour_points_min_ceb7952d8e.png',
                                                previewUrl: null,
                                                __typename: 'UploadFile',
                                            },
                                            __typename: 'ComponentImagesImage',
                                        },
                                        position:
                                            Enum_Componentcircuithubsectionimage_Position.Stretch,
                                        __typename: 'ComponentCircuitHubSectionImage',
                                    },
                                    style: null,
                                    action: null,
                                },
                            ],
                            __typename: 'ComponentCircuitHubSectionTab',
                        },
                        {
                            id: '7',
                            title: 'Regional Seedings',
                            content: [
                                {
                                    __typename: 'ComponentCircuitHubBasicSection',
                                    id: '39',
                                    sectionId: 'regionalseedings',
                                    text: {
                                        id: '33',
                                        title: null,
                                        subtitle: null,
                                        description:
                                            'Teams get seeded in the various tournament stages of the Champion of Champions tour by qualifying from a previous stage or getting a direct invite. Direct invite seeds can be activated by regional partners through tournaments/academy leagues outside CCT or awarded to specific teams based on their overall performance and prestige. \nFurthermore, the Blast Rising winners will participate in a special event for a chance to earn a spot in the Global Finals while the CCT crowned champion shall qualify for Blast Premier.',
                                        __typename: 'ComponentCircuitHubSectionText',
                                    },
                                    image: null,
                                    style: null,
                                    action: null,
                                },
                                {
                                    __typename: 'ComponentCircuitHubBasicSection',
                                    id: '40',
                                    sectionId: 'image',
                                    text: null,
                                    image: {
                                        id: '38',
                                        content: {
                                            id: '470',
                                            alt_text: null,
                                            image: {
                                                id: '4072',
                                                alternativeText: '',
                                                caption: '',
                                                width: 5760,
                                                height: 2622,
                                                url:
                                                    'https://cdn.grid.gg/strapi/Regional_Seedings_min_e1b14601af.png',
                                                previewUrl: null,
                                                __typename: 'UploadFile',
                                            },
                                            __typename: 'ComponentImagesImage',
                                        },
                                        position:
                                            Enum_Componentcircuithubsectionimage_Position.Stretch,
                                        __typename: 'ComponentCircuitHubSectionImage',
                                    },
                                    style: null,
                                    action: null,
                                },
                            ],
                            __typename: 'ComponentCircuitHubSectionTab',
                        },
                        {
                            id: '8',
                            title: 'Format',
                            content: [
                                {
                                    __typename: 'ComponentCircuitHubBasicSection',
                                    id: '41',
                                    sectionId: 'format',
                                    text: {
                                        id: '34',
                                        title: null,
                                        subtitle: null,
                                        description:
                                            'Across each section of The Tour (Online/Studio/Arena) the format and match faciliation shall be consistent and uniform. The Online Cups shall consist of a Regional Open Qualification that shall submit 4 Regional Qualifiers to a Play In. From the Play In teams shall graduate to the Main Online stage of the Tour. Winners of the Main Online Stage shall be rewarded with spots in the CCT Studio Finals. \n',
                                        __typename: 'ComponentCircuitHubSectionText',
                                    },
                                    image: null,
                                    style: null,
                                    action: null,
                                },
                                {
                                    __typename: 'ComponentCircuitHubBasicSection',
                                    id: '42',
                                    sectionId: 'image',
                                    text: {
                                        id: '35',
                                        title: null,
                                        subtitle: null,
                                        description: null,
                                        __typename: 'ComponentCircuitHubSectionText',
                                    },
                                    image: {
                                        id: '40',
                                        content: {
                                            id: '472',
                                            alt_text: null,
                                            image: {
                                                id: '3783',
                                                alternativeText: '',
                                                caption: '',
                                                width: 2292,
                                                height: 1382,
                                                url:
                                                    'https://cdn.grid.gg/strapi/format_asset_517843b4a2.png',
                                                previewUrl: null,
                                                __typename: 'UploadFile',
                                            },
                                            __typename: 'ComponentImagesImage',
                                        },
                                        position:
                                            Enum_Componentcircuithubsectionimage_Position.Stretch,
                                        __typename: 'ComponentCircuitHubSectionImage',
                                    },
                                    style: null,
                                    action: null,
                                },
                            ],
                            __typename: 'ComponentCircuitHubSectionTab',
                        },
                        {
                            id: '9',
                            title: 'Tournaments',
                            content: [
                                {
                                    __typename: 'ComponentCircuitHubBasicSection',
                                    id: '43',
                                    sectionId: 'tournaments',
                                    text: {
                                        id: '36',
                                        title: null,
                                        subtitle: null,
                                        description:
                                            'In total CCT 22-24 shall make up 55 competitions within 7 regions that span the globe. The tournaments shall be played across several time zones which should facilitate around the clock CSGO. Each regional tranche shall incorporate 4 Online Cups. Online Cup winners shall graduate to the Studio Finals, where winners shall then have the opportunity to complete the first CCT Arena Final. ',
                                        __typename: 'ComponentCircuitHubSectionText',
                                    },
                                    image: null,
                                    style: null,
                                    action: null,
                                },
                                {
                                    __typename: 'ComponentCircuitHubBasicSection',
                                    id: '44',
                                    sectionId: null,
                                    text: null,
                                    image: {
                                        id: '39',
                                        content: {
                                            id: '471',
                                            alt_text: null,
                                            image: {
                                                id: '4009',
                                                alternativeText: '',
                                                caption: '',
                                                width: 4952,
                                                height: 2508,
                                                url:
                                                    'https://cdn.grid.gg/strapi/Frame_17908_508362da0b.png',
                                                previewUrl: null,
                                                __typename: 'UploadFile',
                                            },
                                            __typename: 'ComponentImagesImage',
                                        },
                                        position:
                                            Enum_Componentcircuithubsectionimage_Position.Stretch,
                                        __typename: 'ComponentCircuitHubSectionImage',
                                    },
                                    style: null,
                                    action: null,
                                },
                            ],
                            __typename: 'ComponentCircuitHubSectionTab',
                        },
                    ],
                    style: null,
                },
            ],
        },
    ],
};

export default circuitHubFixture;
