import { Children, ReactComponentElement, ReactElement, ReactNode } from 'react';

import getHostname from '@utilities/getHostname';

const domainMatches = (domainRegExp: RegExp) => {
    const hostname = getHostname();
    const windowHostname = window?.gridHostname || '';
    return domainRegExp.test(hostname) || domainRegExp.test(windowHostname);
};

interface DomainRouteProps {
    children: ReactNode;
    // eslint-disable-next-line react/no-unused-prop-types
    domain: RegExp;
}

export const DomainRoute = ({ children }: DomainRouteProps) => <>{children}</>;

interface DomainRouterProps {
    children: ReactNode;
}

export const DomainRouter = ({ children }: DomainRouterProps) => {
    let matchingRoute: ReactElement | null = null;
    const childrenArray = Children.toArray(children);

    childrenArray.forEach((child) => {
        if (matchingRoute || (child as ReactElement).type !== DomainRoute) {
            return;
        }
        const route = child as ReactComponentElement<typeof DomainRoute>;
        const domainRegExp = new RegExp(route.props.domain);
        if (domainMatches(domainRegExp)) {
            matchingRoute = route;
        }
    });

    if (!matchingRoute) {
        throw new TypeError('No route matching domain');
    }
    return matchingRoute;
};
