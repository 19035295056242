const transformBrandToThemeOverrides = (brand) => {
    if (!brand) return {};
    const {
        primary_colors: primary = [],
        secondary_colors: secondary = [],
        slate_colors: slate = [],
        dark_colors: dark = [],
        light_colors: light = [],
        theme_overrides: overrides = {},
    } = brand;

    return {
        ...overrides,
        colors: {
            primary,
            secondary,
            slate,
            dark,
            light,
            base: overrides?.colors?.base,
        },
    };
};

export default transformBrandToThemeOverrides;
