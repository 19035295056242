const mockData = {
    teams: [
        {
            id: '1',
            sideColor: '#fff222',
            teamColor: '#00ff00',
        },
        {
            id: '2',
            sideColor: '#000fff',
            teamColor: '#ff0000',
        },
    ],
};

export default function* generator() {
    yield mockData;
}
