import { rest } from 'msw';

const getPages = rest.get('http://localhost:1337/pages', (req, res, ctx) => res(ctx.json([])));

const getSites = rest.get('http://localhost:1337/sites', (req, res, ctx) => res(ctx.json([])));

const getCircuitPages = rest.get('http://localhost:1337/circuit-hubs/pages', (req, res, ctx) =>
    res(ctx.json([])),
);

export default [getPages, getSites, getCircuitPages];
