import { graphql } from 'msw';

export const strapi = graphql.link('http://localhost:3002/content/graphql');

export const siteBrandingData = {
    siteByDomain: {
        brand: {
            id: '53',
            primary_colors: ['#248740', '#278F45', '#2CA24D'],
            secondary_colors: ['#C6C6C8', '#D1D1D2', '#D9D9DA'],
            slate_colors: ['#777777', '#828282', '#8C8C8C', '#979797'],
            dark_colors: ['#181818', '#242424', '#2F2F2F', '#3B3B3B'],
            light_colors: ['#efefef', '#F5F5F5', '#FAFAFA', '#FFFFFF'],
            theme_overrides: {
                colourScheme: 'dark',
                fonts: ["'Poppins', sans-serif"],
                button: {
                    square: {
                        true: {},
                    },
                },
            },
            fontImportUrl:
                "@import url('https://fonts.googleapis.com/css?family=Poppins&display=swap');",
            primary_logo: {
                url: 'https://cdn.grid.gg/strapi/alliance_primary_85efbb01ba.png',
                __typename: 'UploadFile',
            },
            secondary_logo: {
                url: 'https://cdn.grid.gg/strapi/alliance_secondary_88fa6f5df8.png',
                __typename: 'UploadFile',
            },
            __typename: 'Brand',
        },
        __typename: 'Site',
    },
};

export default strapi.query('GetSiteBranding', (req, res, ctx) => res(ctx.data(siteBrandingData)));
