import { FC } from 'react';
import styled from '@emotion/styled';
import Box from '@components/Layout/Grid/Box';
import {
    border,
    typography,
    variant,
    layout,
    space,
    BorderProps,
    SpaceProps,
    TypographyProps,
    LayoutProps,
    BoxShadowProps,
} from '@components/styled/system';
import { color as themeColor, themeColorFromProp } from '@components/styled/tools';

const IconContainer = styled(Box)`
    transform: translateY(-50%);
`;

export interface InputProps
    extends Omit<
            React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
            'width' | 'height' | 'color' | 'size' | 'onChange'
        >,
        BorderProps,
        SpaceProps,
        TypographyProps,
        LayoutProps,
        BoxShadowProps {
    onChange?: (e: { target: { value: string; disabled?: boolean } }) => void;
    size?: 'small' | 'medium' | 'large';
    disabled?: boolean;
    placeholder?: string;
    activeBorderColor?: string;
    activeBorderColorTone?: number;
    name?: string;
    ellipsisOnOverflow?: boolean;
    textColor?: string;
}

const sizeStyle = variant({
    scale: 'input.size',
    prop: 'size',
});

const disabledStyle = variant({
    scale: 'disabled',
    prop: 'disabled',
});

export const StyledInput: FC<InputProps> = styled.input<InputProps>`
    ${border}
    ${typography}
    ${layout}
    ${sizeStyle}
    ${disabledStyle}
    outline: none;
    box-sizing: border-box;
    border-radius: 0;
    border-style: none;
    border-bottom-style: solid;
    color: ${themeColorFromProp('textColor')};
    background-color: transparent;
    ${variant({
        prop: 'ellipsisOnOverflow',
        variants: {
            true: {
                overflowX: 'hidden',
                textOverflow: 'ellipsis',
                display: 'inline-block',
                whiteSpace: 'nowrap',
            },
        },
    })}
    :-webkit-autofill {
        transition: background-color 3600s ease-in-out 0s, color 3600s ease-in-out 0s;
    }

    ${({ theme, activeBorderColor = 'primary', activeBorderColorTone = 0 }) => `
    :active, :focus {
      border-color: ${[...theme.colors[activeBorderColor]][activeBorderColorTone]};
      box-shadow: 0 1px 0 ${[...theme.colors[activeBorderColor]][activeBorderColorTone]};
    }
  `}

    ${({ theme, activeBorderColor = 'primary', activeBorderColorTone = 0, name }) => `
    &:focus ~ label[for="${name}"], &:active  ~ label[for="${name}"]  {
      color: ${[...theme.colors[activeBorderColor]][activeBorderColorTone]};
    }
  `}

  ::placeholder {
        color: ${themeColor('base.40')};
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &[type='number'] {
        -moz-appearance: textfield;
    }

    ${space}
`;

StyledInput.defaultProps = {
    borderColor: 'base.60',
    borderStyle: 'solid',
    borderWidth: '1px',
    py: 2,
    pr: 0,
    pl: 0,
    fontSize: '14px',
    borderRadius: 1,
    width: '100%',
    size: 'medium',
    textColor: 'white',
};

export default IconContainer;
