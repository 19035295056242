import { rest } from 'msw';
import mockedUsersData from '../../../../features/dashboard/pages/my-organisation/pages/user-management-console/fixtures/users/data.json';
import mockedUserRoleGroups from '../../../../features/dashboard/pages/my-organisation/pages/user-management-console/components/EditRoleGroups/fixtures/roleGroups.json';
import whitelistedIPs from '../../../../features/dashboard/pages/my-organisation/pages/user-management-console/fixtures/users/whitelistedIPs.json';
import apiKeysMock from '../../../../features/dashboard/pages/my-organisation/pages/user-management-console/fixtures/api-keys/data.json';

const paginatedData = {
    users: {
        pageInfo: {
            startCursor: 'id-1-i-0',
            endCursor: 'id-11-i-10', // testing pruposes cursors
            hasNextPage: true,
            hasPreviousPage: false,
        },
        totalCount: 2466,
        edges: mockedUsersData.map((item, index) => ({
            cursor: `id-${item.id}-i-${index}`,
            node: item,
        })),
        filterOptions: {
            userTypes: [
                {
                    name: 'User',
                    id: 'user',
                },
                {
                    name: 'Service',
                    id: 'service',
                },
            ],
        },
    },
};

const getUsers = rest.get('/api/users', (req, res, ctx) => res(ctx.json(paginatedData)));

const getUsersApi = rest.get('/users', (req, res, ctx) => res(ctx.json(paginatedData)));

const getUser = rest.get('/api/users/:userId', (req, res, ctx) => {
    const { userId } = req.params;
    const user = mockedUsersData.find(({ id }) => userId === id);
    return res(ctx.json(user));
});

const getWhitelistedIPs = rest.get('/api/users/:userId/ip-ranges', (req, res, ctx) =>
    res(ctx.json(whitelistedIPs)),
);

const patchUser = rest.patch('/api/users/:userId', (req, res, ctx) => {
    const { userId } = req.params;

    if (userId === '2') {
        return res(
            ctx.status(400),
            ctx.json({
                errors: [{ message: 'You are not allowed to perform this action' }],
            }),
        );
    }

    const user = mockedUsersData.find(({ id }) => userId === id);

    const { isEnabled } = JSON.parse(req.body as string);
    return res(ctx.json({ ...user, isEnabled }));
});

const deleteUser = rest.delete('/api/users/:userId', (req, res, ctx) => {
    const { userId } = req.params;
    if (userId === '2') {
        return res(
            ctx.status(400),
            ctx.json({
                errors: [{ message: 'You are not allowed to perform this action' }],
            }),
        );
    }
    return res(ctx.json({ UserId: userId }));
});

const usersCreationHandler = (req, res, ctx) => {
    const { username, email } = req.body;
    let status = 200;
    const errors: any = [];
    if (username === 'duplicate') {
        status = 403;
        errors.push({
            message: 'Duplicate Username',
            path: ['username'],
        });
    }
    if (email === 'duplicate@gmail.com') {
        status = 400;
        errors.push({
            message: 'A User with email [innes381@gmail.com] already exists and is inactive.',
            path: ['email'],
        });
    }
    if (username === 'internal-error') {
        status = 500;
        errors.push({
            message: 'Internal Error',
        });
    }

    if (errors.length) {
        return res(ctx.status(status), ctx.json({ errors }));
    }
    return res(ctx.json(mockedUsersData[0]));
};

const postUser = rest.post('/api/users/:userId', usersCreationHandler);
const postUserV2 = rest.post('/api/users', usersCreationHandler);

const getUserRoleGroups = rest.get('/api/users/:userId/rolegroups', (_, res, ctx) =>
    res(ctx.json(mockedUserRoleGroups)),
);

const patchUserRoleGroups = rest.patch('/api/users/:userId/rolegroups', (req, res, ctx) => {
    const { isEnabled, id: userId } = JSON.parse(req.body as string);
    mockedUserRoleGroups.roleGroups.forEach(({ id }, i) => {
        if (userId === id) {
            mockedUserRoleGroups.roleGroups[i].isEnabled = isEnabled;
        }
    });

    return res(ctx.json(mockedUserRoleGroups));
});

const getUserApiKey = rest.get('/api/users/:userId/api-keys', (req, res, ctx) =>
    res(ctx.json(apiKeysMock)),
);

export default [
    getUsers,
    getUser,
    deleteUser,
    patchUser,
    postUser,
    postUserV2,
    getUserRoleGroups,
    patchUserRoleGroups,
    getUsersApi,
    getWhitelistedIPs,
    getUserApiKey,
];
