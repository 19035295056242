import { useEffect } from 'react';
import globalConfig from '@config';

const Sentry = import('@sentry/browser');

const useSentry = (config?: any) => {
    useEffect(() => {
        Sentry.then((sentry) => {
            sentry.init({
                dsn: globalConfig.sentry.url,
                environment: globalConfig.mode,
                debug: globalConfig.mode === 'development',
            });
            sentry.configureScope((scope) => {
                scope.setTags({
                    page_hostname: window.location.href,
                    page_language: navigator.language,
                    user_device: navigator.userAgent,
                });
                if (config) {
                    scope.setExtras({
                        widgetName: config.widget,
                        widgetOptions: config.widgetOptions,
                        brand: config.brand,
                        seriesID: config.seriesID,
                    });
                }
            });
        }).catch((err) => console.error(err));
    }, []);
};

export default useSentry;
