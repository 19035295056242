import { graphql } from 'msw';

export const strapi = graphql.link('http://localhost:3002/content/graphql');

const data = {
    currentSeries: {
        id: '2543076',
        tournamentId: '106876',
        __typename: 'BaseSeriesInfo',
    },
};

export default strapi.query('GetCurrentSeries', (req, res, ctx) => res(ctx.data(data)));
