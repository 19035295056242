import { AlertType } from '@components/Feedback/Alert/styles';

export interface LoginBody {
    errors?: AuthError[];
    changePasswordId?: string;
    theme?: string;
}

export interface LoginResponse {
    response: Response;
    body: LoginBody;
}

export const authenticationStatus = {
    200: 'LoggedIn',
    203: 'UpdatePassword',
    401: 'Unauthorized',
    403: 'Forbidden',
    404: 'Unauthorized',
    500: 'InternalError',
    0: 'UnknownError',
    1: 'Loading',
} as const;

export type AuthenticationStatus = typeof authenticationStatus[keyof typeof authenticationStatus];

export interface AuthError {
    message: string;
    type?: AlertType;
}

export const UserRoles = {
    groupOwner: 'group_owner',
    organisationOwner: 'organisation_owner',
};
