const mockData = {
    finished: false,
    games: [
        {
            finished: false,
            id: '63a894d0-b93f-4bd9-927b-8ca24f2d9590',
            map: { name: 'Desert_Main' },
            segments: [
                {
                    finished: false,
                    id: 'playtime-1',
                    started: true,
                    teams: [
                        { id: '78', name: 'PUBG-1', won: false },
                        { id: '89', name: 'PUBG-2', won: false },
                    ],
                },
            ],
            started: true,
            teams: [
                { id: '78', name: 'PUBG-1', score: 4, won: false },
                { id: '89', name: 'PUBG-2', score: 5, won: false },
            ],
        },
    ],
    seriesId: '73749',
    started: true,
    teams: [
        { id: '78', name: 'PUBG-1', score: 4, won: false },
        { id: '89', name: 'PUBG-2', score: 5, won: false },
    ],
    valid: true,
};

export default function* generator() {
    yield mockData;
}
