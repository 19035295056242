import { rest } from 'msw';

let getTeamsFirst = false; // To return 2 different teams on 2 subsequent requests
const getTeams = rest.get('/central-data/teams/:id', (req, res, ctx) => {
    getTeamsFirst = !getTeamsFirst;
    if (getTeamsFirst) {
        return res(
            ctx.json({
                id: 78,
                name: 'CSGO-1',
                primaryColor: '#edbb47',
                secondaryColor: '#536acf',
                logoUrl:
                    'https://cdn-stg.grid.gg/assets/team-logos/009c8b6525a73668b3945dc28d4b9c99',
            }),
        );
    }
    return res(
        ctx.json({
            id: 79,
            name: 'CSGO-2',
            primaryColor: '#536acf',
            secondaryColor: '#edbb47',
            logoUrl: 'https://cdn-stg.grid.gg/assets/team-logos/0b77b9cbb9ffacbe8220d26b78f96eba',
        }),
    );
});

export default [getTeams];
