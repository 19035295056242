import dev from './dev.json';
import prd from './prd.json';
import stg from './stg.json';
import test from './test.json';
import defaultConfig from './default.json';

type ConfigName = 'dev' | 'stg' | 'prd' | 'test';

interface Hosts {
    cdn: string;
    gridApi: string;
    gridInternalApi: string;
    gridContent: string;
    sdkApi: string;
    portal: string;
    wsGridApi: string;
    openPlatformApi: string;
}
interface EnvironmentConfig {
    mode: string;
    hosts: Hosts;
    dashboardsWithWidgetV1Api: string[];
    ga?: {
        [key: string]: { v3: { trackingId: string }; v4: { measurementIds: string[] } };
    };
    sentry: { url: string };
    recaptcha?: { siteId: string };
    domainsWithoutStrapiBranding?: string[];
    rudderstack?: { dataPlaneUrl: string; writeKey: string };
}

interface DefaultConfig {
    availableThemes: string[];
    recaptcha: {
        siteId: string;
    };
    sdkDemo: {
        titleId: string;
        tournamentId: string;
        teamIds: string[];
        formatIdByShortName: {
            Bo1: number;
            Bo2: number;
            Bo3: number;
            Bo5: number;
            Bo7: number;
            SA1: number;
            SA2: number;
            SA3: number;
            SA4: number;
            SA5: number;
            SA6: number;
            SA7: number;
            SA8: number;
            SA9: number;
        };
    };
    algolia: {
        appId: string;
        apiKey: string;
        index: string;
    };
}

const configs: {
    [key in ConfigName]: EnvironmentConfig;
} = {
    test,
    dev,
    stg,
    prd,
};

const configName: ConfigName = (process.env.CONFIG_FILE || 'dev') as ConfigName;
const config: DefaultConfig & EnvironmentConfig = {
    ...defaultConfig,
    ...configs[configName],
};
export default config;
