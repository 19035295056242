import { rest } from 'msw';
import regions from '../../../../features/widget-ranking/fixtures/regions.json';
import regionsRating from '../../../../features/widget-ranking/fixtures/ranking.json';

const getRegions = rest.get(
    'http://localhost:3002/widgets/ratings/:ratingId/regions',
    (req, res, ctx) => res(ctx.json(regions)),
);

const getRegionsRatings = rest.get(
    'http://localhost:3002/widgets/ratings/:ratingId/regions/:regionId/ratings',
    (req, res, ctx) => res(ctx.json(regionsRating)),
);

export default [getRegions, getRegionsRatings];
