// @ts-ignore
import type * as Types from '../../generated/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export type GetSiteBrandingQueryVariables = Types.Exact<{
    domain: Types.Scalars['String'];
}>;

export type GetSiteBrandingQuery = {
    __typename?: 'Query';
    siteByDomain?: Types.Maybe<{
        __typename?: 'Site';
        brand?: Types.Maybe<{
            __typename?: 'Brand';
            id: string;
            primary_colors?: Types.Maybe<any>;
            secondary_colors?: Types.Maybe<any>;
            slate_colors?: Types.Maybe<any>;
            dark_colors?: Types.Maybe<any>;
            light_colors?: Types.Maybe<any>;
            theme_overrides?: Types.Maybe<any>;
            fontImportUrl?: Types.Maybe<string>;
            primary_logo?: Types.Maybe<{ __typename?: 'UploadFile'; url: string }>;
            secondary_logo?: Types.Maybe<{ __typename?: 'UploadFile'; url: string }>;
        }>;
    }>;
};

export const GetSiteBrandingDocument = gql`
    query GetSiteBranding($domain: String!) {
        siteByDomain(domain: $domain) {
            brand {
                id
                primary_colors
                secondary_colors
                slate_colors
                dark_colors
                light_colors
                theme_overrides
                fontImportUrl
                primary_logo {
                    url
                }
                secondary_logo {
                    url
                }
            }
        }
    }
`;

/**
 * __useGetSiteBrandingQuery__
 *
 * To run a query within a React component, call `useGetSiteBrandingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteBrandingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteBrandingQuery({
 *   variables: {
 *      domain: // value for 'domain'
 *   },
 * });
 */
export function useGetSiteBrandingQuery(
    baseOptions: Apollo.QueryHookOptions<GetSiteBrandingQuery, GetSiteBrandingQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetSiteBrandingQuery, GetSiteBrandingQueryVariables>(
        GetSiteBrandingDocument,
        options,
    );
}
export function useGetSiteBrandingLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetSiteBrandingQuery, GetSiteBrandingQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetSiteBrandingQuery, GetSiteBrandingQueryVariables>(
        GetSiteBrandingDocument,
        options,
    );
}
export type GetSiteBrandingQueryHookResult = ReturnType<typeof useGetSiteBrandingQuery>;
export type GetSiteBrandingLazyQueryHookResult = ReturnType<typeof useGetSiteBrandingLazyQuery>;
export type GetSiteBrandingQueryResult = Apollo.QueryResult<
    GetSiteBrandingQuery,
    GetSiteBrandingQueryVariables
>;
