import auth from './api/auth';
import dashboard from './api/dashboard';
import featureSwitches from './api/featureSwitches';
import rapidDashboard from './api/rapidDashboard';
import statsExplorerApi from './api/statsExplorerApi';
import widgets from './api/widgets';
import centralData from './central-data';
import external from './external';

import './widgets';

export default [
    ...auth,
    ...dashboard,
    ...featureSwitches,
    ...rapidDashboard,
    ...statsExplorerApi,
    ...widgets,
    ...external,
    ...centralData,
];
