import { createContext, useState } from 'react';

import { AuthError, AuthenticationStatus } from '@features/authentication/types/authentication';

export interface AuthContextValues {
    errors?: AuthError[];
    status?: AuthenticationStatus;
    setAuthState: (updatedStatus: { status: AuthenticationStatus; errors?: AuthError[] }) => void;
}

const AuthContext = createContext<AuthContextValues>({ setAuthState: () => {} });

export const AuthContextProvider = ({ children }) => {
    const [authState, setAuthState] = useState<Omit<AuthContextValues, 'setAuthState'>>({});
    return (
        <AuthContext.Provider value={{ ...authState, setAuthState }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
