import { useSearchParams } from 'react-router-dom';
import removeFalsy from '@utilities/removeFalsy';
import { useState } from 'react';

type SetFunction<T> = (queryParams: T) => void;

export default function useQueryParams<T extends {}>(): [T, SetFunction<T>, string] {
    const [queryParamsString, setQueryParamsString] = useState('');
    const [searchParams, setSearchParams] = useSearchParams();
    const params = removeFalsy(Object.fromEntries(searchParams));

    return [
        params as T,
        (queryParams) => {
            const newQueryParams = removeFalsy({
                ...params,
                ...queryParams,
            });
            const newSearch = JSON.stringify(newQueryParams);
            const prevSearch = JSON.stringify(params);
            if (newSearch === prevSearch) return;
            setSearchParams(newQueryParams);
            const formattedQueryParams = new URLSearchParams(newQueryParams).toString();
            setQueryParamsString(formattedQueryParams);
        },
        queryParamsString,
    ];
}
