import valorantTheme from './valorant.json';
import lolTheme from './lol.json';
import r6Theme from './r6.json';

export type PortalName = 'grid' | 'valorant' | 'lol' | 'r6';

const portals = [
    {
        // Default always first
        name: 'grid',
        title: 'DATA',
        description: 'PORTAL',
        theme: {},
        hostnamePrefix: 'portal',
    },
    {
        name: 'valorant',
        title: 'VALORANT',
        description: 'DATA PORTAL',
        theme: valorantTheme,
        hostnamePrefix: 'valorant',
    },
    {
        name: 'lol',
        title: 'LEAGUE OF LEGENDS',
        description: 'DATA PORTAL',
        theme: lolTheme,
        hostnamePrefix: 'lol',
    },
    {
        name: 'r6',
        title: 'RAINBOW SIX SIEGE',
        description: 'DATA PORTAL',
        theme: r6Theme,
        hostnamePrefix: 'r6',
    },
] as const;

/**
 * get data portal details
 */
function getDataPortal(hostname: string): typeof portals[number] {
    const portal = portals.find(({ hostnamePrefix }) => hostname.includes(hostnamePrefix, 0));

    if (!portal) {
        // Return default
        return portals[0];
    }

    return portal;
}

export default getDataPortal;
