import getTournamentHubByDomain from './getTournamentByDomain';
import getProductUpdatesLatest from './getProductUpdates';
import getWatchPageSiteAndPages from './getWatchPageSiteAndPages';
import getSiteBranding from './getSiteBranding';
import getSiteAnalytics from './getSiteAnalytics';
import getContainPages from './getContainPages';
import getWatchPageData from './getWatchPageData';
import getCurrentSeries from './getCurrentSeries';
import getTournamentHubLogoByDomain from './getTournamentHubLogoByDomain';

export default [
    getTournamentHubByDomain,
    getProductUpdatesLatest,
    getWatchPageSiteAndPages,
    getSiteBranding,
    getSiteAnalytics,
    getContainPages,
    getWatchPageData,
    getCurrentSeries,
    getTournamentHubLogoByDomain,
];
