import dpControls from './dpControls';
import streams from './streams';
import strapi from './strapi';
import docsContent from './docs-content';
import sdkTelemetryApi from './sdkTelemetryApi';
import feedEvents from './feedEvents';

export default [
    ...dpControls,
    ...streams,
    ...strapi,
    ...docsContent,
    ...sdkTelemetryApi,
    ...feedEvents,
];
