import { graphql } from 'msw';

import seriesStateVeto from '@features/dashboard/pages/central-data/pages/series-details/fixtures/seriesState.json';
import seriesOverview from '@features/dashboard/components/SeriesOverview/fixtures/seriesState.json';

import seriesMaps from '@features/competitor/pages/match-history-viewer/fixtures/seriesMaps.json';

import seriesPlayers from '@features/competitor/pages/match-history-viewer/fixtures/seriesPlayers.json';

const LDFSeriesEndpoint = graphql.link('http://localhost:3002/live-data-feed/series-state/graphql');

const handlers = [
    LDFSeriesEndpoint.query('GetSeriesStateVeto', (_, res, ctx) =>
        res(ctx.data(seriesStateVeto.data)),
    ),
    LDFSeriesEndpoint.query('GetSeriesOverview', (_, res, ctx) =>
        res(ctx.data(seriesOverview.data)),
    ),
    LDFSeriesEndpoint.query('GetSeriesMaps', (_, res, ctx) => res(ctx.data(seriesMaps.data))),
    LDFSeriesEndpoint.query('GetSeriesPlayersAndResults', (req, res, ctx) => {
        if (req.body?.variables.id === '283681') return res(ctx.status(400));
        return res(ctx.data(seriesPlayers.data));
    }),
];

export default handlers;
