import ActionsCancel from '../../../assets/svg/actions/cancel.svg';
import ActionsCheckCircle from '../../../assets/svg/actions/check_circle.svg';
import ActionsError from '../../../assets/svg/actions/error.svg';
import ActionsInfo from '../../../assets/svg/actions/info.svg';
import ActionsLogout from '../../../assets/svg/actions/logout.svg';
import Add from '../../../assets/svg/Add.svg';
import AddImage from '../../../assets/svg/add_image.svg';
import AddThin from '../../../assets/svg/add_thin.svg';
import AdminPanelSettings from '../../../assets/svg/admin-panel-settings.svg';
import Aim from '../../../assets/svg/Aim.svg';
import Api from '../../../assets/svg/api.svg';
import ArrowLeft from '../../../assets/svg/arrow-left.svg';
import ArrowRight from '../../../assets/svg/arrow-right.svg';
import ArrowThickRight from '../../../assets/svg/arrow-thick-right.svg';
import ArrowThinRight from '../../../assets/svg/arrow-thin-right.svg';
import ArrowDown from '../../../assets/svg/arrow_down.svg';
import ArrowUp from '../../../assets/svg/arrow_up.svg';
import Autorenew from '../../../assets/svg/autorenew.svg';
import Bars from '../../../assets/svg/bars.svg';
import Block from '../../../assets/svg/block.svg';
import Bullet from '../../../assets/svg/bullet.svg';
import BurgerMenu from '../../../assets/svg/burger-menu.svg';
import Cached from '../../../assets/svg/cached.svg';
import Calendar from '../../../assets/svg/calendar.svg';
import Cancel from '../../../assets/svg/cancel.svg';
import CheckCircle from '../../../assets/svg/check-circle.svg';
import Check from '../../../assets/svg/check.svg';
import ChevronLeftEnd from '../../../assets/svg/chevron-left-end.svg';
import ChevronLeft from '../../../assets/svg/chevron-left.svg';
import ChevronRightEnd from '../../../assets/svg/chevron-right-end.svg';
import ChevronRight from '../../../assets/svg/chevron-right.svg';
import Clear from '../../../assets/svg/clear.svg';
import ClearFilters from '../../../assets/svg/clear_filters.svg';
import Clock from '../../../assets/svg/clock.svg';
import CloudUpload from '../../../assets/svg/cloud_upload.svg';
import Code from '../../../assets/svg/code.svg';
import Colorize from '../../../assets/svg/colorize.svg';
import CommunicationsEmail from '../../../assets/svg/communications/email.svg';
import CommunicationsSend from '../../../assets/svg/communications/send.svg';
import CompareArrow from '../../../assets/svg/compare-arrow.svg';
import Copy from '../../../assets/svg/copy.svg';
import Cross from '../../../assets/svg/cross.svg';
import Crown from '../../../assets/svg/crown.svg';
import Cube from '../../../assets/svg/cube.svg';
import DeleteForever from '../../../assets/svg/delete-forever.svg';
import Delete from '../../../assets/svg/delete.svg';
import Done from '../../../assets/svg/done.svg';
import DoubleColumn from '../../../assets/svg/double-column.svg';
import DownArrow from '../../../assets/svg/down-arrow.svg';
import Download from '../../../assets/svg/download.svg';
import Edit from '../../../assets/svg/edit.svg';
import EllipsisV from '../../../assets/svg/ellipsis-v.svg';
import EmptyFileOutline from '../../../assets/svg/empty-file-outline.svg';
import Explore from '../../../assets/svg/explore.svg';
import FeedViewer from '../../../assets/svg/feed-viewer.svg';
import FilesEmptyFile from '../../../assets/svg/files/empty_file.svg';
import FilesLiveFile from '../../../assets/svg/files/live_file.svg';
import FilesModeledFile from '../../../assets/svg/files/modeled_file.svg';
import FilesTrayAudio from '../../../assets/svg/files/tray_audio.svg';
import FilesTrayDefault from '../../../assets/svg/files/tray_default.svg';
import FilesTrayEvents from '../../../assets/svg/files/tray_events.svg';
import FilesTrayLive from '../../../assets/svg/files/tray_live.svg';
import FilesTrayModeled from '../../../assets/svg/files/tray_modeled.svg';
import FilesTrayReplay from '../../../assets/svg/files/tray_replay.svg';
import FilesTrayRest from '../../../assets/svg/files/tray_rest.svg';
import FilesTrayState from '../../../assets/svg/files/tray_state.svg';
import Filter from '../../../assets/svg/filter.svg';
import FindInPage from '../../../assets/svg/find-in-page.svg';
import GamesAvatar from '../../../assets/svg/games/avatar.svg';
import GamesCrossfire from '../../../assets/svg/games/crossfire.svg';
import GamesCs2 from '../../../assets/svg/games/cs2.svg';
import GamesCsgo from '../../../assets/svg/games/csgo.svg';
import GamesDcl from '../../../assets/svg/games/dcl.svg';
import GamesDota from '../../../assets/svg/games/dota.svg';
import GamesF12020 from '../../../assets/svg/games/f1 2020.svg';
import GamesKog from '../../../assets/svg/games/kog.svg';
import GamesLol from '../../../assets/svg/games/lol.svg';
import GamesLolwr from '../../../assets/svg/games/lolwr.svg';
import GamesPubgM from '../../../assets/svg/games/pubg-m.svg';
import GamesPubg from '../../../assets/svg/games/pubg.svg';
import GamesR6 from '../../../assets/svg/games/r6.svg';
import GamesVal from '../../../assets/svg/games/val.svg';
import GraphsDoubleLine from '../../../assets/svg/graphs/double-line.svg';
import GraphsHexagon from '../../../assets/svg/graphs/hexagon.svg';
import GraphsNetwork from '../../../assets/svg/graphs/network.svg';
import GridIcon from '../../../assets/svg/grid-icon.svg';
import GridLogo from '../../../assets/svg/grid-logo.svg';
import GroupLarger from '../../../assets/svg/group-larger.svg';
import GroupOff from '../../../assets/svg/group-off.svg';
import HamburgerMenu from '../../../assets/svg/hamburger-menu.svg';
import Heart from '../../../assets/svg/heart.svg';
import IVsDivider from '../../../assets/svg/i-vs-divider.svg';
import Info from '../../../assets/svg/info.svg';
import InfoPowerOff from '../../../assets/svg/info_power_off.svg';
import Language from '../../../assets/svg/language.svg';
import Layers from '../../../assets/svg/layers.svg';
import LeftArrow from '../../../assets/svg/left-arrow.svg';
import List from '../../../assets/svg/list.svg';
import LiveDataFeed from '../../../assets/svg/live-data-feed.svg';
import LoadPreset from '../../../assets/svg/load_preset.svg';
import LoginBackground from '../../../assets/svg/login-background.svg';
import LogosGRIDLogoFaviconPrimaryFPDark from '../../../assets/svg/logos/GRID/Logo/Favicon/Primary/FPDark.svg';
import LogosGRIDLogoFaviconPrimaryFPLight from '../../../assets/svg/logos/GRID/Logo/Favicon/Primary/FPLight.svg';
import LogosGRIDLogoFaviconSecondaryFSDark from '../../../assets/svg/logos/GRID/Logo/Favicon/Secondary/FSDark.svg';
import LogosGRIDLogoFaviconSecondaryFSLight from '../../../assets/svg/logos/GRID/Logo/Favicon/Secondary/FSLight.svg';
import LogosGRIDLogoFaviconSecondaryFSSlate from '../../../assets/svg/logos/GRID/Logo/Favicon/Secondary/FSSlate.svg';
import LogosGRIDLogoMainPrimaryLight from '../../../assets/svg/logos/GRID/Logo/Main/Primary/Light.svg';
import LogosGRIDLogoMainPrimaryPDark from '../../../assets/svg/logos/GRID/Logo/Main/Primary/PDark.svg';
import LogosGRIDLogoMainPrimaryPLight from '../../../assets/svg/logos/GRID/Logo/Main/Primary/PLight.svg';
import LogosGRIDLogoMainPrimaryPSlate from '../../../assets/svg/logos/GRID/Logo/Main/Primary/PSlate.svg';
import LogosGRIDLogoMainSecondarySDark from '../../../assets/svg/logos/GRID/Logo/Main/Secondary/SDark.svg';
import LogosGRIDLogoMainSecondarySLight from '../../../assets/svg/logos/GRID/Logo/Main/Secondary/SLight.svg';
import LogosGRIDLogoMainSecondarySSlate from '../../../assets/svg/logos/GRID/Logo/Main/Secondary/SSlate.svg';
import LogosGRIDLogo from '../../../assets/svg/logos/GRID/Logo.svg';
import LogosGRIDLogo2 from '../../../assets/svg/logos/GRID/Logo_2.svg';
import LogosGRIDLogo3 from '../../../assets/svg/logos/GRID/Logo_3.svg';
import LogosWatchHubMono from '../../../assets/svg/logos/watch-hub-mono.svg';
import LogosWatchHub from '../../../assets/svg/logos/watch-hub.svg';
import Managers from '../../../assets/svg/managers.svg';
import MapIcon from '../../../assets/svg/map.svg';
import MapsPin from '../../../assets/svg/maps/pin.svg';
import MediaBroadcast from '../../../assets/svg/media/broadcast.svg';
import MiscCreation from '../../../assets/svg/misc/Creation.svg';
import MiscIntegration from '../../../assets/svg/misc/integration.svg';
import MoreVert from '../../../assets/svg/more_vert.svg';
import Notifications from '../../../assets/svg/notifications.svg';
import ObjectivesAncient from '../../../assets/svg/objectives/ancient.svg';
import ObjectivesBarrack from '../../../assets/svg/objectives/barrack.svg';
import ObjectivesBarracksDenied from '../../../assets/svg/objectives/barracks_denied.svg';
import ObjectivesBombExplode from '../../../assets/svg/objectives/bomb_explode.svg';
import ObjectivesDead from '../../../assets/svg/objectives/dead.svg';
import ObjectivesDefuse from '../../../assets/svg/objectives/defuse.svg';
import ObjectivesInhibitor from '../../../assets/svg/objectives/inhibitor.svg';
import ObjectivesNexus from '../../../assets/svg/objectives/nexus.svg';
import ObjectivesRoshan from '../../../assets/svg/objectives/roshan.svg';
import ObjectivesTime from '../../../assets/svg/objectives/time.svg';
import ObjectivesTower from '../../../assets/svg/objectives/tower.svg';
import ObjectivesTowerDenied from '../../../assets/svg/objectives/tower_denied.svg';
import Pause from '../../../assets/svg/Pause.svg';
import Play from '../../../assets/svg/Play.svg';
import PlayerFullscreenClose from '../../../assets/svg/player/fullscreen-close.svg';
import PlayerFullscreen from '../../../assets/svg/player/fullscreen.svg';
import PlayerMuted from '../../../assets/svg/player/muted.svg';
import PlayerPause from '../../../assets/svg/player/pause.svg';
import PlayerPlay from '../../../assets/svg/player/play.svg';
import PlayerUnmuted from '../../../assets/svg/player/unmuted.svg';
import PlaySmall from '../../../assets/svg/play_small.svg';
import Plus from '../../../assets/svg/plus.svg';
import PrivacyTip from '../../../assets/svg/privacy-tip.svg';
import QuestionMark from '../../../assets/svg/question-mark.svg';
import RapidVideo from '../../../assets/svg/rapid-video.svg';
import RightArrow from '../../../assets/svg/right-arrow.svg';
import RotateLeft from '../../../assets/svg/rotate-left.svg';
import Rules from '../../../assets/svg/rules.svg';
import Search from '../../../assets/svg/search.svg';
import ShieldOutline from '../../../assets/svg/shield-outline.svg';
import SitesGridGgDataRightsPartnersColor4d from '../../../assets/svg/sites/grid-gg/data-rights/partners/color/4d.svg';
import SitesGridGgDataRightsPartnersColorAlliedEsports from '../../../assets/svg/sites/grid-gg/data-rights/partners/color/allied-esports.svg';
import SitesGridGgDataRightsPartnersColorBlast from '../../../assets/svg/sites/grid-gg/data-rights/partners/color/blast.svg';
import SitesGridGgDataRightsPartnersColorDcl from '../../../assets/svg/sites/grid-gg/data-rights/partners/color/dcl.svg';
import SitesGridGgDataRightsPartnersColorMet from '../../../assets/svg/sites/grid-gg/data-rights/partners/color/met.svg';
import SitesGridGgDataRightsPartnersColorPgl from '../../../assets/svg/sites/grid-gg/data-rights/partners/color/pgl.svg';
import SitesGridGgDataRightsPartnersColorPubg from '../../../assets/svg/sites/grid-gg/data-rights/partners/color/pubg.svg';
import SitesGridGgDataRightsPartnersColorRiot from '../../../assets/svg/sites/grid-gg/data-rights/partners/color/riot.svg';
import SitesGridGgDataRightsPartnersColorStarLadder from '../../../assets/svg/sites/grid-gg/data-rights/partners/color/star-ladder.svg';
import SitesGridGgDataRightsPartnersColorValorant from '../../../assets/svg/sites/grid-gg/data-rights/partners/color/valorant.svg';
import SitesGridGgDataRightsPartnersColorWeplay from '../../../assets/svg/sites/grid-gg/data-rights/partners/color/weplay.svg';
import SitesGridGgDataRightsSectionControl from '../../../assets/svg/sites/grid-gg/data-rights/section/control.svg';
import SitesGridGgDataRightsSectionEmpower from '../../../assets/svg/sites/grid-gg/data-rights/section/empower.svg';
import SitesGridGgDataRightsSectionIntergration from '../../../assets/svg/sites/grid-gg/data-rights/section/intergration.svg';
import SitesGridGgDataRightsSectionLight from '../../../assets/svg/sites/grid-gg/data-rights/section/light.svg';
import SitesGridGgDataRightsSectionMonetise from '../../../assets/svg/sites/grid-gg/data-rights/section/monetise.svg';
import SitesGridGgDataRightsSectionSecurity from '../../../assets/svg/sites/grid-gg/data-rights/section/security.svg';
import SitesGridGgHomePartnersArterraLabs from '../../../assets/svg/sites/grid-gg/home/partners/arterra-labs.svg';
import SitesGridGgHomePartnersBet365 from '../../../assets/svg/sites/grid-gg/home/partners/bet365.svg';
import SitesGridGgHomePartnersFaceIt from '../../../assets/svg/sites/grid-gg/home/partners/face-it.svg';
import SitesGridGgHomePartnersGgBet from '../../../assets/svg/sites/grid-gg/home/partners/gg-bet.svg';
import SitesGridGgHomePartnersGosuGamers from '../../../assets/svg/sites/grid-gg/home/partners/gosu-gamers.svg';
import SitesGridGgHomePartnersParimatch from '../../../assets/svg/sites/grid-gg/home/partners/parimatch.svg';
import SitesGridGgHomePartnersPinnacle from '../../../assets/svg/sites/grid-gg/home/partners/pinnacle.svg';
import SitesGridGgHomePartnersThespike from '../../../assets/svg/sites/grid-gg/home/partners/thespike.svg';
import SitesGridGgHomeReviewsKrafton from '../../../assets/svg/sites/grid-gg/home/reviews/krafton.svg';
import SitesGridGgHomeReviewsWeplay from '../../../assets/svg/sites/grid-gg/home/reviews/weplay.svg';
import SitesGridGgHomeServicesGame from '../../../assets/svg/sites/grid-gg/home/services/game.svg';
import SitesGridGgHomeServicesGranularity from '../../../assets/svg/sites/grid-gg/home/services/granularity.svg';
import SitesGridGgHomeServicesTournament from '../../../assets/svg/sites/grid-gg/home/services/tournament.svg';
import SocialFacebook from '../../../assets/svg/social/facebook.svg';
import SocialInstagram from '../../../assets/svg/social/instagram.svg';
import SocialLinkedin from '../../../assets/svg/social/linkedin.svg';
import SocialTwitch from '../../../assets/svg/social/twitch.svg';
import SocialTwitter from '../../../assets/svg/social/twitter.svg';
import SocialYoutube from '../../../assets/svg/social/youtube.svg';
import SyncDisabled from '../../../assets/svg/sync-disabled.svg';
import TeamlogoPlaceholder from '../../../assets/svg/teamlogo-placeholder.svg';
import TeamsChaos from '../../../assets/svg/teams/chaos.svg';
import TeamsGodsentLarge from '../../../assets/svg/teams/godsent-large.svg';
import TeamsGodsentLogo from '../../../assets/svg/teams/godsent-logo.svg';
import Tier2 from '../../../assets/svg/tier2.svg';
import Tier3 from '../../../assets/svg/tier3.svg';
import TipDown from '../../../assets/svg/tip-down.svg';
import TipUp from '../../../assets/svg/tip-up.svg';
import Traders from '../../../assets/svg/traders.svg';
import TrophyOutline from '../../../assets/svg/trophy-outline.svg';
import Trophy from '../../../assets/svg/trophy.svg';
import UpArrow from '../../../assets/svg/up-arrow.svg';
import Upload from '../../../assets/svg/upload.svg';
import UserAdd from '../../../assets/svg/user-add.svg';
import UserRemove from '../../../assets/svg/user-remove.svg';
import User from '../../../assets/svg/user.svg';
import VisibleOff from '../../../assets/svg/visible-off.svg';
import VisibleOn from '../../../assets/svg/visible-on.svg';

const iconComponents = {
    'actions/cancel': ActionsCancel,
    'actions/check_circle': ActionsCheckCircle,
    'actions/error': ActionsError,
    'actions/info': ActionsInfo,
    'actions/logout': ActionsLogout,
    Add,
    add_image: AddImage,
    add_thin: AddThin,
    'admin-panel-settings': AdminPanelSettings,
    Aim,
    api: Api,
    'arrow-left': ArrowLeft,
    'arrow-right': ArrowRight,
    'arrow-thick-right': ArrowThickRight,
    'arrow-thin-right': ArrowThinRight,
    arrow_down: ArrowDown,
    arrow_up: ArrowUp,
    autorenew: Autorenew,
    bars: Bars,
    block: Block,
    bullet: Bullet,
    'burger-menu': BurgerMenu,
    cached: Cached,
    calendar: Calendar,
    cancel: Cancel,
    'check-circle': CheckCircle,
    check: Check,
    'chevron-left-end': ChevronLeftEnd,
    'chevron-left': ChevronLeft,
    'chevron-right-end': ChevronRightEnd,
    'chevron-right': ChevronRight,
    clear: Clear,
    clear_filters: ClearFilters,
    clock: Clock,
    cloud_upload: CloudUpload,
    code: Code,
    colorize: Colorize,
    'communications/email': CommunicationsEmail,
    'communications/send': CommunicationsSend,
    'compare-arrow': CompareArrow,
    copy: Copy,
    cross: Cross,
    crown: Crown,
    cube: Cube,
    'delete-forever': DeleteForever,
    delete: Delete,
    done: Done,
    'double-column': DoubleColumn,
    'down-arrow': DownArrow,
    download: Download,
    edit: Edit,
    'ellipsis-v': EllipsisV,
    'empty-file-outline': EmptyFileOutline,
    explore: Explore,
    'feed-viewer': FeedViewer,
    'files/empty_file': FilesEmptyFile,
    'files/live_file': FilesLiveFile,
    'files/modeled_file': FilesModeledFile,
    'files/tray_audio': FilesTrayAudio,
    'files/tray_default': FilesTrayDefault,
    'files/tray_events': FilesTrayEvents,
    'files/tray_live': FilesTrayLive,
    'files/tray_modeled': FilesTrayModeled,
    'files/tray_replay': FilesTrayReplay,
    'files/tray_rest': FilesTrayRest,
    'files/tray_state': FilesTrayState,
    filter: Filter,
    'find-in-page': FindInPage,
    'games/avatar': GamesAvatar,
    'games/crossfire': GamesCrossfire,
    'games/cs2': GamesCs2,
    'games/csgo': GamesCsgo,
    'games/dcl': GamesDcl,
    'games/dota': GamesDota,
    'games/f1 2020': GamesF12020,
    'games/kog': GamesKog,
    'games/lol': GamesLol,
    'games/lolwr': GamesLolwr,
    'games/pubg-m': GamesPubgM,
    'games/pubg': GamesPubg,
    'games/r6': GamesR6,
    'games/val': GamesVal,
    'graphs/double-line': GraphsDoubleLine,
    'graphs/hexagon': GraphsHexagon,
    'graphs/network': GraphsNetwork,
    'grid-icon': GridIcon,
    'grid-logo': GridLogo,
    'group-larger': GroupLarger,
    'group-off': GroupOff,
    'hamburger-menu': HamburgerMenu,
    heart: Heart,
    'i-vs-divider': IVsDivider,
    info: Info,
    info_power_off: InfoPowerOff,
    language: Language,
    layers: Layers,
    'left-arrow': LeftArrow,
    list: List,
    'live-data-feed': LiveDataFeed,
    load_preset: LoadPreset,
    'login-background': LoginBackground,
    'logos/GRID/Logo/Favicon/Primary/FPDark': LogosGRIDLogoFaviconPrimaryFPDark,
    'logos/GRID/Logo/Favicon/Primary/FPLight': LogosGRIDLogoFaviconPrimaryFPLight,
    'logos/GRID/Logo/Favicon/Secondary/FSDark': LogosGRIDLogoFaviconSecondaryFSDark,
    'logos/GRID/Logo/Favicon/Secondary/FSLight': LogosGRIDLogoFaviconSecondaryFSLight,
    'logos/GRID/Logo/Favicon/Secondary/FSSlate': LogosGRIDLogoFaviconSecondaryFSSlate,
    'logos/GRID/Logo/Main/Primary/Light': LogosGRIDLogoMainPrimaryLight,
    'logos/GRID/Logo/Main/Primary/PDark': LogosGRIDLogoMainPrimaryPDark,
    'logos/GRID/Logo/Main/Primary/PLight': LogosGRIDLogoMainPrimaryPLight,
    'logos/GRID/Logo/Main/Primary/PSlate': LogosGRIDLogoMainPrimaryPSlate,
    'logos/GRID/Logo/Main/Secondary/SDark': LogosGRIDLogoMainSecondarySDark,
    'logos/GRID/Logo/Main/Secondary/SLight': LogosGRIDLogoMainSecondarySLight,
    'logos/GRID/Logo/Main/Secondary/SSlate': LogosGRIDLogoMainSecondarySSlate,
    'logos/GRID/Logo': LogosGRIDLogo,
    'logos/GRID/Logo_2': LogosGRIDLogo2,
    'logos/GRID/Logo_3': LogosGRIDLogo3,
    'logos/watch-hub-mono': LogosWatchHubMono,
    'logos/watch-hub': LogosWatchHub,
    managers: Managers,
    map: MapIcon,
    'maps/pin': MapsPin,
    'media/broadcast': MediaBroadcast,
    'misc/Creation': MiscCreation,
    'misc/integration': MiscIntegration,
    more_vert: MoreVert,
    notifications: Notifications,
    'objectives/ancient': ObjectivesAncient,
    'objectives/barrack': ObjectivesBarrack,
    'objectives/barracks_denied': ObjectivesBarracksDenied,
    'objectives/bomb_explode': ObjectivesBombExplode,
    'objectives/dead': ObjectivesDead,
    'objectives/defuse': ObjectivesDefuse,
    'objectives/inhibitor': ObjectivesInhibitor,
    'objectives/nexus': ObjectivesNexus,
    'objectives/roshan': ObjectivesRoshan,
    'objectives/time': ObjectivesTime,
    'objectives/tower': ObjectivesTower,
    'objectives/tower_denied': ObjectivesTowerDenied,
    Pause,
    Play,
    'player/fullscreen-close': PlayerFullscreenClose,
    'player/fullscreen': PlayerFullscreen,
    'player/muted': PlayerMuted,
    'player/pause': PlayerPause,
    'player/play': PlayerPlay,
    'player/unmuted': PlayerUnmuted,
    play_small: PlaySmall,
    plus: Plus,
    'privacy-tip': PrivacyTip,
    'question-mark': QuestionMark,
    'rapid-video': RapidVideo,
    'right-arrow': RightArrow,
    'rotate-left': RotateLeft,
    rules: Rules,
    search: Search,
    'shield-outline': ShieldOutline,
    'sites/grid-gg/data-rights/partners/color/4d': SitesGridGgDataRightsPartnersColor4d,
    'sites/grid-gg/data-rights/partners/color/allied-esports': SitesGridGgDataRightsPartnersColorAlliedEsports,
    'sites/grid-gg/data-rights/partners/color/blast': SitesGridGgDataRightsPartnersColorBlast,
    'sites/grid-gg/data-rights/partners/color/dcl': SitesGridGgDataRightsPartnersColorDcl,
    'sites/grid-gg/data-rights/partners/color/met': SitesGridGgDataRightsPartnersColorMet,
    'sites/grid-gg/data-rights/partners/color/pgl': SitesGridGgDataRightsPartnersColorPgl,
    'sites/grid-gg/data-rights/partners/color/pubg': SitesGridGgDataRightsPartnersColorPubg,
    'sites/grid-gg/data-rights/partners/color/riot': SitesGridGgDataRightsPartnersColorRiot,
    'sites/grid-gg/data-rights/partners/color/star-ladder': SitesGridGgDataRightsPartnersColorStarLadder,
    'sites/grid-gg/data-rights/partners/color/valorant': SitesGridGgDataRightsPartnersColorValorant,
    'sites/grid-gg/data-rights/partners/color/weplay': SitesGridGgDataRightsPartnersColorWeplay,
    'sites/grid-gg/data-rights/section/control': SitesGridGgDataRightsSectionControl,
    'sites/grid-gg/data-rights/section/empower': SitesGridGgDataRightsSectionEmpower,
    'sites/grid-gg/data-rights/section/intergration': SitesGridGgDataRightsSectionIntergration,
    'sites/grid-gg/data-rights/section/light': SitesGridGgDataRightsSectionLight,
    'sites/grid-gg/data-rights/section/monetise': SitesGridGgDataRightsSectionMonetise,
    'sites/grid-gg/data-rights/section/security': SitesGridGgDataRightsSectionSecurity,
    'sites/grid-gg/home/partners/arterra-labs': SitesGridGgHomePartnersArterraLabs,
    'sites/grid-gg/home/partners/bet365': SitesGridGgHomePartnersBet365,
    'sites/grid-gg/home/partners/face-it': SitesGridGgHomePartnersFaceIt,
    'sites/grid-gg/home/partners/gg-bet': SitesGridGgHomePartnersGgBet,
    'sites/grid-gg/home/partners/gosu-gamers': SitesGridGgHomePartnersGosuGamers,
    'sites/grid-gg/home/partners/parimatch': SitesGridGgHomePartnersParimatch,
    'sites/grid-gg/home/partners/pinnacle': SitesGridGgHomePartnersPinnacle,
    'sites/grid-gg/home/partners/thespike': SitesGridGgHomePartnersThespike,
    'sites/grid-gg/home/reviews/krafton': SitesGridGgHomeReviewsKrafton,
    'sites/grid-gg/home/reviews/weplay': SitesGridGgHomeReviewsWeplay,
    'sites/grid-gg/home/services/game': SitesGridGgHomeServicesGame,
    'sites/grid-gg/home/services/granularity': SitesGridGgHomeServicesGranularity,
    'sites/grid-gg/home/services/tournament': SitesGridGgHomeServicesTournament,
    'social/facebook': SocialFacebook,
    'social/instagram': SocialInstagram,
    'social/linkedin': SocialLinkedin,
    'social/twitch': SocialTwitch,
    'social/twitter': SocialTwitter,
    'social/youtube': SocialYoutube,
    'sync-disabled': SyncDisabled,
    'teamlogo-placeholder': TeamlogoPlaceholder,
    'teams/chaos': TeamsChaos,
    'teams/godsent-large': TeamsGodsentLarge,
    'teams/godsent-logo': TeamsGodsentLogo,
    tier2: Tier2,
    tier3: Tier3,
    'tip-down': TipDown,
    'tip-up': TipUp,
    traders: Traders,
    'trophy-outline': TrophyOutline,
    trophy: Trophy,
    'up-arrow': UpArrow,
    upload: Upload,
    'user-add': UserAdd,
    'user-remove': UserRemove,
    user: User,
    'visible-off': VisibleOff,
    'visible-on': VisibleOn,
};

export default iconComponents;
