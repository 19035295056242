import { keyframes } from '@emotion/react';

import styled from '@emotion/styled';
import { ifProp, prop } from 'styled-tools';

const hide = keyframes`
  0% {
    opacity: 1;
    visibility: visible;
  }
  99% {visibility: visible;}
  100% {
      opacity: 0;
      visibility:hidden;
  }
`;

const LoadingScreen = styled.div<{ isHidden: boolean }>`
    position: fixed;
    height: 100vh;
    width: 100vw;
    background: ${prop('base.90')};
    z-index: 1000;

    animation: ${hide} 0.5s normal forwards ease-in-out;
    animation-play-state: paused;

    ${ifProp('isHidden', 'animation-play-state: running;')};

    svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
`;

export default LoadingScreen;
