import { createContext, ReactNode, useMemo } from 'react';
import { ParsedQuery } from 'query-string';
import { FlagProvider, useFlags } from '@unleash/proxy-client-react';

import useQueryParams from '@hooks/useQueryParams';

import unleashConfig from '../config/unleash';
import useInitializeUnleashContext from '../hooks/useInitializeUnleashContext';
import { FeatureName, FeatureSwitches } from '../types/featureSwitches';

const FEATURE_NAME_STORAGE_PREFIX = 'feature_';
const WINDOW_STORAGE = 'sessionStorage';

const FeatureSwitchesContext = createContext<FeatureSwitches>({});
export default FeatureSwitchesContext;

const featureStorageKeyToName = (featureStorageKey: string) =>
    featureStorageKey.replace(FEATURE_NAME_STORAGE_PREFIX, '') as FeatureName;

const addFeatureSwitchToStorage = (featureName: FeatureName) => {
    const storage = window[WINDOW_STORAGE];

    storage.setItem(`${FEATURE_NAME_STORAGE_PREFIX}${featureName}`, 'true');
};

const useFeatureSwitchesParams = (): FeatureSwitches => {
    const [queryParams] = useQueryParams<ParsedQuery<string>>();
    return Object.keys(queryParams)
        .filter((queryParam) => queryParam.startsWith(FEATURE_NAME_STORAGE_PREFIX))
        .reduce((acc, featureKey) => {
            const value = queryParams[featureKey];
            const featureName = featureStorageKeyToName(featureKey);
            if (!value) {
                acc[featureName] = false;
            } else if (Array.isArray(value)) {
                acc[featureName] = true;
            } else {
                acc[featureName] = JSON.parse(value);
            }
            return acc;
        }, {});
};

const useFeatureSwitchesUnleash = (): FeatureSwitches => {
    const flagsArray = useFlags();
    return flagsArray.reduce((acc, flag) => {
        acc[flag.name] = flag.enabled;
        return acc;
    }, {});
};

const readFeatureSwitchesFromStorage = (): FeatureSwitches => {
    const storage = window[WINDOW_STORAGE];
    return Object.entries(storage)
        .filter(([key]) => key.startsWith(FEATURE_NAME_STORAGE_PREFIX))
        .reduce(
            (acc, [featureStorageKey, enabled]) => ({
                ...acc,
                [featureStorageKeyToName(featureStorageKey)]: JSON.parse(enabled),
            }),
            {},
        );
};

interface FeatureSwitchesProviderInnerProps {
    children: ReactNode;
}

function FeatureSwitchesProviderInner({ children }: FeatureSwitchesProviderInnerProps) {
    useInitializeUnleashContext();

    const featureSwitchesParams = useFeatureSwitchesParams();
    const featureSwitchesUnleash = useFeatureSwitchesUnleash();
    const featureSwitchesStorage = readFeatureSwitchesFromStorage();
    const featureSwitchesAll = useMemo(
        () => ({
            ...featureSwitchesUnleash,
            ...featureSwitchesStorage,
            ...featureSwitchesParams,
        }),
        [featureSwitchesUnleash, featureSwitchesStorage, featureSwitchesParams],
    );

    useMemo(() => {
        Object.entries(featureSwitchesParams).forEach(([featureKey, enabled]) => {
            const foundSame = Object.entries(featureSwitchesStorage).find(
                ([key, value]) => key === featureKey && value === enabled,
            );

            if (!foundSame && enabled) {
                addFeatureSwitchToStorage(featureKey as FeatureName);
            }
        });
    }, [featureSwitchesParams]);

    return (
        <FeatureSwitchesContext.Provider value={featureSwitchesAll}>
            {children}
        </FeatureSwitchesContext.Provider>
    );
}

interface FeatureSwitchesProviderOuterProps {
    children: ReactNode;
}

function FeatureSwitchesProviderOuter({ children }: FeatureSwitchesProviderOuterProps) {
    return (
        <FlagProvider config={unleashConfig}>
            <FeatureSwitchesProviderInner>{children}</FeatureSwitchesProviderInner>
        </FlagProvider>
    );
}

export {
    FeatureSwitchesProviderOuter as FeatureSwitchesProvider,
    // For testing only
    FeatureSwitchesProviderInner as __FeatureSwitchesProviderTesting,
};
