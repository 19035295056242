import { graphql } from 'msw';

export const strapi = graphql.link('http://localhost:3002/content/graphql');

export const siteAnalyticsData = {
    siteByDomain: {
        id: '62',
        google_analytics_code: 'UA-123456',
    },
};

export default strapi.query('GetSiteAnalytics', (req, res, ctx) =>
    res(ctx.data(siteAnalyticsData)),
);
