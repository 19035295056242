import { Global, css, useTheme, Theme } from '@emotion/react';
import emotionReset from 'emotion-reset';

const FontImports = ({ fontPaths = [] }: any) =>
    css`
        ${fontPaths.reduce(
            (acc: string, fontPath: any) => `
${acc}
@font-face {
    font-family: "${fontPath.name}";
    font-weight: ${fontPath.fontWeight};
    src: ${fontPath.src};
}
`,
            '',
        )}
    `;

const getGlobalStyles = ({ theme }: { theme: Theme }) => css`
    html,
    body {
        scrollbar-width: thin;
        scrollbar-color: ${theme.colors.base[80]} ${theme.colors.base[95]};
        background-color: ${theme.colors.base[95]};
    }
`;

const EmotionReset = () =>
    css`
        button,
        input,
        optgroup,
        select,
        textarea {
            font-family: inherit;
            font-weight: 500;
        }

        svg {
            display: block;
        }

        img {
            display: block;
        }

        input[type='time']::-webkit-calendar-picker-indicator {
            display: none;
        }

        ${emotionReset}
    `;

export function GlobalFontImports() {
    const theme = useTheme();
    const fontImportStatements = theme.fontImports.map((importUrl) => (
        <Global
            key={importUrl}
            styles={css`
                ${importUrl};
            `}
        />
    ));
    return <>{fontImportStatements}</>;
}

export function GlobalGeneralStyles() {
    const theme = useTheme();
    return <Global styles={getGlobalStyles({ theme })} />;
}

export function GlobalFonts() {
    return <Global styles={FontImports} />;
}

export function DefaultFonts() {
    const theme = useTheme();
    return (
        <Global
            styles={css`
                body,
                :host {
                    font-family: ${theme.fonts[0]};
                }
            `}
        />
    );
}

export function GlobalEmotionReset() {
    return <Global styles={EmotionReset} />;
}
