const mockData = {
    id: '1',
    games: [
        {
            sequenceNumber: 12,
            score: [
                {
                    teamId: '78',
                    score: 8,
                },
                {
                    teamId: '89',
                    score: 3,
                },
            ],
            initialProbabilities: [
                {
                    teamId: '78',
                    value: 60,
                },
                {
                    teamId: '89',
                    value: 40,
                },
            ],
            currentProbabilities: [
                {
                    teamId: '78',
                    color: 'teams.910',
                    name: 'Astralis',
                    value: 94, // Last finished segment value
                    swingWinValue: 99,
                    swingLossValue: 90,
                },
                {
                    teamId: '89',
                    color: 'teams.910',
                    name: 'AVANGAR',
                    value: 6,
                    swingWinValue: 10,
                    swingLossValue: 1,
                },
            ],
            totalSegments: 18,
            segments: [
                {
                    segmentNumber: 1,
                    segmentWinnerTeamId: '78',
                    isProjected: false,
                    graphProbabilityValue: 48,
                    winnerTeamColor: 'teams.910',
                    probabilities: [
                        { teamId: '78', value: 48, swingWin: 48, swingLoss: 18 },
                        { teamId: '89', value: 18, swingWin: 18, swingLoss: 10 },
                    ],
                },
                {
                    segmentNumber: 2,
                    segmentWinnerTeamId: '78',
                    isProjected: false,
                    graphProbabilityValue: 23,
                    winnerTeamColor: 'teams.910',
                    probabilities: [
                        { teamId: '78', value: 23, swingWin: 23, swingLoss: 60 },
                        { teamId: '89', value: 40, swingWin: 40, swingLoss: 77 },
                    ],
                },
                {
                    segmentNumber: 3,
                    segmentWinnerTeamId: '89',
                    isProjected: false,
                    graphProbabilityValue: 71,
                    winnerTeamColor: 'teams.910',
                    probabilities: [
                        { teamId: '78', value: 24, swingWin: 24, swingLoss: 29 },
                        { teamId: '89', value: 71, swingWin: 71, swingLoss: 76 },
                    ],
                },
                {
                    segmentNumber: 4,
                    segmentWinnerTeamId: '78',
                    isProjected: false,
                    winnerTeamColor: 'teams.910',
                    graphProbabilityValue: 55,
                    probabilities: [
                        { teamId: '78', value: 55, swingWin: 55, swingLoss: 11 },
                        { teamId: '89', value: 89, swingWin: 89, swingLoss: 45 },
                    ],
                },
                {
                    segmentNumber: 5,
                    segmentWinnerTeamId: '78',
                    isProjected: false,
                    winnerTeamColor: 'teams.910',
                    graphProbabilityValue: 0,
                    probabilities: [
                        { teamId: '78', value: 0, swingWin: 0, swingLoss: 66 },
                        { teamId: '89', value: 34, swingWin: 66, swingLoss: 100 },
                    ],
                },
                {
                    segmentNumber: 6,
                    segmentWinnerTeamId: '78',
                    isProjected: false,
                    winnerTeamColor: 'teams.910',
                    graphProbabilityValue: 79,
                    probabilities: [
                        { teamId: '78', value: 79, swingWin: 79, swingLoss: 44 },
                        { teamId: '89', value: 66, swingWin: 66, swingLoss: 21 },
                    ],
                },
                {
                    segmentNumber: 7,
                    segmentWinnerTeamId: '78',
                    isProjected: false,
                    winnerTeamColor: 'teams.910',
                    graphProbabilityValue: 11,
                    probabilities: [
                        { teamId: '78', value: 11, swingWin: 11, swingLoss: 87 },
                        { teamId: '89', value: 13, swingWin: 13, swingLoss: 89 },
                    ],
                },
                {
                    segmentNumber: 8,
                    segmentWinnerTeamId: '78',
                    isProjected: false,
                    winnerTeamColor: 'teams.910',
                    graphProbabilityValue: 98,
                    probabilities: [
                        { teamId: '78', value: 98, swingWin: 98, swingLoss: 42 },
                        { teamId: '89', value: 68, swingWin: 68, swingLoss: 2 },
                    ],
                },
                {
                    segmentNumber: 9,
                    segmentWinnerTeamId: '89',
                    isProjected: false,
                    winnerTeamColor: 'teams.910',
                    graphProbabilityValue: 66,
                    probabilities: [
                        { teamId: '78', value: 52, swingWin: 52, swingLoss: 44 },
                        { teamId: '89', value: 66, swingWin: 66, swingLoss: 48 },
                    ],
                },
                {
                    segmentNumber: 10,
                    segmentWinnerTeamId: '89',
                    isProjected: false,
                    winnerTeamColor: 'teams.910',
                    graphProbabilityValue: 20,
                    probabilities: [
                        { teamId: '78', value: 48, swingWin: 48, swingLoss: 80 },
                        { teamId: '89', value: 20, swingWin: 20, swingLoss: 52 },
                    ],
                },
                {
                    segmentNumber: 11,
                    segmentWinnerTeamId: '78',
                    isProjected: false,
                    winnerTeamColor: 'teams.910',
                    graphProbabilityValue: 94,
                    probabilities: [
                        { teamId: '78', value: 94, swingWin: 94, swingLoss: 60 },
                        { teamId: '89', value: 40, swingWin: 40, swingLoss: 6 },
                    ],
                },
                {
                    segmentNumber: 12,
                    segmentWinnerTeamId: '',
                    isProjected: true,
                    winnerTeamColor: '',
                    graphProbabilityValue: 94,
                    probabilities: [
                        { teamId: '78', value: 14, swingWin: 14, swingLoss: 44 },
                        { teamId: '89', value: 66, swingWin: 66, swingLoss: 86 },
                    ],
                },
            ],
        },
    ],
    teams: [
        {
            id: '78',
            name: 'Astralis',
            iconUrl: 'https://cdn.grid.gg/assets/team-logos/2e7204b9f0c86ce0ee42c781d93b32f5',
            color: 'teams.910',
        },
        {
            id: '89',
            name: 'AVANGAR',
            iconUrl: 'https://cdn.grid.gg/assets/team-logos/4a25fcf94791c891191872b0c28055bf',
            color: 'teams.910',
            isAway: true,
        },
    ],
};

export default function* generator() {
    yield mockData;
    yield mockData;
    yield mockData;
    yield mockData;
}
