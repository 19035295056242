import { rest } from 'msw';

rest.get('/controls-dp', (req, res, ctx) =>
    res(
        ctx.json({
            seriesId: '1',
            machineDataSourceId: 'TEST',
        }),
    ),
);

export default [];
