import { InputHTMLAttributes } from 'react';

const maskString = (
    input: InputHTMLAttributes<HTMLInputElement>['value'] = '',
    unmaskedLength: number = 5,
) =>
    `${String(input).slice(0, unmaskedLength)}${Array(
        Math.max(String(input).length - unmaskedLength, 0),
    )
        .fill('*')
        .join('')}`;

export default maskString;
