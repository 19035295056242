import { SeriesTimelineEvent } from '@features/widget-series-timeline/services/SeriesTimelineService';

const startItem: SeriesTimelineEvent = {
    id: '1',
    lineStyle: { backgroundColor: 'transparent' },
    label: { value: 'Start', color: 'white' },
    description: 'de_dust 2',
    timeOffset: { millisecondsFromBeginning: 0, color: 'white' },
    markerStyle: { borderColor: 'white', backgroundColor: 'transparent' },
    isLastEntry: false,
};

const item: SeriesTimelineEvent = {
    id: '2',
    lineStyle: { backgroundColor: 'red' },
    label: { value: 'Team 1', color: 'red' },
    description: 'Player Boka killed player Joka',
    timeOffset: { millisecondsFromBeginning: 1000, color: 'white' },
    markerStyle: {
        borderColor: 'red',
        backgroundColor: 'red',
        teamLogo: '/assets/images/teams/team-icon.png',
    },
    isLastEntry: false,
};

const item2: SeriesTimelineEvent = {
    id: '3',
    lineStyle: { backgroundColor: '#45b6fe' },
    label: { value: 'Team 2', color: '#45b6fe' },
    description: 'Won a round',
    timeOffset: { millisecondsFromBeginning: 2000, color: '#45b6fe' },
    markerStyle: {
        borderColor: '#45b6fe',
        backgroundColor: '#45b6fe',
        // teamLogo: '/assets/images/teams/team-icon.png',
    },
    isLastEntry: false,
};

const item3 = {
    id: '4',
    lineStyle: { backgroundColor: '#45b6fe' },
    label: { value: 'Team 2', color: '#45b6fe' },
    timeOffset: { millisecondsFromBeginning: 2000, color: '#45b6fe' },
    markerStyle: { borderColor: '#45b6fe', backgroundColor: '#45b6fe' },
    isLastEntry: false,
};

const endItem: SeriesTimelineEvent = {
    id: '5',
    lineStyle: { backgroundColor: 'white' },
    label: { value: 'End', color: 'white' },
    timeOffset: { millisecondsFromBeginning: 3000, color: 'white' },
    markerStyle: { borderColor: 'white', backgroundColor: 'transparent' },
    isLastEntry: true,
};

const generateAddMockEvent = (entryToAdd) => ({
    body: entryToAdd,
    seriesId: '1',
    type: 'addEntry',
});

const mockDataRemoveEvent = {
    body: {
        entryIdToRemove: item3.id,
    },
    seriesId: '1',
    type: 'removeEntry',
};

export default function* generator() {
    try {
        yield generateAddMockEvent(startItem);
        yield generateAddMockEvent(item);
        yield generateAddMockEvent(item2);
        yield generateAddMockEvent(item3);
        yield generateAddMockEvent(endItem);
        yield mockDataRemoveEvent;
    } catch (e) {
        console.error(e);
    }
}
