import config from '@config';

const unleashConfig = {
    url: `${config.hosts.portal}/api/features/proxy`,
    clientKey: 'test-key',
    refreshInterval: 30, // seconds
    appName: 'grid-frontend',
    environment: config.mode,
};

export default unleashConfig;
