import { graphql } from 'msw';
import article8Fixture from '../../../features/app-documentation/fixtures/article-8.json';
import article1Fixture from '../../../features/app-documentation/fixtures/article-1.json';
import article2Fixture from '../../../features/app-documentation/fixtures/article-2.json';
import recipe1Fixture from '../../../features/app-documentation/fixtures/recipe-1.json';
import recipe2Fixture from '../../../features/app-documentation/fixtures/recipe-2.json';
import recipe3Fixture from '../../../features/app-documentation/fixtures/recipe-3.json';

import navFixtureData from '../../../features/app-documentation/fixtures/navigation.json';
import recipes from '../../../features/app-documentation/fixtures/recipes.json';
import sideNavFixtureData from '../../../features/app-documentation/fixtures/sideNavigationContentQueryResult.json';

const docsContentEndpoint = graphql.link('http://localhost:3004/documentation/graphql');

const articleData = {
    '1': article1Fixture.data,
    '2': article2Fixture.data,
    '8': article8Fixture.data,
};

const recipeData = {
    '1': recipe1Fixture.data,
    '2': recipe2Fixture.data,
    '3': recipe3Fixture.data,
};

const handlers = [
    docsContentEndpoint.query('GetNavigationContent', (req, res, ctx) =>
        res(ctx.data(navFixtureData.data)),
    ),
    docsContentEndpoint.query('GetSideNavigationContent', (_, res, ctx) =>
        res(ctx.data(sideNavFixtureData.data)),
    ),

    docsContentEndpoint.query('GetDocumentationArticles', (_, res, ctx) => res(ctx.data(recipes))),
    docsContentEndpoint.query('GetArticleContent', (req, res, ctx) => {
        const article = articleData[req.variables.id];

        if (!article) {
            return res.networkError('No article found');
        }

        return res(ctx.data(article));
    }),
    docsContentEndpoint.query('GetRecipeStepsContent', (req, res, ctx) => {
        const article = recipeData[req.variables.id];

        if (!article) {
            return res.networkError('No article found');
        }

        return res(ctx.data(article));
    }),

    docsContentEndpoint.query('GetArticleByPath', (req, res, ctx) =>
        res(
            ctx.data({
                renderNavigation: [
                    {
                        id: 3,
                        title: 'First article!',
                        icon_url: '',
                        path: '/documentation/test',
                        related: {
                            __typename: 'NavigationItemRelatedData',
                            attributes: {
                                __typename: 'DocumentationArticle',
                                ...article1Fixture.data.documentationArticle.data.attributes,
                            },
                        },
                    },
                ],
            }),
        ),
    ),
];

export default handlers;
