import { rest } from 'msw';

const response = {
    groups: [
        {
            title: 'Game 1 Winner',
            predictions: [
                {
                    text: 'Team 1',
                    value: {
                        percentage: '50%',
                        decimal: '0.5',
                    },
                },
                {
                    text: 'Team 2',
                    value: {
                        percentage: '20%',
                        decimal: '0.2',
                    },
                },
                {
                    text: 'Tie',
                    value: {
                        percentage: '30%',
                        decimal: '0.3',
                    },
                },
            ],
        },
        {
            title: 'Game 2 Winner',
            predictions: [
                {
                    text: 'Team 1',
                    value: {
                        percentage: '56%',
                        decimal: '0.56',
                    },
                },
                {
                    text: 'Team 2',
                    value: {
                        percentage: '27%',
                        decimal: '0.27',
                    },
                },
                {
                    text: 'Tie',
                    value: {
                        percentage: '14%',
                        decimal: '0.14',
                    },
                },
            ],
        },
        {
            title: 'Which player will got MVP?',
            predictions: [
                {
                    text: 'Player 1',
                    value: {
                        percentage: '6%',
                        decimal: '0.06',
                    },
                },
                {
                    text: 'Player 2',
                    value: {
                        percentage: '20%',
                        decimal: '0.2',
                    },
                },
                {
                    text: 'Player 3',
                    value: {
                        percentage: '14%',
                        decimal: '0.14',
                    },
                },
                {
                    text: 'Player 4',
                    value: {
                        percentage: '9%',
                        decimal: '0.09',
                    },
                },
                {
                    text: 'Player 5',
                    value: {
                        percentage: '21%',
                        decimal: '0.21',
                    },
                },
                {
                    text: 'Player 6',
                    value: {
                        percentage: '12%',
                        decimal: '0.12',
                    },
                },
            ],
        },
    ],
};

const getPreSeriesPredictions = rest.get('/api/predictions/pre/series/:id', (req, res, ctx) =>
    res(ctx.json(response)),
);

export default [getPreSeriesPredictions];
