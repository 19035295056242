import { rest } from 'msw';

const profileData = {
    theme: 'grid',
    apiKey: '123',
};

const myProfile = rest.get('/api/my-profile', (req, res, ctx) => res(ctx.json(profileData)));

export default [myProfile];
