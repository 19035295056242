import { useEffect } from 'react';

import useAuthentication from './useAuthentication';

// handleLogin can optionally return a cleanup function which will be called
// on logout. Similarly to how standard useEffect works.
const useLoginEffect = (handleLogin: () => void | (() => void)) => {
    const authentication = useAuthentication();

    useEffect(() => {
        let handleLogout;
        if (authentication.status === 'LoggedIn') {
            handleLogout = handleLogin();
        }
        return handleLogout;
    }, [authentication.status]);
};

export default useLoginEffect;
