/* eslint-disable max-classes-per-file */

import { Server, WebSocket } from 'mock-socket';
import { matchPath } from 'react-router';
import { parse } from 'query-string';

export const servers = new Map();

/* eslint-disable */
class ServerProxy extends Server {
    start() {
        window.WebSocket = WebSocketProxy;
    }
}
/* eslint-enable */

const createOrGetServer = (url: string) => {
    if (servers.has(url)) return servers.get(url);
    const server = new ServerProxy(url);
    servers.set(url, server);
    return server;
};

interface Params {
    search: Record<string, any>;
    params: Record<string, any>;
}

export const handlers: {
    path: string;
    handler: (socket: Server, params: Params) => void;
}[] = [];

export const registerHandler = (
    path: string,
    handler: (socket: Server, params: Params) => void,
) => {
    handlers.push({ path, handler });
};

export class WebSocketProxy extends WebSocket {
    constructor(url: string, protocols: string | string[]) {
        const { search, origin, pathname } = new URL(url);
        const transformedUrl = `${origin}${pathname}${search.replace('?', '')}`;

        const matchedHandlers = handlers.filter(
            ({ path }) => !!matchPath({ path, end: true }, pathname),
        );

        matchedHandlers.forEach(({ path, handler }) => {
            const match = matchPath({ path }, pathname);
            handler(createOrGetServer(transformedUrl), {
                params: match?.params || {},
                search: parse(search),
            });
        });

        super(transformedUrl, protocols);
    }
}

export default WebSocketProxy;
