import { rest } from 'msw';
import mockedFilesData from '@features/competitor/pages/match-history-viewer/fixtures/seriesFiles.json';

const differentData = {
    data: {
        files: [
            {
                id: 'events-grid-compressed',
                description: 'Grid series events',
                status: 'match-not-started',
                fileName: 'state_1_grid.json.zip',
                fullURL: '',
            },
            {
                id: 'state-grid',
                description: 'Grid post-match state',
                status: 'ready',
                fileName: 'end_state_666_grid.json',
                fullURL: 'https://api-stg.grid.gg/file-download/end-state/grid/series/666',
            },
            {
                id: 'events-riot-compressed',
                description: 'Riot series events compressed',
                status: 'preparing',
                fileName: '',
                fullURL: '',
            },
            {
                id: 'state-riot-compressed',
                description: 'Riot post-match state compressed',
                status: 'match-not-started',
                fileName: '',
                fullURL: '',
            },
        ],
    },
};

const getSeriesFiles = rest.get('http://localhost:3002/file-download/list/:id', (req, res, ctx) => {
    if (req.params.id === '283613') {
        return res(ctx.json(differentData.data));
    }
    return res(ctx.json(mockedFilesData.data));
});

export default [getSeriesFiles];
