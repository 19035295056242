import { graphql } from 'msw';

import firstPage from '../../../features/competitor/pages/events-explorer/fixtures/firstPage.json';
import secondPage from '../../../features/competitor/pages/events-explorer/fixtures/secondPage.json';

const feedEvents = graphql.link('/api/event-explorer-api/events/graphql');

let secondPageRequestCounter = 0;

const handlers = [
    feedEvents.query('getSeriesEvents', (req, res, ctx) => {
        const pageLength = firstPage.events.edges.length;

        const filters = req.variables.filter?.event;
        if (filters) {
            const filteredTypes = filters.map(({ type: { eq: type } }) => type);
            const eventEdges = [...firstPage.events.edges, ...secondPage.events.edges];
            const eventEdgesFiltered = eventEdges.filter(({ node: { type } }) =>
                filteredTypes.includes(type),
            );
            return res(
                ctx.data({
                    events: {
                        edges: eventEdgesFiltered,
                        pageInfo: {
                            endCursor: 'end-cursor',
                            hasNextPage: true,
                            hasPreviousPage: false,
                            startCursor: 'start-cursor',
                        },
                    },
                }),
            );
        }

        const firstPageLastEdge = firstPage.events.edges[pageLength - 1];
        if (req.variables.after === firstPageLastEdge.cursor) {
            secondPageRequestCounter += 1;

            if (secondPageRequestCounter < 3) {
                return res(ctx.delay(100), ctx.status(500));
            }
            return res(ctx.data(secondPage));
        }

        return res(ctx.data(firstPage));
    }),
];

export default handlers;
