import { getAuthToken } from '@features/authentication/utilities/authToken';

import { LoginResponse } from '../types/authentication';

const authenticationService = {
    async login(loginId: string, password: string): Promise<LoginResponse> {
        const response = await fetch('/auth/login', {
            method: 'POST',
            body: JSON.stringify({ loginId, password }),
        });
        const body = await response.json();
        return { response, body };
    },
    async logout() {
        const response = await fetch('/auth/logout', {
            method: 'POST',
        });
        const body = await response.json();

        return { response, body };
    },
    async verify(): Promise<Response> {
        const response = await fetch('/auth/verify', {
            method: 'GET',
        });
        return response;
    },
    hasRole(role: OneOrMany<string>): boolean {
        const authToken = getAuthToken();
        if (authToken) {
            try {
                const jwtPayload = authToken.split('.')[1];
                const decodedPayload = atob(jwtPayload);
                const { roles } = JSON.parse(decodedPayload);

                if (!Array.isArray(role)) return roles.includes(role);

                return !role.find((str) => !roles.includes(str));
            } catch (e) {
                return false;
            }
        }
        return false;
    },
};

export default authenticationService;
